const debounce = (func: any, delay: number) => {
  let timeoutId: number;

  return (...args: any) => {
    clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default debounce;
