import LegendContainer from '@components/atoms/new-proposal/legend-container';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import config from '@config';

const extractIdURL = (driveUrl: string) => {
  const match = driveUrl.match(/\/d\/([a-zA-Z0-9_-]+)\//);
  if (match && match[1]) {
    const fileId = match[1];
    return `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
  }
};

function FeasibilitySimulationCharts() {
  const imageProposalHistoricPrice = config.PROPOSALS_HISTORIC_PRICES_CHART_IMAGE_URL;

  const imagePropoposalWithDirectLink = extractIdURL(imageProposalHistoricPrice);

  return (
    <SlideContainer className="h-fit">
      <section className="flex flex-col gap-10 sm:gap-6 w-full h-fit min-h-full p-8">
        <LegendContainer legend="Simulação de viabilidade" />
        <div className="w-full flex flex-col sm:gap-6  items-start sm:items-center ">
          <b className="sm:w-10/12 text-heading-xsmall text-primary-70 xl:text-heading-xlarge mt-2">
            O papel da Clarke é assessorar o seu grupo para encontrar as melhores oportunidades de compra de energia
          </b>
        </div>
        <div className="w-full flex justify-center sm:py-5 ">
          <div className="min-w-full min-h-fit flex justify-center items-center">
            <img src={imagePropoposalWithDirectLink} alt="Gráfico de simulação de viabilidade" />
          </div>
        </div>
      </section>
    </SlideContainer>
  );
}

export default FeasibilitySimulationCharts;
