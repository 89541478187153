import { IWhiteLabelSupplier } from '@contexts/white-label/type';

export function SuppliersProjectionsHistoryTableTagColumn({
  suppliersProjection,
}: {
  suppliersProjection: IWhiteLabelSupplier;
}) {
  return (
    <td className="text-paragraph-small sm:text-paragraph-medium xl:text-paragraph-large col-span-5 flex items-center text-start min-h-full">
      <tr className="flex items-center gap-2">
        {suppliersProjection.users?.length ?? Number(Math.random() * 10).toFixed(0)} usuários
      </tr>
    </td>
  );
}
