import React from 'react';
import { Tooltip } from '@clarke-energia/foton';
import { twMerge } from 'tailwind-merge';

import './slider.css';

export interface SliderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChangedCallback: (e: any) => void;
  label?: string;
  error?: string;
  required?: boolean;
  tooltipContent?: string;
  customAttrs?: Record<string, string>;
  step?: number;
  initialValueInPercentage?: number;
  darkMode?: boolean;
  hideValueBubble?: boolean;
}

const Slider: React.FC<SliderProps> = ({
  onValueChangedCallback,
  label,
  error,
  required,
  tooltipContent,
  customAttrs,
  step,
  initialValueInPercentage,
  darkMode,
  hideValueBubble = false,
  ...attrs
}) => {
  const { id, disabled, className, ...restAttrs } = attrs;

  const [value, setValue] = React.useState<number>(0);
  const [activeValueBubble, setActiveValueBubble] = React.useState(false);
  const [descriptionPosition, setValueBubblePosition] = React.useState({
    left: `${value ?? 0}%`,
  });

  const handleSliderInteraction = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat((e.target as HTMLInputElement).value);
    setValue(newValue);
    setValueBubblePosition({ left: `${newValue}%` });
    setActiveValueBubble(true);

    if (onValueChangedCallback) onValueChangedCallback(e);
  };

  const handleMouseLeave = () => {
    setActiveValueBubble(false);
  };

  const handleMouseOver = () => {
    if (!hideValueBubble) {
      setActiveValueBubble(true);
    }
  };

  React.useEffect(() => {
    if (disabled) {
      setValueBubblePosition({ left: `${initialValueInPercentage}%` });
    }

    if (initialValueInPercentage) {
      setValue(initialValueInPercentage);
      setValueBubblePosition({ left: `${initialValueInPercentage}%` });
    }
  }, [disabled, setValue, initialValueInPercentage]);

  const dynamicClasses = disabled ? 'text-neutral-40' : 'text-black';

  const trackStyle: React.CSSProperties = {
    background: `linear-gradient(to right, #00DF7C 0%, #00DF7C ${value}%, #E8E8E8 ${value}%, #E8E8E8 100%)`,
  };

  return (
    <div className={twMerge(`flex flex-col`, className)}>
      {label && (
        <div className={twMerge('flex gap-2 ml-3 mb-2 items-center', darkMode ? 'text-[#FAFAFA]' : 'text-[#2E2E2E]')}>
          <label
            htmlFor={id}
            className={twMerge('text-paragraph-medium font-normal', darkMode ? 'text-[#FAFAFA]' : 'text-[#2E2E2E]')}
          >
            {label}
          </label>
          {tooltipContent && <Tooltip content={tooltipContent} />}
        </div>
      )}
      <div className={`w-full h-[0.75rem] m-auto relative ${dynamicClasses}`}>
        {activeValueBubble && !hideValueBubble && (
          <div
            className={twMerge(
              'absolute flex justify-center items-center gap-2 p-2 bottom-5 rounded-pill',
              darkMode ? 'bg-[#FAFAFA] text-[#2E2E2E]' : 'bg-[#2E2E2E] text-[#FAFAFA]',
            )}
            style={descriptionPosition}
          >
            <p className="font-bold text-[.5rem] sm:text-sm text-light-neutral text-center sm:p-2">{`${value.toFixed(
              0,
            )}%`}</p>
          </div>
        )}
        <input
          id={id}
          {...customAttrs}
          className={disabled ? 'disabled-slider' : 'slider'}
          type="range"
          min="0"
          max="100"
          step={step ?? '1'}
          value={value}
          defaultValue={initialValueInPercentage}
          onChange={handleSliderInteraction}
          onMouseOver={handleMouseOver}
          onMouseEnter={() => setActiveValueBubble(true)}
          onMouseLeave={handleMouseLeave}
          onTouchStart={() => setActiveValueBubble(true)}
          onTouchEnd={handleMouseLeave}
          style={trackStyle}
          disabled={disabled}
          {...restAttrs}
        />
      </div>
    </div>
  );
};

export default Slider;
