import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { FileInput } from '@clarke-energia/foton';

export interface FileFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  label: string;
  onChangeFiles: (files: File | Array<File>) => void;
  placeholder?: string;
  id: string;
  options?: RegisterOptions<T>;
  description: string;
  maximumFileSizeInMB: number;
  allowedFileTypes?: string[];
  multipleFiles?: boolean;
  storedFiles?: Array<any>;
  handleRemoveStoredFile?: (fileId: string) => void;
  loading?: boolean;
}

export function FileField<T extends FieldValues>({
  field,
  label,
  id,
  placeholder,
  options,
  description,
  maximumFileSizeInMB,
  multipleFiles,
  allowedFileTypes,
  storedFiles,
  handleRemoveStoredFile,
  loading,
  onChangeFiles,
}: FileFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onBlur, value } }) => (
        <div id={id} className="flex flex-col pt-4 space-y-1 col-span-1">
          <FileInput
            name={name}
            label={label}
            value={value}
            allowedFileTypes={allowedFileTypes}
            placeholder={placeholder}
            required={(options?.required as boolean) || false}
            error={errors[field]?.message as string | undefined}
            onBlur={onBlur}
            onChangeFiles={(files) => onChangeFiles(files)}
            description={description}
            maximumFileSizeInMB={maximumFileSizeInMB}
            multipleFiles={multipleFiles}
            storedFiles={storedFiles}
            handleRemoveStoredFile={handleRemoveStoredFile}
            loading={loading}
            customAttrs={{ 'data-cy': id }}
          />
        </div>
      )}
    />
  );
}
