import coverImage from '@assets/images/multiple-lamps-green-bg.png';

import ManagementBillingTable from '@components/atoms/product-two/management-billing-table';
import SlideHeader from '@components/atoms/simulation/slide-header';

import { generatePostMigrationTableData, generatePreMigrationTableData } from './static-data';

export interface IServiceProposal {
  groupManagementPrice: number;
  totalMigrationCost?: number;
  unitsNumber?: number;
  proposalValidity?: string;
}

const ServiceProposal = ({
  totalMigrationCost,
  groupManagementPrice,
  unitsNumber,
  proposalValidity,
}: IServiceProposal): JSX.Element => {
  if (groupManagementPrice === undefined || !unitsNumber) return <></>;

  const preMigrationTableData = generatePreMigrationTableData(totalMigrationCost);

  const postMigrationTableData = generatePostMigrationTableData(groupManagementPrice);

  return (
    <div className="w-full h-full grid grid-cols-12 justify-between">
      <div className="col-span-12 sm:col-span-6 h-full">
        <div className="justify-start items-start xl:w-3/4">
          <SlideHeader title="Proposta de Serviço Clarke" />
        </div>
        <div className="px-6 sm:pl-8 sm:pr-2 lg:px-8">
          <ManagementBillingTable
            preMigrationTableData={preMigrationTableData}
            postMigrationTableData={postMigrationTableData}
          />
          {proposalValidity && (
            <h2 className="text-paragraph-large font-normal py-4 mt-5">Proposta válida até {proposalValidity}.</h2>
          )}
        </div>
      </div>
      <img
        src={coverImage}
        className="max-h-40 w-full col-span-12 order-first object-cover sm:order-last sm:col-span-6 sm:max-h-none sm:h-full sm:object-cover"
      />
    </div>
  );
};
export default ServiceProposal;
