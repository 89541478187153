import React from 'react';

import { twMerge } from 'tailwind-merge';

interface ISlideContainer {
  children: React.ReactNode;
  className?: string;
  isVisible?: boolean;
}

const isOnPrintMode = window.location.search.includes('print');

const SlideContainer = ({ children, className, isVisible }: ISlideContainer) => {
  const slideContainerHeight = isOnPrintMode ? 'h-[810px]' : 'h-fit';
  const slideContainerWidth = isOnPrintMode ? 'w-[1440px]' : 'w-full';
  const slideContainerPaddigBottom = !isOnPrintMode && 'pb-4';
  const extraClass = !isOnPrintMode && className;
  const hideClass = isVisible === false && 'hidden';
  const gradientContainer = className?.includes('gradient-container') && 'gradient-container';
  const slideContainerClassDynamic = [slideContainerWidth, slideContainerHeight, slideContainerPaddigBottom].join(' ');
  const slideContainerClassFixed = 'flex flex-col overflow-x-hidden overflow-y-clip';

  return (
    <div
      className={twMerge(
        slideContainerClassFixed,
        slideContainerClassDynamic,
        extraClass,
        hideClass,
        gradientContainer,
      )}
    >

      {children}
    </div>
  );
};

export default SlideContainer;