import { useNavigate } from 'react-router-dom';
import { Drawer } from '@clarke-energia/foton';

import { buildProposalsHistoryPath } from '@routers/path-builder';

import SemanticMarquee, { IAlertMessage } from '../../semantic-marquee';

import ProposalPreviewContainerSkeleton from './skeleton';

interface ILeadProposalsHubContainer {
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: JSX.Element;
  loading: boolean;
  alertMessage?: IAlertMessage;
}

interface IBaseDrawer {
  handleCloseDrawer: () => void;
  children: JSX.Element;
}

const BaseDrawer = ({ children, handleCloseDrawer }: IBaseDrawer): JSX.Element => (
  <Drawer open={true} onClose={handleCloseDrawer} drawerWidth="w-full md:w-2/3 xl:w-1/2">
    {children}
  </Drawer>
);

export const LeadProposalsHubContainer = ({
  setIsDrawerOpen,
  children,
  loading,
  alertMessage,
}: ILeadProposalsHubContainer): JSX.Element => {
  const navigate = useNavigate();

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    navigate(buildProposalsHistoryPath());
  };

  if (loading) {
    return (
      <BaseDrawer handleCloseDrawer={handleCloseDrawer}>
        <ProposalPreviewContainerSkeleton />
      </BaseDrawer>
    );
  } else if (alertMessage) {
    return (
      <BaseDrawer handleCloseDrawer={handleCloseDrawer}>
        <SemanticMarquee kind={alertMessage.kind}>{alertMessage.content}</SemanticMarquee>
      </BaseDrawer>
    );
  }

  return <BaseDrawer handleCloseDrawer={handleCloseDrawer}>{children}</BaseDrawer>;
};
