import React from 'react';

import { GadgetWithIcon, IGadgetWithIconContent } from '@components/atoms/simulation/gadget-with-icon';
import { GadgetNumbered, IGadgetNumberedContent } from '@components/atoms/simulation/gadget-numered';

import SlideHeader from '@components/atoms/simulation/slide-header';

interface IConclusiveSectionLayout {
  mainTitle: string;
  backgroundImg: string;
  gadgetWithIconContent?: Array<IGadgetWithIconContent>;
  gadgetNumberedContent?: Array<IGadgetNumberedContent>;
}

interface IBackgroundImage {
  backgroundImg: string;
}

const BackgroundImage = ({ backgroundImg }: IBackgroundImage) => {
  return (
    <div className="w-full h-[19rem] xl:w-8/12 xl:h-full xl:absolute xl:top-0 xl:right-0 xl:z-[-1]">
      <img className="w-full h-full object-cover object-left" src={backgroundImg} />
    </div>
  );
};

const ConclusiveSectionLayout: React.FC<IConclusiveSectionLayout> = ({
  mainTitle,
  backgroundImg,
  gadgetWithIconContent,
  gadgetNumberedContent,
}: IConclusiveSectionLayout) => {
  const isOnPrintMode = window.location.search.includes('print');
  return (
    <div className="w-full h-full flex relative flex-col">
      <BackgroundImage backgroundImg={backgroundImg} />
      <div className="w-full xl:w-4/12 xl:pr-6">
        <SlideHeader title={mainTitle} />
      </div>
      {gadgetWithIconContent && <GadgetWithIcon isOnPrintMode={isOnPrintMode} boxesContent={gadgetWithIconContent} />}
      {gadgetNumberedContent && <GadgetNumbered isOnPrintMode={isOnPrintMode} boxesContent={gadgetNumberedContent} />}
    </div>
  );
};

export default ConclusiveSectionLayout;
