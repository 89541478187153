import { RefCallBack } from 'react-hook-form';
import Select from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

interface ComboBoxInputProps extends Omit<StateManagerProps, 'onChange'> {
  label: string;
  inputOptions: { label: string; value: string | number }[];
  maxMenuHeightInPx: number;
  ref?: RefCallBack;
  onChange: (e: any) => void;
  value?: string | number;
  error?: string;
  required?: boolean;
  customAttrs?: Record<string, string>;
}

export function ComboBoxInput({
  label,
  ref,
  inputOptions,
  error,
  required,
  value,
  onChange,
  maxMenuHeightInPx,
  customAttrs,
  ...restAttrs
}: ComboBoxInputProps) {
  const { placeholder, onBlur, onMenuScrollToBottom, onInputChange, name } = restAttrs;
  return (
    <div>
      <label className="text-paragraph-medium">
        {label}
        {required && <span className="text-danger-60">*</span>}
      </label>
      <Select
        {...restAttrs}
        {...customAttrs}
        ref={ref}
        name={name}
        options={inputOptions as []}
        value={inputOptions.find((val) => val.value === value) || null}
        placeholder={placeholder || 'Selecione uma opção'}
        onBlur={onBlur}
        onChange={(option: any) => {
          if (option && onChange) onChange(option.value);
        }}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onInputChange={onInputChange}
        maxMenuHeight={maxMenuHeightInPx}
        styles={{
          control: (base) => ({
            ...base,
            '&:hover': { borderColor: error ? 'red' : '#C5C5C5' },
            border: `1px solid ${error ? 'red' : ' #C5C5C5'}`,
            boxShadow: 'none',
            height: 33,
            minHeight: 33,
            padding: 'none',
          }),
          input: (base) => ({
            ...base,
            marginBottom: 2,
            height: '100%',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#00DF7C' : 'none',
            '&:hover': { backgroundColor: state.isSelected ? 'none' : '#F1FFEB' },
          }),
        }}
        isDisabled={restAttrs.isDisabled}
        data-cy={restAttrs.id}
      />
      {error && <span className="text-danger-60 text-paragraph-small">{error}</span>}
    </div>
  );
}
