import React from 'react';
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { Checkbox, Tooltip } from '@clarke-energia/foton';

import { IGeneralFormSelectableInputsOptions } from '../types';

export interface CheckboxFormFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  inputOptions: Array<IGeneralFormSelectableInputsOptions>;
  label: string;
  options?: RegisterOptions<T>;
  tooltipContent?: string;
}

export function CheckboxFormField<T extends FieldValues>({
  field,
  inputOptions,
  label,
  options,
  tooltipContent,
}: CheckboxFormFieldProps<T>) {
  const { control } = useFormContext<T>();
  return (
    <div className="flex flex-col gap-2">
      {label && (
        <div className="flex gap-2">
          <label className="text-paragraph-medium">
            {label}
            {options?.required && <span className="text-danger-60">*</span>}
          </label>
          {tooltipContent && <Tooltip content={tooltipContent} />}
        </div>
      )}
      <div className="flex gap-6 flex-wrap">
        {inputOptions.map(({ id, value, optionLabel, defaultChecked }, _) => (
          <Controller
            key={id}
            control={control}
            name={field}
            rules={options}
            render={({ field: { name, onBlur, onChange } }) => (
              <Checkbox
                id={id}
                customAttrs={{ 'data-cy': id }}
                name={name}
                label={optionLabel}
                value={value}
                checked={defaultChecked}
                defaultChecked={defaultChecked}
                required={(options?.required as boolean) || false}
                onBlur={onBlur}
                disabled={options?.disabled}
                onChange={options?.onChange ?? onChange}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
}
