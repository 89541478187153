interface IDescriptionWithNumber {
  textDescription: string;
  value: number | string;
  valueTextColor: 'primary' | 'black';
}

export const DescriptionWithNumber = ({ textDescription, value, valueTextColor }: IDescriptionWithNumber) => {
  const valueClassFixed = 'text-paragraph-medium sm:text-paragraph-large font-bold';

  return (
    <div className="flex flex-col w-fit">
      <h5 className="text-p text-paragraph-small">{textDescription}</h5>
      <h4 className={`${valueClassFixed} ${valueTextColor == 'primary' && 'text-primary-70'}`}>{value}</h4>
    </div>
  );
};
