/* eslint-disable react/require-default-props */
import React from 'react';

interface ISuperheaderContainer {
  superHeader: string;
  children?: JSX.Element;
}

const SuperheaderContainer: React.FC<ISuperheaderContainer> = ({ superHeader, children }: ISuperheaderContainer) => (
  <div className="w-full h-full flex flex-col">
    <div className="w-full bg-neutral-10 text-paragraph-small font-bold pl-5 py-[7px]">{superHeader} </div>
    <div className="w-full h-full">{children}</div>
  </div>
);

export default SuperheaderContainer;
