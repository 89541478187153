import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FeedbackNotification, LayoutProps } from '@clarke-energia/foton';

import { IManagementContractPayload, IManagementContractProduct } from '@contexts/management-contract/types';
import useCustomClipboard from '@hooks/use-custom-clipboard';

import { ContractFormFooter } from '@components/molecules/form/contract/contract-form-footer';
import GeneratedContractModal from '@components/molecules/form/contract/sections/generated-contract-modal';

import ContractFormCore from './management-contract-form-core';

interface IContractContent extends LayoutProps {
  formController: UseFormReturn<IManagementContractPayload, any>;
  contractProduct: IManagementContractProduct;
  hasPreviousDraft: boolean;
  setHasPreviousDraft: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContractContent = ({
  formController,
  contractProduct,
  hasPreviousDraft,
  setHasPreviousDraft,
}: IContractContent) => {
  const { customClipboardFeedbackNotificationContent } = useCustomClipboard();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  return (
    <div className="col-span-full flex flex-col w-full lg:w-3/4 p-7">
      <FormProvider {...formController}>
        {customClipboardFeedbackNotificationContent && (
          <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
            <FeedbackNotification {...customClipboardFeedbackNotificationContent} />
          </div>
        )}
        <ContractFormCore contractProduct={contractProduct} />
        <ContractFormFooter
          hasPreviousDraft={hasPreviousDraft}
          setIsModalOpen={setIsModalOpen}
          setHasPreviousDraft={setHasPreviousDraft}
        />
        {isModalOpen && <GeneratedContractModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
      </FormProvider>
    </div>
  );
};

export default ContractContent;
