import React from 'react';
import { Checkbox } from '@clarke-energia/foton';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { getSimulatorCompetitorDefaultValues } from '@components/molecules/form/simulator/default-values-getters';
import { ISimulationPayloadBase, SimulatorTypeEnum } from '@contexts/types';

import SimulatorFormCompetitorsListItem from './simulator-form-competitor-section';
import { useAuth } from '@src/auth-wrapper';

interface ISimulatorFormCompetitorsListItem<T> {
  fieldsController?: Record<keyof T | string, boolean>;
  simulatorType: keyof typeof SimulatorTypeEnum;
}

function SimulatorFormCompetitorsListSection<T>({
  fieldsController,
  simulatorType,
}: ISimulatorFormCompetitorsListItem<T>) {
  const { setValue, watch } = useFormContext();

  const {
    fields: competitorsListFields,
    append,
    remove,
  } = useFieldArray<ISimulationPayloadBase>({
    name: 'competitorsList',
    rules: { minLength: 0, required: false },
  });

  const { user } = useAuth();

  const [showCompetitorSection, setShowCompetitorSection] = React.useState<boolean>(false);
  const competitorList = watch('competitorsList');

  const clearCompetitorSection = () => {
    return setValue('competitorsList', null);
  };

  const setupInitialValuesForCompetitorList = () => {
    const clarkeInitialSetupValues = getSimulatorCompetitorDefaultValues(simulatorType, true);
    append(clarkeInitialSetupValues);

    const competitorInitialSetupValues = getSimulatorCompetitorDefaultValues(simulatorType);
    append(competitorInitialSetupValues);
  };

  const competitorSectionHandler = () => {
    showCompetitorSection ? clearCompetitorSection() : setupInitialValuesForCompetitorList();
    return setShowCompetitorSection(!showCompetitorSection);
  };

  React.useEffect(() => {
    competitorList && competitorList.length >= 2 && setShowCompetitorSection(true);
  }, [competitorList]);

  return (
    <>
      {!user?.isCommercialDealer && !user?.isWhiteLabel && (
        <div className="flex flex-col gap-10 mt-3 bg-neutral-10 pt-5 pb-5 mb-6 px-6 rounded-medium">
          <Checkbox
            id="check-box-show-competitor-comparison"
            label="Comparativo com concorrente"
            onChange={() => competitorSectionHandler()}
            checked={showCompetitorSection}
          />
          {showCompetitorSection &&
            competitorsListFields.map((field, index) => (
              <SimulatorFormCompetitorsListItem
                key={field.id}
                competitorIndex={index}
                removeCompetitor={remove}
                appendCompetitor={append}
                fieldsController={fieldsController}
                competitorsNumber={competitorsListFields?.length}
                simulatorType={simulatorType}
              />
            ))}
        </div>
      )}
    </>
  );
}

export default SimulatorFormCompetitorsListSection;
