import ChartDataLabels from 'chartjs-plugin-datalabels';

import BarChart from '@components/atoms/general/bar-chart';

import buildChartWithLabelsData from './helper';

type IGridColumns = {
  totalColumns: number;
  yearsColumns: number;
};

export interface IEconomyChart {
  labels: Array<string>;
  currentValues: Array<number>;
  targetValues: Array<number>;
  savingValues: Array<number>;
  alternativeBarsColor?: boolean;
  gridColumns?: IGridColumns;
}

const Legend = (): JSX.Element => {
  return (
    <div className="w-full flex flex-row pt-4 justify-center gap-7">
      <div className="flex flex-row gap-2">
        <div className="bg-neutral-80 h-5 w-5 rounded-full"></div>
        <h1 className="text-caption-medium text-neutral-60">Mercado Cativo (ACR)</h1>
      </div>
      <div className="flex flex-row gap-2">
        <div className="bg-primary-60 h-5 w-5 rounded-full"></div>
        <h1 className="text-caption-medium text-neutral-60">Mercado Livre (ACL)</h1>
      </div>
    </div>
  );
};

const EconomyChartWithLabels = ({
  labels,
  currentValues,
  targetValues,
  savingValues,
  alternativeBarsColor,
  gridColumns,
}: IEconomyChart): JSX.Element => {
  if (!targetValues || !currentValues || !savingValues || !gridColumns) return <></>;

  const chartData = buildChartWithLabelsData({
    currentValues,
    targetValues,
    savingValues,
    alternativeBarsColor,
    labels,
  });

  return (
    <div className="w-full h-full px-[0.875rem] pt-5">
      <div className="h-[13.125rem]">
        <BarChart
          plugins={[ChartDataLabels]}
          options={chartData.options}
          datasets={chartData.datasets}
          labels={chartData.labels}
        />
      </div>
      <Legend />
    </div>
  );
};

export default EconomyChartWithLabels;
