import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
`;

export const SlideContainer = styled.div`
  width: 1366px;
  height: 852px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${(props: { withPadding?: boolean }) => (props.withPadding ? '2rem' : 0)};
`;

export const FlexItemsEnd = styled.div.attrs({
  className: 'h-full col-span-full flex flex-col gap-8 justify-end',
})``;

export const FlexItemsBetween = styled.div.attrs({
  className: 'h-full col-span-full flex flex-col gap-8 justify-between',
})``;

export const FlexItems = styled.div.attrs({
  className: 'h-full col-span-full flex flex-col gap-8',
})``;

export const FlexDirection = styled.div.attrs({
  className: 'flex flex-col  md:flex-row  gap-8',
})``;

export const FlexItemsRowFlexStart = styled.div.attrs({
  className: ' flex flex-row gap-8',
})``;

export const PremisesAndPricesSectionLargeScreen = styled.div.attrs({
  className: 'flex flex-col justify-between relative gap-5  relative',
})``;

export const PremisesAndPricesSection = styled.div.attrs({
  className: 'flex flex-col gap-5',
})``;
