import { useNavigate } from 'react-router-dom';
import { Button } from '@clarke-energia/foton';

import { buildCreateContractPath } from '@routers/path-builder';

import { useAuth } from '@src/auth-wrapper';

import { formatDate } from '@utils/datetime';
import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';
import { getCalculatorName } from '@utils/common';

export interface IExtendedLeadProposalsHub {
  titleContent?: string;
  createdAt?: string;
  leadId: string;
  proposalId: string | undefined;
  isProposalValid?: boolean;
}

const LeadProposalsHubHeader = ({
  titleContent,
  createdAt,
  leadId,
  proposalId,
  isProposalValid,
}: IExtendedLeadProposalsHub): JSX.Element => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const showGenerateContractButton = leadId && proposalId && isProposalValid && !user?.isCommercialDealer;

  const handleButtonGenerateContractClick = () => {
    if (proposalId) {
      navigate(buildCreateContractPath(leadId, proposalId));
    }
    eventTracker.trackEvent(eventLabels.BUTTON_GENERATE_CONTRACT_DRAWER, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  };

  return (
    <div className="flex flex-col justify-between items-start mb-7 w-full">
      <h3 className="text-paragraph-medium font-normal text-neutral-50">{titleContent}</h3>
      <div className="flex justify-between w-full">
        <div className="flex flex-col">
          <h1 className="text-heading-large font-bold">
            {user?.isCommercialDealer ? 'Simulação de Economia' : 'Proposta de Economia'}
          </h1>
          <h1 className="text-heading-xsmall">{`${formatDate(createdAt, 'DD/MM/YYYY - HH:mm')}`}</h1>
        </div>
        {showGenerateContractButton && !user?.isWhiteLabel && (
          <Button
            kind="secondary"
            label="Gerar contrato"
            size="medium"
            type="button"
            onClick={() => handleButtonGenerateContractClick()}
          />
        )}
      </div>
    </div>
  );
};

export default LeadProposalsHubHeader;
