import { FeedbackNotificationProps } from '@clarke-energia/foton';
import { capitalizeFirstLetter } from '@utils/text';

export const getCustomChangeSellerFeedbackNotificationContents = (
  onCloseNotification: () => void,
): Record<string, FeedbackNotificationProps> => {
  return {
    CHANGE_SELLER_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'Vendedor alterado com sucesso.',
      onCloseNotification,
    },
    CHANGE_SELLER_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Não foi possível alterar o vendedor da proposta.',
      onCloseNotification,
    },
  };
};

export const splitUserName = (name: string) => {
  const isNameAnEmail = name.includes('@');
  const nameParts = isNameAnEmail ? name.split('@') : name.split(' ');

  const firstName = capitalizeFirstLetter(nameParts[0]);
  const secondName = isNameAnEmail ? undefined : capitalizeFirstLetter(nameParts[1] ?? undefined);

  return {
    firstName,
    secondName,
  };
};
