import { Tooltip } from '@clarke-energia/foton';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import { twMerge } from 'tailwind-merge';

export interface GenericFormFieldProps<T extends FieldValues> {
  field: Path<T>;
  id: string;
  label: string;
  placeholder?: string;
  options?: RegisterOptions;
  disabled?: boolean;
  className?: string;
  tooltipContent?: string;
  activeResize?: 'none' | 'both' | 'horizontal' | 'vertical';
}

export function TextAreaFormField<T extends FieldValues>({
  field,
  id,
  label,
  placeholder,
  options,
  disabled,
  className,
  tooltipContent,
  activeResize = 'none',
}: GenericFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();

  const fieldError = get(errors, field)?.message as string | undefined;
  return (
    <Controller
      control={control}
      name={field}
      rules={{ ...options, deps: [field] }}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div className={`flex flex-col ${className}`}>
          <div className="flex">
            <label className="text-paragraph-medium text-black">{label}</label>
            {options?.required && <span className="text-paragraph-medium text-danger-60">*</span>}
            {tooltipContent && <Tooltip content={tooltipContent} />}
          </div>
          <textarea
            data-cy={id}
            className={twMerge(
              `h-32 resize-${activeResize}`,
              disabled ? 'text-neutral-60' : 'text-black',
              fieldError
                ? 'focus:ring-danger-60 focus:border-danger-60'
                : 'focus:ring-primary-60 focus:border-primary-60',
              'border-1 border-brand-gray-20 rounded-md text-paragraph-medium ',
            )}
            id={id}
            name={name}
            value={value}
            placeholder={placeholder || 'Breve descrição sobre o diferencial da empresa. Até 150 caracteres.'}
            required={false}
            readOnly={disabled}
            onBlur={onBlur}
            onChange={onChange}
          />
          {fieldError && <span className="text-paragraph-small text-danger-60">{fieldError}</span>}
        </div>
      )}
    />
  );
}
