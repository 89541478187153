import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useAuth } from '@src/auth-wrapper';

import {
  LOW_TENSION_OPTING_INITIAL_EXPENSES,
  LOW_TENSION_STANDARD_INITIAL_EXPENSES,
  DEFAULT_CLARKE_RETAIL_PRICE_MARGIN_PERCENTAGE,
  TARIFFS_INDEXER,
  TARIFF,
} from '@utils/constants';

import { ISimulatorFormUnitSection } from '@components/molecules/form/simulator/sections/simulator-form-units-section/simulator-form-unit-section';
import { BuildManagementSimulatorFields, BuildRetailSimulatorFields } from './helper';

interface ISimulatorUnitGeneralSubsection extends ISimulatorFormUnitSection {
  isLowTension: boolean;
  isRetailSimulator: boolean;
}
export function SimulatorUnitGeneralSubsection({
  unitNum,
  fieldsController,
  isLowTension = false,
  isRetailSimulator,
}: ISimulatorUnitGeneralSubsection) {
  const { watch, setValue, trigger } = useFormContext();
  const { user } = useAuth();
  const tariffInput = useWatch({ name: `units.${unitNum}.tariff` }) as keyof typeof TARIFFS_INDEXER;

  useEffect(() => {
    const initialExpensesValueByTariff = getInitialExpensesValueByTariff(tariffInput);
    initialExpensesValueByTariff && setValue(`units.${unitNum}.initialExpenses`, initialExpensesValueByTariff);
  }, [tariffInput]);

  const getInitialExpensesValueByTariff = (tariffInput: keyof typeof TARIFFS_INDEXER) => {
    if (tariffInput === TARIFF.B3_OPTING) {
      return LOW_TENSION_OPTING_INITIAL_EXPENSES;
    } else if (tariffInput === TARIFF.B3_STANDARD) {
      return LOW_TENSION_STANDARD_INITIAL_EXPENSES;
    } else {
      return null;
    }
  };
  const isStateES =
    watch(`units.${unitNum}.energyDistributionCompany`) == '29' ||
    watch(`units.${unitNum}.energyDistributionCompany`) == '30';

  const customFee = watch('customFee');

  const handleChangeIsCachoeiroCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (!customFee) {
        setValue('customFee.type', 'PERCENTAGE');
        setValue('customFee.auxiliarFields.uniqueMargin', DEFAULT_CLARKE_RETAIL_PRICE_MARGIN_PERCENTAGE);
      }
    }
  };

  return !isRetailSimulator ? (
    <BuildManagementSimulatorFields
      {...{
        unitNum,
        fieldsController,
        handleChangeIsCachoeiroCustomer,
        isStateES,
        trigger,
        user,
      }}
    />
  ) : (
    <BuildRetailSimulatorFields
      {...{
        unitNum,
        fieldsController,
        handleChangeIsCachoeiroCustomer,
        isStateES,
        trigger,
        user,
        isLowTension,
      }}
    />
  );
}
