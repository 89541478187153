import { twMerge } from 'tailwind-merge';
import LocationIcon from '@assets/newProposal/map-pin.svg';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import { NewIconCard } from '@components/atoms/new-proposal/icon-card/index';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import { whatWeDoCardData, whiteLabelSupplierCardData } from '@components/molecules/new-proposal/static';
import useWhiteLabel from '@hooks/use-white-label';
import './styles.css';

function WhatWeDoSection() {
  const { isWhiteLabelProposal } = useWhiteLabel();
  const legend = {
    default: 'O que a Clarke faz?',
    whiteLabelSupplier: 'Sobre',
  };
  return (
    <SlideContainer className="xl:min-h-screen h-fit">
      <section className="what-we-do-section-styles  min-h-fit xl:px-20">
        <LegendContainer
          isWhiteLabelProposal={isWhiteLabelProposal}
          legend={isWhiteLabelProposal ? legend.whiteLabelSupplier : legend.default}
        />

        <div className="what-we-do-container">
          <div className="what-we-do-div-container">
            {isWhiteLabelProposal ? (
              <>
                <h1 className={twMerge('title-styles-wl', `text-white-label-main min-h-fit`)}>
                  A migração para o mercado livre de energia pode ser divida em 04 passos
                </h1>
                <span className="text-styles-span">Nossa empresa ajuda o seu negócio com todo o processo.</span>
              </>
            ) : (
              <>
                <h1 className="title-styles">Somos uma das maiores gestoras de varejo de energia no Brasil</h1>
                <span className="text-styles-span">
                  Ajudamos o seu negócio a<b className="text-bold text-primary-70 mx-3">economizar na conta de luz</b>{' '}
                  por meio da migração para o Mercado Livre de Energia{' '}
                  <b className="text-bold text-primary-70">em 04 passos</b>:
                </span>
              </>
            )}
          </div>

          <div className="grid-styles">
            {(isWhiteLabelProposal ? whiteLabelSupplierCardData : whatWeDoCardData).map((item, index) => (
              <div key={item.title} className="card-styles">
                {index === 0 && (
                  <span
                    className={twMerge(
                      'card-span-styles bg-primary-70 p-2',
                      isWhiteLabelProposal && `bg-white-label-main`,
                    )}
                  >
                    <img src={LocationIcon} alt="Location Icon" />
                    <p className="text-paragraph-small">Estamos aqui</p>
                  </span>
                )}
                <NewIconCard
                  key={`proposal-what-we-do-section-card-${item.title}`}
                  {...item}
                  index={index}
                  isWhiteLabelProposal={isWhiteLabelProposal}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </SlideContainer>
  );
}

export default WhatWeDoSection;
