export const eventLabels = {
  PAGE_VIEW_HOME: 'PAGE_VIEW_HOME',
  PAGE_VIEW_SIMULATOR: 'PAGE_VIEW_SIMULATOR',
  PAGE_VIEW_HISTORY: 'PAGE_VIEW_HISTORY',
  BUTTON_ADD_WHITE_LABEL_SUPPLIER: 'BUTTON_ADD_WHITE_LABEL_SUPPLIER',
  BUTTON_COPYDATA_SIMULATOR: 'BUTTON_COPYDATA_SIMULATOR',
  BUTTON_RECOVERDATA_SIMULATOR: 'BUTTON_RECOVERDATA_SIMULATOR',
  BUTTON_PASTEDATA_SIMULATOR: 'BUTTON_PASTEDATA_SIMULATOR',
  BUTTON_SIMULATE_SIMULATOR: 'BUTTON_SIMULATE_SIMULATOR',
  BUTTON_FULL_PROPOSAL_DRAWER: 'BUTTON_FULL_PROPOSAL_DRAWER',
  BUTTON_SIMPLIFIED_PROPOSAL_DRAWER: 'BUTTON_SIMPLIFIED_PROPOSAL_DRAWER',
  BUTTON_DETAILED_ANALYSIS_DRAWER: 'BUTTON_DETAILED_ANALYSIS_DRAWER',
  BUTTON_DETAILED_ANALYSIS: 'BUTTON_DETAILED_ANALYSIS_GC_COMPARE',
  BUTTON_GENERATE_DOCUMENT_DRAWER: 'BUTTON_GENERATE_DOCUMENT_DRAWER',
  BUTTON_REPEAT_SIMULATION_DRAWER: 'BUTTON_REPEAT_SIMULATION_DRAWER',
  BUTTON_REUSE_PROPOSAL_DRAWER: 'BUTTON_REUSE_PROPOSAL_DRAWER',
  BUTTON_DELETE_PROPOSAL_DRAWER: 'BUTTON_DELETE_PROPOSAL_DRAWER',
  BUTTON_GENERATE_CONTRACT_DRAWER: 'BUTTON_GENERATE_CONTRACT_DRAWER',
  BUTTON_MANAGEMENT_HOME: 'BUTTON_MANAGEMENT_HOME',
  BUTTON_RETAIL_HOME: 'BUTTON_RETAIL_HOME',
  BUTTON_FEASIBILITY_HOME: 'BUTTON_FEASIBILITY_HOME',
  BUTTON_ACCOUNTS_HOME: 'BUTTON_ACCOUNTS_HOME',
  BUTTON_ADMIN_HOME: 'BUTTON_ADMIN_HOME',
  FORM_STARTED: 'FORM_STARTED',
  FORM_SUBMITTED: 'FORM_SUBMITTED',
  ADD_UNIT_BUTTON_CLICKED: 'ADD_UNIT_BUTTON_CLICKED',
  EQUAL_ENERGY_DEMAND_OPTION_CHECKED: 'EQUAL_ENERGY_DEMAND_OPTION_CHECKED',
  ERROR_MESSAGE_WHEN_CALCULATION_FAILS: 'ERROR_MESSAGE_WHEN_CALCULATION_FAILS',
  ACL_CALCULATION_METHOD_CHANGED: 'ACL_CALCULATION_METHOD_CHANGED',
  INSERT_MANUAL_DISCOUNT_CHECKED: 'INSERT_MANUAL_DISCOUNT_CHECKED',
  INSERT_MANUAL_DISCOUNTS: 'INSERT_MANUAL_DISCOUNTS',
  INSERT_MANUAL_PRICES_CHECKED: 'INSERT_MANUAL_PRICES_CHECKED',
  INSERT_MANUAL_PRICES: 'INSERT_MANUAL_PRICES',
  SEARCH_IN_SIMULATIONS_LIST: 'SEARCH_IN_SIMULATIONS_LIST',
  CLICK_VIEW_SIMULATION_DESCRIPTION: 'CLICK_VIEW_SIMULATION_DESCRIPTION',
  CLICK_OPEN_SIMULATION: 'CLICK_OPEN_SIMULATION',
  CLICK_GENERATE_DOCUMENT_IN_SIMULATION_CALCULATOR_RESULT: 'CLICK_GENERATE_DOCUMENT_IN_SIMULATION_CALCULATOR_RESULT',
  CLICK_COPY_INPUTS_IN_SIMULATION_DESCRIPTION: 'CLICK_COPY_INPUTS_IN_SIMULATION_DESCRIPTION',
  SIMULATION_PAGE_VIEWED: 'SIMULATION_PAGE_VIEWED',
  CALCULATION_RESULT_PAGE_VIEWED: 'CALCULATION_RESULT_PAGE_VIEWED',
  CALCULATION_RESULT_PAGE_SELECTED_PRODUCT_CHANGED: 'CALCULATION_RESULT_PAGE_SELECTED_PRODUCT_CHANGED',
  CALCULATION_RESULT_PAGE_SELECTED_YEARS_CHANGED: 'CALCULATION_RESULT_PAGE_SELECTED_YEARS_CHANGED',
  CALCULATION_RESULT_PAGE_SELECTED_UNITS_CHANGED: 'CALCULATION_RESULT_PAGE_SELECTED_UNITS_CHANGED',
  CALCULATION_RESULT_PAGE_CLICK_ON_GENERATE_SIMULATION: 'CALCULATION_RESULT_PAGE_CLICK_ON_GENERATE_SIMULATION',
  CALCULATION_RESULT_PAGE_CLICK_ON_REDO_CALCULUS: 'CALCULATION_RESULT_PAGE_CLICK_ON_REDO_CALCULUS',
  CALCULATION_RESULT_PAGE_CLICK_ON_REUSE_CALCULUS: 'CALCULATION_RESULT_PAGE_CLICK_ON_REUSE_CALCULUS',
  CALCULATION_REUSE_MODAL_CLICK_ON_CHANGE_TARGET_SIMULATION:
    'CALCULATION_REUSE_MODAL_CLICK_ON_CHANGE_TARGET_SIMULATION',
  CALCULATION_REUSE_MODAL_CLICK_ON_GENERATE_SIMULATION: 'CALCULATION_REUSE_MODAL_CLICK_ON_GENERATE_SIMULATION',
  CALCULATION_REUSE_MODAL_SHOWS_MESSAGE_ERROR: 'CALCULATION_REUSE_MODAL_SHOWS_MESSAGE_ERROR',
};
