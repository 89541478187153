import CompetitiveAnalysis from '@components/molecules/general/simulation/competitor-comparison';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import { ISimulationResultsProps } from '.';

export function CompetitiveAnalysisProposal({ simulationResults }: ISimulationResultsProps) {
  if (simulationResults && simulationResults?.competitiveAnalysis) {
    return (
      <SlideContainer>
        <section className="min-w-full min-h-full h-fit w-fit flex flex-col justify-center items-center py-10 px-7">
          <LegendContainer legend="Análise Competitiva" />
          <CompetitiveAnalysis competitiveAnalysis={simulationResults?.competitiveAnalysis} isNewProposal={true} />
        </section>
      </SlideContainer>
    );
  }
  return <></>;
}
