import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { IProductTwoSimulatorInputs } from '@contexts/product-two/types';
import { SimulatorTypeEnum } from '@contexts/types';

import SimulatorFormGeneralSection from '@components/molecules/form/simulator/sections/simulator-form-general-section';
import SimulatorFormUnitsSection from '@components/molecules/form/simulator/sections/simulator-form-units-section';
import SimulatorFormTargetYearsSection from '@components/molecules/form/simulator/sections/simulator-form-target-years-section';
import SimulatorFormCompetitorsListSection from '@components/molecules/form/simulator/sections/simulator-form-competitors-list-section';
import SimulatorFormTotalMigrationCostSection from '@components/molecules/form/simulator/sections/simulator-form-total-migration-cost-section';
import SimulatorFormPremisesSection from '@components/molecules/form/simulator/sections/simulator-form-premises-section/index';
import SimulatorFormExclusiveTraderSection from '@components/molecules/form/simulator/sections/simulator-form-exclusive-trader-section';
import SimulatorFormFinancialProjectionSection from '@components/molecules/form/simulator/sections/simulator-form-financial-projection-section';

interface IProductTwoSimulatorFormCore {
  formController: UseFormReturn<IProductTwoSimulatorInputs, any>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onSubmit: (values: IProductTwoSimulatorInputs) => void;
  simulatorType: keyof typeof SimulatorTypeEnum;
}

const ProductTwoSimulatorFormCore = ({
  simulatorType,
  formController,
  formRef,
  onSubmit,
}: IProductTwoSimulatorFormCore) => {
  return (
    <form
      ref={formRef}
      onSubmit={formController.handleSubmit(onSubmit)}
      className="flex flex-col flex-wrap w-full gap-3 justify-start"
    >
      <SimulatorFormGeneralSection<IProductTwoSimulatorInputs>
        fields={{
          aclType: true,
          submarket: true,
          proposalValidity: true,
        }}
      />
      <SimulatorFormTargetYearsSection />
      <SimulatorFormFinancialProjectionSection uniqueTargetYears />
      <SimulatorFormExclusiveTraderSection />
      <SimulatorFormCompetitorsListSection<IProductTwoSimulatorInputs> simulatorType={simulatorType} />
      <SimulatorFormUnitsSection<IProductTwoSimulatorInputs>
        fieldsController={{
          powerGeneratorCost: true,
          tariff: true,
          demand: true,
          lowTensionType: true,
          operationHoursRange: true,
          companyType: true,
          migrationDate: true,
        }}
      />
      <SimulatorFormPremisesSection simulatorType={simulatorType} />
      <SimulatorFormTotalMigrationCostSection />
    </form>
  );
};

export default ProductTwoSimulatorFormCore;
