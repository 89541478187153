/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import { Bar } from 'react-chartjs-2';

interface IProjectionChartProps {
  labels: string[];
  datasets: any[];
  options: any;
  plugins: any;
}

const BarChart = ({ labels, datasets, options, plugins }: IProjectionChartProps) => (
  <Bar
    data={{
      labels,
      datasets,
    }}
    options={options}
    plugins={plugins}
  />
);

export default BarChart;
