import useWhiteLabel from '@hooks/use-white-label';
import bgLogo from '@assets/newProposal/raios-icon.svg';
import logo from '@assets/newProposal/heroLogo.svg';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';

import './styles.css';

function ContactUs() {
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();

  return (
    <SlideContainer>
      <section className="contact-us-section">
        {isWhiteLabelProposal ? (
          <div className="w-full min-h-fit h-screen flex flex-col gap-10 justify-center items-center">
            <img
              className="w-10/12 sm:w-1/3 rounded-xl"
              src={whiteLabelSupplier?.presentation?.logoUrl as string}
              alt={`logo do fornecedor ${whiteLabelSupplier?.presentation?.name}`}
            />
            <span className="text-heading-medium sm:text-heading-xlarge text-white-label-main py-5  xl:px-5 whitespace-nowrap">
              <p>E-mail: {whiteLabelSupplier?.presentation?.email}</p>
              <p>Telefone: {whiteLabelSupplier?.presentation?.phone}</p>
            </span>
          </div>
        ) : (
          <>
            <img src={bgLogo} alt="Logo da Clarke" className="contact-us-image-logo" />
            <div className="contact-us-div-container">
              <img src={logo} alt="Logo da Clarke" className="sm:h-40 xl:h-48" />
              <span className="text-heading-xsmall text-primary-70 py-5  xl:px-5 whitespace-nowrap">
                <p>E-mail: vendas@clarke.com.br</p>
                <p>Telefone: +55 (11) 97401-9971</p>
              </span>
            </div>
          </>
        )}
      </section>
    </SlideContainer>
  );
}

export default ContactUs;
