import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { ContractFormCustomerSignatorySection } from './contract-form-customer-section';
import { IManagementContractPayload } from '@contexts/management-contract/types';
import { useFieldArray } from 'react-hook-form';
import { SelectFormField } from '@components/atoms/form/fields/select-form-field';

interface IContractFormCustomersSection {
  fieldsController?: Record<keyof IManagementContractPayload | string, boolean>;
}

enum CustomerProcurationValidityEnum {
  ONE_YEAR = 'Doze meses',
  UNDETERMINED = 'Indeterminada',
}

const contractSignatoriesDefaultValues = {
  fullName: undefined,
  cpfNumber: undefined,
  identityNumber: undefined,
  email: undefined,
};

const customerProcurationValidityOptions = [
  { value: 'ONE_YEAR', optionLabel: CustomerProcurationValidityEnum.ONE_YEAR },
  { value: 'UNDETERMINED', optionLabel: CustomerProcurationValidityEnum.UNDETERMINED },
];

function ContractFormCustomersSection({ fieldsController }: IContractFormCustomersSection) {
  const { fields, append, remove } = useFieldArray<IManagementContractPayload>({
    name: 'inputs.signatories.customerSignatories',
    rules: { minLength: 1, required: true },
  });

  return (
    <ContractSectionContainer>
      <>
        <ContractTitleAndSubtitleSubsection
          title="Procurações"
          subtitle="Insira os dados usados para a(s) assinatura(s) do cliente"
        />
        <div className="flex flex-col w-full ">
          <div className="border-b border-gray-300 pb-5 grid grid-col-1 md:grid-cols-2">
            <SelectFormField<IManagementContractPayload>
              id="inputs.signatories.customerProcurationValidity"
              field="inputs.signatories.customerProcurationValidity"
              label="Validade da procuração"
              inputOptions={customerProcurationValidityOptions}
              options={{ required: { value: true, message: 'Campo validade da procuração é obrigatório' } }}
            />
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-col gap-12 w-full">
              {fields.map((field, index) => (
                <ContractFormCustomerSignatorySection
                  key={field.id}
                  customerSignatoryNum={index}
                  removeCustomerSignatory={remove}
                  appendCustomerSignatory={() => append(contractSignatoriesDefaultValues)}
                  fieldsController={fieldsController}
                  customerSignatoryLength={fields.length}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    </ContractSectionContainer>
  );
}

export default ContractFormCustomersSection;
