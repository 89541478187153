import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import useProductTwo from '@hooks/use-product-two';

import {
  IProductTwoSimulationExtendedResult,
  IProductTwoSimulatorInputs,
  IProductTwoProposal,
} from '@contexts/product-two/types';

import { parseProductTwoProposal, parseProductTwoProposalScenarios } from '@contexts/product-two/parser';

import { SIMULATION_NOT_FOUND_PATH } from '@routers/constants';
import SimplifiedProposalSectionOne from '@components/organisms/simplified-proposal/simplified-proposal-section-one';
import SimplifiedProposalSectionTwo from '@components/organisms/simplified-proposal/simplified-proposal-section-two';
import SimplifiedProposalSectionThree from '@components/organisms/simplified-proposal/simplified-proposal-section-three';
import SimplifiedProposalAnnualBreakdown from '@components/molecules/simplified-proposal/simplified-proposal-annual-breakdown';
import { getAnnualBreakdownDataList } from '@components/organisms/simplified-proposal/helper';

const ProductTwoSimplifiedProposal = () => {
  const getGroupManagementPrice = (simulationInputs?: IProductTwoSimulatorInputs) => {
    return simulationInputs?.units?.reduce((accumulated, unit) => accumulated + unit.managementPrice, 0);
  };
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { getProductTwoProposalById } = useProductTwo();

  const [proposal, setProposal] = React.useState<IProductTwoProposal>();
  const [simulationResult, setSimulationResult] = React.useState<IProductTwoSimulationExtendedResult>();

  const getProposalScenariosAndGroupManagementPrice = (proposal: IProductTwoProposal) => {
    const bestRetailTraderResult = proposal?.calculatorMetadata?.default?.bestRetailTraderResult;
    const simulationInputs = bestRetailTraderResult?.inputs;

    const groupManagementPrice = getGroupManagementPrice(simulationInputs);

    const parsedSimulationScenarios = parseProductTwoProposalScenarios(
      simulationInputs,
      bestRetailTraderResult,
      groupManagementPrice,
    );
    setSimulationResult(parsedSimulationScenarios);
  };

  const getProposal = (id: string) => {
    getProductTwoProposalById(id)
      .then((response) => {
        if (response.data) {
          const parsedProposal = parseProductTwoProposal(response.data.data);
          setProposal(parsedProposal);
          getProposalScenariosAndGroupManagementPrice(parsedProposal);
        } else navigate(SIMULATION_NOT_FOUND_PATH);
      })
      .catch(() => navigate(SIMULATION_NOT_FOUND_PATH));
  };
  const targetYear = proposal?.inputs.targetYear;
  const annualBreakdownDataList = getAnnualBreakdownDataList(simulationResult, targetYear);

  React.useEffect(() => {
    if (id) getProposal(id);
  }, [id]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 bg-neutral-10 gap-7 lg:gap-9 md:p-9 xl:p-12">
      <div className="col-span-full">
        {simulationResult && (
          <SimplifiedProposalSectionOne
            leadName={proposal?.lead.name}
            totalSaving={simulationResult?.totalSaving}
            totalSavingByMonth={simulationResult?.totalSavingByMonth}
            savingPercentage={simulationResult?.savingPercentage}
            proposalValidity={simulationResult.proposalValidity}
          />
        )}
      </div>
      <div className="col-span-full">
        <SimplifiedProposalSectionTwo simulationExtendedResult={simulationResult} />
      </div>
      <div className="col-span-1 md:col-span-full px-5 lg:px-0">
        {annualBreakdownDataList && annualBreakdownDataList.length > 0 && (
          <SimplifiedProposalAnnualBreakdown annualBreakdownDataList={annualBreakdownDataList} />
        )}
      </div>
      <div className="col-span-full">
        <SimplifiedProposalSectionThree />
      </div>
    </div>
  );
};

export default ProductTwoSimplifiedProposal;
