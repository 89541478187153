import React from 'react';
import { Modal } from '@clarke-energia/foton';

interface IModalDeleteProposal {
  leadName: string | undefined;
  selectedLeadId?: string;
  deleteAllProposalsByLeadId: (leadI: string) => Promise<void>;
  openProposalsDeleteModal: boolean;
  setOpenProposalsDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDeleteAllProposalsByLeadId = ({
  leadName,
  selectedLeadId,
  deleteAllProposalsByLeadId,
  openProposalsDeleteModal,
  setOpenProposalsDeleteModal,
}: IModalDeleteProposal): JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDeleteProposalsButtonClick = () => {
    setLoading(true);
    selectedLeadId && deleteAllProposalsByLeadId(selectedLeadId).finally(() => setLoading(false));
    setOpenProposalsDeleteModal(false);
  };
  return (
    <Modal
      open={openProposalsDeleteModal}
      setOpen={() => setOpenProposalsDeleteModal(false)}
      title={`Deletar todas as propostas do grupo ${leadName}`}
      description="Tem certeza que deseja excluir todas as propostas vinculadas a esse Grupo Comercial?"
      modalButtons={{
        primary: {
          label: 'Excluir',
          style: { backgroundColor: 'red', width: '100%' },
          onClick: handleDeleteProposalsButtonClick,
          loading,
        },
        secondary: {
          label: 'Cancelar',
          style: { width: '100%' },
          onClick: () => setOpenProposalsDeleteModal(false),
          disabled: loading,
        },
      }}
    />
  );
};

export default ModalDeleteAllProposalsByLeadId;
