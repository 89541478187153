export function SuppliersProjectionsHistoryTableHead() {
  return (
    <thead>
      <th className="text-paragraph-small sm:text-paragraph-medium 2xl:text-paragraph-large grid grid-cols-10 gap-4 place-content-center font-bold w-full min-w-fit h-9 border-b-1 border-brand-gray-20 ">
        <td className="col-span-4 text-start">Empresa</td>
        <td className="col-span-4 text-start">Número de usuários</td>
        <td className="col-span-1 text-start" />
      </th>
    </thead>
  );
}
