import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Checkbox } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';

import { defaultNumericFormFieldOptionsAsRequired } from '@utils/form';

import { ISimulationPayloadBase, ISimulationUnitPayloadBase } from '@contexts/types';
import { parseUnitConsumptionHistory } from '@contexts/calculator/units-payload-handlers';
import useWhiteLabel from '@hooks/use-white-label';

import { fieldVerificator } from '@components/molecules/form/simulator/helper';
import { SimulatorFormTooltipsContentIndexer as tooltipsContent } from '@components/molecules/form/simulator/static-data';
import { calculateManagementPriceSuggestion } from '@components/atoms/form/simulation-units-section/management-price-suggestion';

import { NumericFormField } from '../fields/numeric-form-field';

interface ISimulatorUnitBudgetSubsection {
  fieldsController?: Record<string, boolean>;
  unitNum: number;
}
export function SimulatorUnitBudgetSubsection({ unitNum, fieldsController }: ISimulatorUnitBudgetSubsection) {
  const [useAutoManagementPrice, setUseAutoManagementPrice] = React.useState<boolean>(true);

  const { setValue } = useFormContext<ISimulationPayloadBase>();
  const unitValues = useWatch<ISimulationPayloadBase>({ name: `units.${unitNum}` });

  const { user } = useAuth();
  const { whiteLabelSupplier } = useWhiteLabel();

  const handleManagementValueButton = () => {
    const { consumptionOffPeak, consumptionPeak } = parseUnitConsumptionHistory({
      ...(unitValues as ISimulationUnitPayloadBase),
    }) as ISimulationUnitPayloadBase;
    const suggestedManagementPrice =
      user?.isWhiteLabel && whiteLabelSupplier?.prices?.management
        ? whiteLabelSupplier.prices?.management
        : calculateManagementPriceSuggestion(consumptionOffPeak, consumptionPeak);

    setValue(`units.${unitNum}.managementPrice`, suggestedManagementPrice as number);
  };

  const handleOnChangeEnableAutomaticManagementPriceCheckBox = (isChecked: boolean) => {
    if (isChecked) {
      handleManagementValueButton();
      setUseAutoManagementPrice(true);
    } else {
      setUseAutoManagementPrice(false);
    }
  };

  React.useEffect(() => {
    if (whiteLabelSupplier?.prices?.management) {
      handleManagementValueButton();
      setUseAutoManagementPrice(true);
    }
  }, [whiteLabelSupplier]);

  const unitConsumptionsWatch = useWatch({
    name: [`units.${unitNum}.consumptionOffPeak`, `units.${unitNum}.consumptionPeak`],
  });

  React.useEffect(() => {
    if (useAutoManagementPrice) handleManagementValueButton();
  }, [unitConsumptionsWatch, whiteLabelSupplier]);

  if (!user?.isCommercialDealer) {
    return (
      <div>
        <div className="flex flex-col mb-5">
          <h2 className="text-paragraph-large font-bold">Orçamento</h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4">
          <div className="grid grid-cols-1">
            {fieldVerificator({
              fieldName: 'managementPrice',
              fieldPriority: 'PRIMARY',
              fields: fieldsController,
            }) && (
              <div className="flex flex-col gap-3">
                <NumericFormField<ISimulationPayloadBase>
                  id={`units.${unitNum}.managementPrice`}
                  field={`units.${unitNum}.managementPrice`}
                  label="Custo de Gestão / mês"
                  formatProps={{
                    mask: '_',
                    allowNegative: false,
                    fixedDecimalScale: false,
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                    decimalScale: 10,
                    prefix: 'R$ ',
                  }}
                  options={defaultNumericFormFieldOptionsAsRequired}
                  disabled={useAutoManagementPrice}
                  tooltipContent={tooltipsContent.unit.managementPrice}
                />
                <Checkbox
                  name={`enable-automatic-management-price-for-unit-${unitNum}`}
                  label="Calcular automaticamente"
                  onChange={(e) => handleOnChangeEnableAutomaticManagementPriceCheckBox(e.target.checked)}
                  defaultChecked
                />
              </div>
            )}
          </div>
          {fieldVerificator({
            fieldName: 'initialExpenses',
            fieldPriority: 'PRIMARY',
            fields: fieldsController,
          }) && (
            <NumericFormField<ISimulationPayloadBase>
              id={`units.${unitNum}.initialExpenses`}
              field={`units.${unitNum}.initialExpenses`}
              label="Investimento Inicial"
              formatProps={{
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 10,
                prefix: 'R$ ',
              }}
              tooltipContent={tooltipsContent.unit.initialExpenses}
            />
          )}
        </div>
      </div>
    );
  } else return <></>;
}
