import teamBackground from '@assets/images/team.png';
import eyeIcon from '@assets/images/Eye.png';
import documentReportIcon from '@assets/images/document-report.png';
import shieldCheckIcon from '@assets/images/shield-check.png';
import documentSearchIcon from '@assets/images/document-search.png';
import ConclusiveSectionLayout from '@components/molecules/product-two/simulation/conclusive-section-layout';

const gadgetWithIconContent = [
  {
    imgSrc: eyeIcon,
    titleText: 'Especialistas de olho na sua conta',
    text: 'Temos um time de especialista dedicado em cuidar e antecipar questões na sua conta de luz.',
  },
  {
    imgSrc: documentReportIcon,
    titleText: 'Relatórios estratégicos',
    text: 'Elaboração de diversos relatórios e estudos de oportunidades de economia.',
  },
  {
    imgSrc: shieldCheckIcon,
    titleText: 'Análises de viabilidade',
    text: 'Somos a gestora da sua conta, você não precisa se preocupar com nada.',
  },
  {
    imgSrc: documentSearchIcon,
    titleText: 'Monitoramento constante',
    text: 'Todas as contas e processos do Mercado Livre passam por uma detalhada conferência dos nossos especialistas.',
  },
];

const AboutTeam: React.FC = () => {
  return (
    <ConclusiveSectionLayout
      mainTitle="Time de engenheiros e especialistas para a sua conta de luz."
      backgroundImg={teamBackground}
      gadgetWithIconContent={gadgetWithIconContent}
    />
  );
};

export default AboutTeam;
