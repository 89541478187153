import { UseFormSetError } from 'react-hook-form';
import { get } from 'lodash';

import { IManagementContract, IManagementContractPayload } from '@contexts/management-contract/types';

const signatoryFields = ['fullName', 'identityNumber', 'cpfNumber', 'email'];
const requiredNonArrayFieldsIndexer: Record<string, Array<string>> = {
  'inputs.customer': ['name', 'headOfficeZipCode', 'headOfficeAddress', 'financeDepartmentEmail'],
  'inputs.payment': ['totalMigrationPrice', 'managementPricePerUnit'],
  'inputs.forum': ['city', 'state'],
  'inputs.signatories.clarkeSignatory': signatoryFields,
  'inputs.signatories': ['customerProcurationValidity'],
};

const requiredArrayFieldsIndexer: Record<string, Array<string>> = {
  'inputs.units': ['name', 'address', 'installationNumber'],
  'inputs.signatories.customerSignatories': signatoryFields,
};

function validateContractFormFields(
  formValues: IManagementContract | IManagementContractPayload,
  setError: UseFormSetError<IManagementContractPayload>,
): boolean {
  let validationResponse: boolean = true;

  const setValidationError = (fieldName: keyof IManagementContractPayload) => {
    validationResponse = false;
    setError(fieldName, { type: 'custom', message: 'Este campo é obrigatório' });
  };

  const validateNestedField = (fieldPath: string, fieldName: string, index?: number) => {
    let completeFieldNameParts = [fieldPath, index, fieldName];
    if (index === undefined) completeFieldNameParts.splice(1, 1);

    const parsedFieldName = completeFieldNameParts.join('.') as keyof IManagementContractPayload;
    const fieldValue = get(formValues, parsedFieldName);
    if (fieldValue === undefined || fieldValue === null || fieldValue === '') setValidationError(parsedFieldName);
  };

  Object.entries(requiredNonArrayFieldsIndexer).forEach(([fieldPath, nestedFieldsNames]) => {
    nestedFieldsNames.map((nestedFieldName) => {
      validateNestedField(fieldPath, nestedFieldName);
    });
  });

  Object.entries(requiredArrayFieldsIndexer).forEach(([fieldPath, nestedFieldsNames]) => {
    const arrayFieldItems = get(formValues, fieldPath) as Array<any>;
    arrayFieldItems?.map((_, index) => {
      nestedFieldsNames.map((nestedFieldName) => validateNestedField(fieldPath, nestedFieldName, index));
    });
  });

  return validationResponse;
}

export default validateContractFormFields;
