import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import { useAuth } from '@src/auth-wrapper';
import config from '@config';

import { ISalesContext } from './types';

export const SalesContext = createContext({} as ISalesContext);

interface Provider {
  children: React.ReactNode;
}

const SalesProvider: React.FC<Provider> = ({ children }) => {
  const {
    authStatus: { accessToken },
  } = useAuth();

  const SalesApiClient = axios.create({
    baseURL: config.SALES_API_HOST,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  function convertHtmlToPdfHandler(
    url: string,
    filename: string,
    width?: number,
    height?: number,
    apiVersion = 'v2',
  ): AxiosPromise {
    const cleanFilename = filename.replace(/[&]/g, '_e_');
    return SalesApiClient.get(
      `/api/${apiVersion}/html-to-pdf?url=${url}&filename=${cleanFilename}${width ? `&width=${width}` : ''}${
        height ? `&height=${height}` : ''
      }`,
    );
  }

  return (
    // @ts-expect-error -> generic error caused by external lib
    <SalesContext.Provider
      value={{
        convertHtmlToPdfHandler,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export default SalesProvider;
