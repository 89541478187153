import SimplifiedProposalHeader from '@components/molecules/simplified-proposal/simplified-proposal-header';
import useWhiteLabel from '@hooks/use-white-label';
import { useAuth } from '@src/auth-wrapper';
import { useEffect } from 'react';

export interface ISimulationSimplifiedProposalSectionOne {
  leadName?: string;
  totalSaving: number;
  totalSavingByMonth: number;
  savingPercentage: number;
  proposalValidity?: string;
}

const SimplifiedProposalSectionOne = ({
  leadName,
  totalSaving,
  totalSavingByMonth,
  savingPercentage,
  proposalValidity,
}: ISimulationSimplifiedProposalSectionOne) => {
  const { user } = useAuth();
  const { getWhiteLabelSupplierByUserId } = useWhiteLabel();

  useEffect(() => {
    if (user && user.id) {
      getWhiteLabelSupplierByUserId(user.id);
    }
  }, [user]);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-7 lg:grid-cols-6 xl:gap-9">
      <div className="col-span-full xl:min-h-[21.5625rem]">
        <SimplifiedProposalHeader
          leadName={leadName}
          totalSaving={totalSaving}
          totalSavingByMonth={totalSavingByMonth}
          savingPercentage={savingPercentage}
          proposalValidity={proposalValidity}
        />
      </div>
    </div>
  );
};
export default SimplifiedProposalSectionOne;
