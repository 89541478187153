import { isObjectEmpty, transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase } from '@utils/common';

import {
  IContractedDemandSimulatorInputs,
  IContractedDemandSimulation,
  IContractedDemandSimulationPayload,
} from './types';

export const parseContractedDemandSimulationPayload = (simulationData: IContractedDemandSimulatorInputs) => {
  const simulationUnit = simulationData.units?.shift();

  if (!simulationUnit) return undefined;

  const consumptionPeakAsArray = !isObjectEmpty(simulationUnit.consumptionPeak)
    ? (Object.values(simulationUnit.consumptionPeak as Record<string, any>) as Array<number>)
    : undefined;
  const consumptionOffPeakAsArray = !isObjectEmpty(simulationUnit.consumptionOffPeak)
    ? (Object.values(simulationUnit.consumptionOffPeak as Record<string, any>) as Array<number>)
    : undefined;

  const parsedContractedDemandSimulationPayload: IContractedDemandSimulationPayload = {
    contractedDemandOffPeak: simulationUnit.aclContractedDemandOffPeak ?? null,
    contractedDemandPeak: simulationUnit.aclContractedDemandPeak ?? null,
    energyDistributionCompany: simulationUnit.energyDistributionCompany,
    icmsType: simulationUnit.icmsType,
    measuredDemandOffPeak: consumptionOffPeakAsArray,
    tariff: simulationUnit.tariff,
    measuredDemandPeak: consumptionPeakAsArray,
  };

  return transformObjectKeysToSnakeCase(parsedContractedDemandSimulationPayload);
};

export const parseContractedDemandSimulatorResult = (
  simulatorResponse: Record<string, any>,
): IContractedDemandSimulation => {
  return transformObjectKeysToCamelCase(simulatorResponse) as IContractedDemandSimulation;
};
