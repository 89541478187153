import { IProductOneUnitReport } from '@contexts/product-one/types';
import { IProductTwoUnitReport } from '@contexts/product-two/types';
import { ISimulationResultBase } from '@contexts/types';
import { ICurrentMarket, IPremises, ISampleMonth, ITargetMarket, IUnitReport } from '@pages/detailed-analysis/types';
import { formatDate } from '@utils/datetime';
import { formatNumber } from '@utils/numeral';

const getCurrentMarketUnitTableDataMediumVoltage = (unitReport: IUnitReport) => {
  const currentMarketUnitTableDataMediumVoltage = [
    {
      label: 'Demanda Ponta (kW)',
      value: formatNumber(unitReport.premises.acrContractedDemandPeak as number, '0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.acrContractedDemandPeak',
    },
    {
      label: 'Demanda Ponta (R$/kW)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.tariff.demandPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.demandPeak',
    },
    {
      label: 'Total Demanda Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.demandPeak as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.demandPeak',
    },
    {
      label: 'Demanda Fora Ponta (kW)	',
      value: formatNumber(unitReport.premises.acrContractedDemandOffPeak as number, '0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.acrContractedDemandOffPeak',
    },
    {
      label: 'Demanda Fora Ponta (R$/kW)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.tariff.demandOffPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.demandOffPeak',
    },
    {
      label: 'Total Demanda Fora Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.demandOffPeak as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.demandOffPeak',
    },
    {
      label: 'TUSD Ponta (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.consumptionPeak as number, '0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionPeak',
    },
    {
      label: 'TUSD Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffPeak as number,
        ' 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffPeak',
    },
    {
      label: 'Total TUSD Ponta (R$)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.distributionTariffPeakCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.distributionTariffPeakCost',
    },
    {
      label: 'TUSD Fora Ponta (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak as number, '0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak',
    },
    {
      label: 'TUSD Fora Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffOffPeak as number,
        '0,0.00',
      ),

      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffOffPeak',
    },
    {
      label: 'Total TUSD Fora Ponta (R$)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.distributionTariffOffPeakCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.distributionTariffOffPeakCost',
    },
    {
      label: 'TE Ponta (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak',
    },
    {
      label: 'TE Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffPeak as number,
        ' 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffPeak',
    },
    {
      label: 'Total TE Ponta (R$)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.electricityTariffPeakCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.electricityTariffPeakCost',
    },
    {
      label: 'TE Fora Ponta (MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffOffPeak as number,
        ' 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffOffPeak',
    },
    {
      label: 'TE Fora Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffOffPeak as number,
        ' 0,0.00',
      ),

      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffOffPeak',
    },
    {
      label: 'Total TE Fora Ponta (R$)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.electricityTariffOffPeakCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.electricityTariffOffPeakCost',
    },
    {
      label: 'Total custo gerador (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.generatorCost as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.generatorCost',
    },
    {
      label: 'Total Bandeiras Tarifárias',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.tariffFlag as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariffFlag',
    },
    {
      label: 'ICMS',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalWithIcms as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithIcms',
    },
    {
      label: 'PIS/COFINS',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalPisCofins as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalPisCofins',
    },
    {
      label: 'Total sem impostos (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes',
    },
    {
      label: 'Total com impostos (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes',
    },
  ];

  return currentMarketUnitTableDataMediumVoltage;
};

const getCurrentMarketUnitTableDataLowVoltage = (unitReport: IUnitReport) => {
  const currentMarketUnitTableDataLowVoltage = [
    {
      label: 'TUSD (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage',
    },
    {
      label: 'TUSD (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffLowVoltage as number,
        ' 0,0.00',
      ),

      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffLowVoltage',
    },
    {
      label: 'Total TUSD (R$)	custo com TUSD',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.distributionTariffLowVoltageCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.distributionTariffLowVoltageCost',
    },
    {
      label: 'TE (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage',
    },
    {
      label: 'TE (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffLowVoltage as number,
        ' 0,0.00',
      ),

      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffLowVoltage',
    },
    {
      label: 'Total TE (R$)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.currentMarket.electricityTariffLowVoltageCost as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.electricityTariffLowVoltageCost',
    },
    {
      label: 'bandeira tarifária',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.tariffFlag as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariffFlag',
    },
    {
      label: 'ICMS',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue',
    },
    {
      label: 'valor total de consumo sem impostos',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.rawTotalConsumptionCost as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.rawTotalConsumptionCost',
    },
    {
      label: 'valor total de consumo com icms',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalWithIcms as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithIcms',
    },
    {
      label: 'PIS COFINS',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalPisCofins as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalPisCofins',
    },
    {
      label: 'valor total sem taxas',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes',
    },
    {
      label: 'valor total com taxas',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes',
    },
  ];

  return currentMarketUnitTableDataLowVoltage;
};

export const getCurrentMarketUnitTableData = (unitReport: IUnitReport) => {
  const currentMarketUnitTableData =
    unitReport.premises.tariff == 8 || unitReport.premises.tariff == 9
      ? getCurrentMarketUnitTableDataLowVoltage(unitReport)
      : getCurrentMarketUnitTableDataMediumVoltage(unitReport);

  return currentMarketUnitTableData;
};

export const getTargetMarketUnitTableData = (unitReport: IUnitReport) => {
  const targetMarketUnitTableData = [
    {
      label: 'Demanda Ponta (kW)	',
      value: formatNumber(unitReport.premises.aclContractedDemandPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandPeak',
    },
    {
      label: 'Demanda Ponta (R$/kW)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.tariff.demandPeak as number, ' 0,0.00'),

      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.demandPeak',
    },
    {
      label: 'Total Demanda Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.demandPeak, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.demandPeak',
    },
    {
      label: 'Demanda Fora Ponta (kW)	',
      value: formatNumber(unitReport.premises.aclContractedDemandOffPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandOffPeak',
    },
    {
      label: 'Demanda Fora Ponta (R$/kW)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.tariff.demandOffPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.demandOffPeak',
    },
    {
      label: 'Total Demanda Fora Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.demandOffPeak, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.demandOffPeak',
    },
    {
      label: 'TUSD Ponta (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.consumptionPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.consumptionPeak',
    },
    {
      label: 'TUSD Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffPeak as number,
        ' 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffPeak',
    },
    {
      label: 'Total TUSD Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.distributionTariffPeakCost, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionTariffPeakCost',
    },
    {
      label: 'TUSD Fora Ponta (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.consumptionOffPeak as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.consumptionOffPeak',
    },
    {
      label: 'TUSD Fora Ponta (R$/MWh)',
      value: formatNumber(
        unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffOffPeak as number,
        ' 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffOffPeak',
    },
    {
      label: 'Total TUSD Fora Ponta (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.distributionTariffOffPeakCost, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionTariffOffPeakCost',
    },
    {
      label: 'Desconto Demanda Ponta',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.stimulatedDemandPeak, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.stimulatedDemandPeak',
    },
    {
      label: 'Desconto Demanda Fora Ponta',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.stimulatedDemandOffPeak, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.stimulatedDemandOffPeak',
    },
    {
      label: 'Desconto TUSD Ponta',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.stimulatedDistributionTariffPeak, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.stimulatedDistributionTariffPeak',
    },
    {
      label: 'ICMS distribuição',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.distributionIcms, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionIcms',
    },
    {
      label: 'ICMS eletricidade',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.electricityIcms, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.electricityIcms',
    },
    {
      label: 'ICMS',
      value: formatNumber(
        unitReport.sampleMonthSimulation.targetMarket.distributionIcms +
          unitReport.sampleMonthSimulation.targetMarket.electricityIcms,
        '$ 0,0.00',
      ),
      tooltipContent:
        'propriedade:  unitReport.sampleMonthSimulation.targetMarket.distributionIcms + unitReport.sampleMonthSimulation.targetMarket.electricityIcms	',
    },
    {
      label: 'PIS/COFINS',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.distributionPisCofins, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionPisCofins',
    },
    {
      label: 'Energia com perdas (MWh)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.electricityWithLosses as number, ' 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.electricityWithLosses',
    },
    {
      label: 'Destaque PIS/COFINS',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.pisCofinsHighlight, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.pisCofinsHighlight',
    },
    {
      label: 'Total Energia (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.electricityTariffCost, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.electricityTariffCost',
    },
    {
      label: 'ESS/EER',
      value: formatNumber(
        unitReport.sampleMonthSimulation.targetMarket.securityAndModulationEnergyCharges as number,
        '$ 0,0.00',
      ),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.securityAndModulationEnergyCharges',
    },
    {
      label: 'Contribuição CCEE',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarketContributionCcee, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarketContributionCcee',
    },
    {
      label: 'Gestão Clarke',
      value: formatNumber(unitReport.premises.managementPrice, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.managementPrice',
    },
    {
      label: 'Total sem impostos (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.totalWithoutTaxes, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.totalWithoutTaxes',
    },
    {
      label: 'Total com impostos (R$)',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.totalWithTaxes, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.totalWithTaxes',
    },
  ];

  return targetMarketUnitTableData;
};

export const getEconomyUnitTableData = (unitReport: IUnitReport) => {
  const economyUnitTableData = [
    {
      label: 'Economia Total (R$)',
      value: formatNumber(unitReport?.sampleMonthSimulation?.report?.totalSavingWithTaxes, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.report.totalSavingWithTaxes',
    },
    {
      label: 'Economia Total %',
      value: formatNumber(unitReport?.sampleMonthSimulation?.report?.totalSavingInPercentageWithTaxes, '%'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.report.totalSavingInPercentageWithTaxes',
    },
  ];

  return economyUnitTableData;
};
export const getLowVoltageFinalTariffTableData = (unitReport: IUnitReport) => {
  if (!unitReport.lowVoltageFinalTariffsList) {
    return {
      headers: [],
      rows: [],
    };
  }
  const headers = [
    'Data de ajuste',
    ...unitReport.lowVoltageFinalTariffsList.map((item) => formatDate(item.tariffAdjustmentDate, 'DD/MM/YYYY')),
  ];
  const tagStyle = '';
  const dataRows = [
    {
      row: [
        'TUSD (MWh)',
        ...unitReport.lowVoltageFinalTariffsList.map(
          (item) => formatNumber(item.distributionTariffLowVoltage, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
    {
      row: [
        'TE (MWh)',
        ...unitReport.lowVoltageFinalTariffsList.map(
          (item) => formatNumber(item.electricityTariffLowVoltage, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
  ];
  return {
    headers,
    rows: dataRows,
  };
};
export const getMediumVoltageFinalTariffTableData = (unitReport: IUnitReport) => {
  if (!unitReport.mediumVoltageFinalTariffsList) {
    return {
      headers: [],
      rows: [],
    };
  }
  const headers = [
    'Data de ajuste',
    ...unitReport.mediumVoltageFinalTariffsList.map((item) => formatDate(item.tariffAdjustmentDate, 'DD/MM/YYYY')),
  ];
  const tagStyle = '';
  const dataRows = [
    {
      row: [
        'Demanda Ponta (R$/kW)',
        ...unitReport.mediumVoltageFinalTariffsList.map((item) => formatNumber(item.demandPeak ?? 0, '$ 0,0.00') || ''),
      ],
      tagStyle,
    },
    {
      row: [
        'Demanda Fora Ponta (R$/kW)',
        ...unitReport.mediumVoltageFinalTariffsList.map(
          (item) => formatNumber(item.demandOffPeak ?? 0, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
    {
      row: [
        'TUSD Ponta (R$/MWh)',
        ...unitReport.mediumVoltageFinalTariffsList.map(
          (item) => formatNumber(item.distributionTariffPeak ?? 0, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
    {
      row: [
        'TUSD Fora Ponta (R$/MWh)',
        ...unitReport.mediumVoltageFinalTariffsList.map(
          (item) => formatNumber(item.distributionTariffOffPeak ?? 0, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
    {
      row: [
        'TE Ponta (R$/MWh)',
        ...unitReport.mediumVoltageFinalTariffsList.map(
          (item) => formatNumber(item.electricityTariffPeak ?? 0, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
    {
      row: [
        'TE Fora Ponta (R$/MWh)',
        ...unitReport.mediumVoltageFinalTariffsList.map(
          (item) => formatNumber(item.electricityTariffOffPeak ?? 0, '$ 0,0.00') || '',
        ),
      ],
      tagStyle,
    },
  ];

  return {
    headers,
    rows: dataRows,
  };
};
export const getPremissisesTableData = (unitReport: IUnitReport) => {
  const premissesTableData = [
    {
      label: 'Data de migração',
      value: unitReport.premises.migrationDate,
      tooltipContent: 'propriedade: unitReport.premises.migrationDate',
    },
    {
      label: 'Distribuidora',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.energyDistributionCompany.name,
      tooltipContent:
        'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.energyDistributionCompany.name',
    },

    {
      label: 'Modalidade Tarifária',
      value: formatNumber(Number(unitReport.sampleMonthSimulation.currentMarket.tariff.tariff), '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.tariff',
    },
    {
      label: 'Demanda Ponta ACR',
      value: unitReport.premises.aclContractedDemandPeak,
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandPeak',
    },
    {
      label: 'Demanda Fora Ponta ACR',
      value: unitReport.premises.aclContractedDemandOffPeak,
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandOffPeak',
    },
    {
      label: 'Classificação da empresa',
      value: unitReport.premises.icmsType,
      tooltipContent: 'propriedade: unitReport.premises.icmsType',
    },
    {
      label: 'ICMS',
      value: formatNumber(unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue as number, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue',
    },
    {
      label: 'Tipo de energia',
      value: 'Incentivada 50%',
      tooltipContent: 'Valor padrão',
    },
    {
      label: 'Gestão Clarke/mês',
      value: formatNumber(unitReport.premises.managementPrice, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.managementPrice',
    },
    {
      label: 'Migração',
      value: formatNumber(unitReport.premises.initialExpenses, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.premises.initialExpenses',
    },
    {
      label: 'Contribuição CCEE',
      value: formatNumber(unitReport.sampleMonthSimulation.targetMarket.contributionCcee, '$ 0,0.00'),
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.contributionCcee',
    },
  ];

  return premissesTableData;
};

export const getGroupInfoTableData = (proposalData: ISimulationResultBase) => {
  const groupInfoTableData = [
    {
      label: 'I-REC',
      value: formatNumber(Number(proposalData?.iRecCertificationCost), '$ 0,0.00'),
      tooltipContent: 'propriedade: iRecCertificationCost',
    },
  ];

  return groupInfoTableData;
};

const monthsListDescription = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const getConsumptionMediumVoltageTableData = (unitReport: IUnitReport) => {
  const consumptionPeak = unitReport.premises.consumptionPeak;
  const consumptionOffPeak = unitReport.premises.consumptionOffPeak;

  const parsedConsumptioMediumVoltage = monthsListDescription.map((item, index) => ({
    label: item,
    consumptionPeak: consumptionPeak[index + 1],
    consumptionOffPeak: consumptionOffPeak[index + 1],
  }));
  return parsedConsumptioMediumVoltage;
};

export const getConsumptionLowVoltageTableData = (unitReport: IUnitReport) => {
  const consumptionLowVoltage = unitReport.premises.consumptionLowVoltage;

  const parsedConsumptionLowVoltage = Object.entries(consumptionLowVoltage).map(([key, value]) => ({
    label: monthsListDescription[parseInt(key) - 1],
    value: value,
  }));

  return parsedConsumptionLowVoltage;
};

export const getPriceListTableData = (unitReport: IUnitReport) => {
  const priceList = Object.values(unitReport.priceList)[0];
  const parsedPriceList = Object.entries(priceList).map(([key, value]) => ({
    label: key,
    value: value,
  }));
  return parsedPriceList;
};

const formatValueForUniqueValue = (value: number, type: string) => {
  return type === 'ABSOLUTE' ? `${value ?? '0'} (R$/MWh)` : `${(value * 100)?.toFixed(0) ?? '0'}%`;
};
const formatValueForValueByYear = (valueByYear: Record<string, number>, type: string) => {
  const formattedValueList = [];
  for (const year in valueByYear) {
    if (type === 'PERCENTAGE') {
      formattedValueList.push(`${year}: ${(valueByYear[year] * 100)?.toFixed(0) ?? '0'}%`);
    } else {
      formattedValueList.push(`${year}: ${valueByYear[year] ?? '0'} (R$/MWh)`);
    }
  }
  return formattedValueList.join(' | ');
};

export const getPriceListCompositionTableData = (unitReport: IUnitReport) => {
  const keysIndex = unitReport?.priceListComposition?.map((item) => Object.keys(item.priceList ?? {})?.[0]);
  const keyIndex = keysIndex && Math.max(Number(...keysIndex));
  const allYears = unitReport?.priceListComposition?.flatMap((item) => Object?.keys(item.priceList?.[keyIndex] ?? {}));
  const uniqueYears = Array?.from(new Set(allYears))?.sort();

  const headers = ['Etapa', 'Premissas', ...uniqueYears];
  const dataRows = unitReport?.priceListComposition?.map((item) => {
    const keyIndex = Object.keys(item.priceList ?? {})?.[0];
    const years = uniqueYears?.map((year) => formatNumber(item.priceList?.[keyIndex][year], '$0,0.00'));

    const { value, type, ceil, floor, label, valueByYear, additionalInfo } = item.priceComponent;

    const formattedValue = valueByYear
      ? formatValueForValueByYear(valueByYear, type)
      : formatValueForUniqueValue(value, type);
    const premisesContent = additionalInfo ? additionalInfo : formattedValue;
    const maxTag = ceil !== null ? `(max R$ ${ceil})` : '';
    const minTag = floor !== null ? `(min R$ ${floor})` : '';

    const premisesTag = `${premisesContent} ${maxTag} ${minTag}`.trim();
    const tagStyle =
      !additionalInfo && (value > 0 || !formattedValue.includes('-'))
        ? 'text-primary-60 font-semiligth text-paragrath-medium'
        : value < 0 || formattedValue.includes('-')
        ? 'text-danger-60 font-semiligth text-paragrath-medium'
        : 'text-gray-60 font-semiligth text-paragrath-medium';

    return { row: [label, premisesTag, ...years], tagStyle };
  });

  return {
    headers,
    rows: dataRows,
  };
};
export function parseProposalToUnitsReportsRetail(unitsReports: Array<IProductTwoUnitReport>): IUnitReport[] {
  return unitsReports?.map((unit: Record<string, any>) => {
    const premises: IPremises = {
      nickname: unit?.premises?.nickname,
      icmsType: unit?.premises?.icmsType,
      migrationDate: unit?.premises?.migrationDate,
      managementPrice: unit?.premises?.managementPrice,
      initialExpenses: unit?.premises?.initialExpenses,
      powerGeneratorCost: unit?.premises?.powerGeneratorCost,
      solarPlantInOperation: unit?.premises?.solarPlantInOperation,
      consumptionPeak: unit?.premises?.consumptionPeak,
      consumptionOffPeak: unit?.premises?.consumptionOffPeak,
      consumptionLowVoltage: unit?.premises?.consumptionLowVoltage,
      companyType: unit?.premises?.companyType,
      operationHoursRange: unit?.premises?.operationHoursRange,
      distributedGenerationMonthlyCost: unit?.premises?.distributedGenerationMonthlyCost,
      injectedEnergyOffPeak: unit?.premises?.injectedEnergyOffPeak,
      injectedEnergyPeak: unit?.premises?.injectedEnergyPeak,
      manualAcrContractedDemandPeak: unit?.premises?.manualAcrContractedDemandPeak,
      manualAcrContractedDemandOffPeak: unit?.premises?.manualAcrContractedDemandOffPeak,
      manualAclContractedDemandPeak: unit?.premises?.manualAclContractedDemandPeak,
      manualAclContractedDemandOffPeak: unit?.premises?.manualAclContractedDemandOffPeak,
      manualConsumptionOffPeak: unit?.premises?.manualConsumptionOffPeak,
      manualConsumptionPeak: unit?.premises?.manualConsumptionPeak,
      targetVolumeGenerated: unit?.premises?.targetVolumeGenerated,
      energyDistributionCompany: unit?.premises?.energyDistributionCompany,
      acrContractedDemandPeak: unit?.premises?.acrContractedDemandPeak,
      acrContractedDemandOffPeak: unit?.premises?.acrContractedDemandOffPeak,
      aclContractedDemandPeak: unit?.premises?.aclContractedDemandPeak,
      aclContractedDemandOffPeak: unit?.premises?.aclContractedDemandOffPeak,
      tariff: unit?.premises?.tariff,
    };

    const currentMarket: ICurrentMarket = {
      consumptionPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionPeak,
      consumptionOffPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionOffPeak,
      distributionTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffPeakCost,
      distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffOffPeakCost,
      electricityTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffPeakCost,
      electricityTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffOffPeakCost,
      generatorCost: unit?.sampleMonthSimulation?.currentMarket?.generatorCost,
      demandOffPeak: unit?.sampleMonthSimulation?.currentMarket?.demandOffPeak,
      demandPeak: unit?.sampleMonthSimulation?.currentMarket?.demandPeak,
      consumptionLowVoltage: unit?.sampleMonthSimulation?.currentMarket?.consumptionLowVoltage,
      tariff: unit?.sampleMonthSimulation?.currentMarket?.tariff,
      distributionTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffLowVoltageCost,
      electricityTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffLowVoltageCost,
      monthYear: unit?.sampleMonthSimulation?.currentMarket?.monthYear,
      generalGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.generalGeneratorFactor,
      tariffFlagList: unit?.sampleMonthSimulation?.currentMarket?.tariffFlagList,
      targetMigrationDate: unit?.sampleMonthSimulation?.currentMarket?.targetMigrationDate,
      injectedEnergyOffPeak: unit?.sampleMonthSimulation?.currentMarket?.injectedEnergyOffPeak,
      monthGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.monthGeneratorFactor,
      icms: unit?.sampleMonthSimulation?.currentMarket?.icms,
      rawTotalConsumptionCost: unit?.sampleMonthSimulation?.currentMarket?.rawTotalConsumptionCost,
      totalWithIcms: unit?.sampleMonthSimulation?.currentMarket?.totalWithIcms,
      totalPisCofins: unit?.sampleMonthSimulation?.currentMarket?.totalPisCofins,
      totalWithoutTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithoutTaxes,
      totalWithTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithTaxes,
      tariffFlag: unit?.sampleMonthSimulation?.currentMarket?.tariffFlag,
    };

    const targetMarket: ITargetMarket = {
      risk: unit?.sampleMonthSimulation?.targetMarket?.risk,
      productType: unit?.sampleMonthSimulation?.targetMarket?.productType,
      monthYear: unit?.sampleMonthSimulation?.targetMarket?.monthYear,
      priceListWithInflation: unit?.sampleMonthSimulation?.targetMarket?.priceListWithInflation,
      customTaxes: unit?.sampleMonthSimulation?.targetMarket?.customTaxes,
      icms: unit?.sampleMonthSimulation?.targetMarket?.icms,
      manualAclPrice: unit?.sampleMonthSimulation?.targetMarket?.manualAclPrice,
      manualAclPricesHasChargesCoverage: unit?.sampleMonthSimulation?.targetMarket?.manualAclPricesHasChargesCoverage,
      contributionCcee: unit?.sampleMonthSimulation?.targetMarket?.contributionCcee,
      modulation: unit?.sampleMonthSimulation?.targetMarket?.modulation,
      demandPeak: unit?.sampleMonthSimulation?.targetMarket?.demandPeak,
      demandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.demandOffPeak,
      tariff: unit?.sampleMonthSimulation?.targetMarket?.tariff,
      consumptionPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionPeak,
      consumptionOffPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionOffPeak,
      distributionTariffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffPeakCost,
      distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffOffPeakCost,
      stimulatedDemandPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandPeak,
      stimulatedDemandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandOffPeak,
      stimulatedDistributionTariffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDistributionTariffPeak,
      distributionIcms: unit?.sampleMonthSimulation?.targetMarket?.distributionIcms,
      electricityIcms: unit?.sampleMonthSimulation?.targetMarket?.electricityIcms,
      distributionPisCofins: unit?.sampleMonthSimulation?.targetMarket?.distributionPisCofins,
      electricityWithLosses: unit?.sampleMonthSimulation?.targetMarket?.electricityWithLosses,
      pisCofinsHighlight: unit?.sampleMonthSimulation?.targetMarket?.pisCofinsHighlight,
      electricityTariffCost: unit?.sampleMonthSimulation?.targetMarket?.electricityTariffCost,
      securityAndModulationEnergyCharges: unit?.sampleMonthSimulation?.targetMarket?.securityAndModulationEnergyCharges,
      targetMarketContributionCcee: unit?.sampleMonthSimulation?.targetMarket?.targetMarketContributionCcee,
      clarkeManagement: unit?.sampleMonthSimulation?.targetMarket?.clarkeManagement,
      totalWithoutTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithoutTaxes,
      monthGeneratorFactor: unit?.sampleMonthSimulation?.targetMarket?.monthGeneratorFactor,
      totalWithTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithTaxes,
      managementPrice: unit?.sampleMonthSimulation?.targetMarket?.managementPrice,
    };

    const sampleMonthSimulation: ISampleMonth = {
      currentMarket,
      targetMarket,
      report: unit?.sampleMonthSimulation.report,
    };
    const unitReport: IUnitReport = {
      priceListWithInflation: unit?.priceListWithInflation,
      priceListComposition: unit?.priceListComposition,
      premises,
      totalSavingWithTaxes: unit?.totalSavingWithTaxes,
      totalSavingInPercentageWithTaxes: unit?.totalSavingInPercentageWithTaxes,
      averageConsumption: unit?.averageConsumption,
      carbonDioxideEmissionInTonsAvoidedByYear: unit?.carbonDioxideEmissionInTonsAvoidedByYear,
      currentSpentByYear: unit?.currentSpentByYear,
      averageBillAmount: unit?.averageBillAmount,
      energyFeeMonthlyByYear: unit?.energyFeeMonthlyByYear,
      grossMerchandiseVolumeByYear: unit?.grossMerchandiseVolumeByYear,
      initialExpenses: unit?.initialExpenses,
      lowVoltageFinalTariffsList: unit?.lowVoltageFinalTariffsList,
      managementPriceByYear: unit?.managementPriceByYear,
      manualAclPrice: unit?.manualAclPrice,
      mediumVoltageFinalTariffsList: unit?.mediumVoltageFinalTariffsList,
      nickname: unit?.nickname,
      priceList: unit?.priceList,
      rawPriceList: unit?.rawPriceList,
      usedPriceProjection: unit?.usedPriceProjection,
      sampleMonthSimulation: sampleMonthSimulation,
      savingByYearInPercentage: unit?.savingByYearInPercentage,
      savingByYear: unit?.savingByYear,
      savingByMonth: unit?.savingByMonth,
      takeRateByYear: unit?.takeRateByYear,
      targetSpentByYear: unit?.targetSpentByYear,
      totalCarbonDioxideEmissionInTonsAvoided: unit?.totalCarbonDioxideEmissionInTonsAvoided,
      totalGrossMerchandiseVolume: unit?.totalGrossMerchandiseVolume,
      totalManagementPrice: unit?.totalManagementPrice,
      totalMonths: unit?.totalMonths,
      totalSavingInPercentage: unit?.totalSavingInPercentage,
      totalSaving: unit?.totalSaving,
      totalTakeRate: unit?.totalTakeRate,
      totalConsumptionSummationInMwh: unit?.totalConsumptionSummationInMwh,
      totalLifetimeValue: unit?.totalLifetimeValue,
    };
    return unitReport;
  });
}

export function parseProposalToUnitsReportsManagement(unitsReports: Array<IProductOneUnitReport>): IUnitReport[] {
  return unitsReports?.map((unit: Record<string, any>) => {
    const premises: IPremises = {
      nickname: unit?.premises?.nickname,
      icmsType: unit?.premises?.icmsType,
      migrationDate: unit?.premises?.migrationDate,
      managementPrice: unit?.premises?.managementPrice,
      initialExpenses: unit?.premises?.initialExpenses,
      powerGeneratorCost: unit?.premises?.powerGeneratorCost,
      solarPlantInOperation: unit?.premises?.solarPlantInOperation,
      consumptionPeak: unit?.premises?.consumptionPeak,
      consumptionOffPeak: unit?.premises?.consumptionOffPeak,
      consumptionLowVoltage: unit?.premises?.consumptionLowVoltage,
      companyType: unit?.premises?.companyType,
      operationHoursRange: unit?.premises?.operationHoursRange,
      distributedGenerationMonthlyCost: unit?.premises?.distributedGenerationMonthlyCost,
      injectedEnergyOffPeak: unit?.premises?.injectedEnergyOffPeak,
      injectedEnergyPeak: unit?.premises?.injectedEnergyPeak,
      manualAcrContractedDemandPeak: unit?.premises?.manualAcrContractedDemandPeak,
      manualAcrContractedDemandOffPeak: unit?.premises?.manualAcrContractedDemandOffPeak,
      manualAclContractedDemandPeak: unit?.premises?.manualAclContractedDemandPeak,
      manualAclContractedDemandOffPeak: unit?.premises?.manualAclContractedDemandOffPeak,
      manualConsumptionOffPeak: unit?.premises?.manualConsumptionOffPeak,
      manualConsumptionPeak: unit?.premises?.manualConsumptionPeak,
      targetVolumeGenerated: unit?.premises?.targetVolumeGenerated,
      energyDistributionCompany: unit?.premises?.energyDistributionCompany,
      acrContractedDemandPeak: unit?.premises?.acrContractedDemandPeak,
      acrContractedDemandOffPeak: unit?.premises?.acrContractedDemandOffPeak,
      aclContractedDemandPeak: unit?.premises?.aclContractedDemandPeak,
      aclContractedDemandOffPeak: unit?.premises?.aclContractedDemandOffPeak,
      tariff: unit?.premises?.tariff,
    };

    const currentMarket: ICurrentMarket = {
      consumptionPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionPeak,
      consumptionOffPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionOffPeak,
      distributionTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffPeakCost,
      distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffOffPeakCost,
      electricityTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffPeakCost,
      electricityTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffOffPeakCost,
      generatorCost: unit?.sampleMonthSimulation?.currentMarket?.generatorCost,
      demandOffPeak: unit?.sampleMonthSimulation?.currentMarket?.demandOffPeak,
      demandPeak: unit?.sampleMonthSimulation?.currentMarket?.demandPeak,
      consumptionLowVoltage: unit?.sampleMonthSimulation?.currentMarket?.consumptionLowVoltage,
      tariff: unit?.sampleMonthSimulation?.currentMarket?.tariff,
      distributionTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffLowVoltageCost,
      electricityTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffLowVoltageCost,
      monthYear: unit?.sampleMonthSimulation?.currentMarket?.monthYear,
      generalGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.generalGeneratorFactor,
      tariffFlagList: unit?.sampleMonthSimulation?.currentMarket?.tariffFlagList,
      targetMigrationDate: unit?.sampleMonthSimulation?.currentMarket?.targetMigrationDate,
      injectedEnergyOffPeak: unit?.sampleMonthSimulation?.currentMarket?.injectedEnergyOffPeak,
      monthGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.monthGeneratorFactor,
      icms: unit?.sampleMonthSimulation?.currentMarket?.icms,
      rawTotalConsumptionCost: unit?.sampleMonthSimulation?.currentMarket?.rawTotalConsumptionCost,
      totalWithIcms: unit?.sampleMonthSimulation?.currentMarket?.totalWithIcms,
      totalPisCofins: unit?.sampleMonthSimulation?.currentMarket?.totalPisCofins,
      totalWithoutTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithoutTaxes,
      totalWithTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithTaxes,
      tariffFlag: unit?.sampleMonthSimulation?.currentMarket?.tariffFlag,
    };

    const targetMarket: ITargetMarket = {
      risk: unit?.sampleMonthSimulation?.targetMarket?.risk,
      productType: unit?.sampleMonthSimulation?.targetMarket?.productType,
      monthYear: unit?.sampleMonthSimulation?.targetMarket?.monthYear,
      priceListWithInflation: unit?.sampleMonthSimulation?.targetMarket?.priceListWithInflation,
      customTaxes: unit?.sampleMonthSimulation?.targetMarket?.customTaxes,
      icms: unit?.sampleMonthSimulation?.targetMarket?.icms,
      manualAclPrice: unit?.sampleMonthSimulation?.targetMarket?.manualAclPrice,
      manualAclPricesHasChargesCoverage: unit?.sampleMonthSimulation?.targetMarket?.manualAclPricesHasChargesCoverage,
      contributionCcee: unit?.sampleMonthSimulation?.targetMarket?.contributionCcee,
      modulation: unit?.sampleMonthSimulation?.targetMarket?.modulation,
      demandPeak: unit?.sampleMonthSimulation?.targetMarket?.demandPeak,
      demandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.demandOffPeak,
      tariff: unit?.sampleMonthSimulation?.targetMarket?.tariff,
      consumptionPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionPeak,
      consumptionOffPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionOffPeak,
      distributionTariffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffPeakCost,
      distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffOffPeakCost,
      stimulatedDemandPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandPeak,
      stimulatedDemandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandOffPeak,
      stimulatedDistributionTariffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDistributionTariffPeak,
      distributionIcms: unit?.sampleMonthSimulation?.targetMarket?.distributionIcms,
      electricityIcms: unit?.sampleMonthSimulation?.targetMarket?.electricityIcms,
      distributionPisCofins: unit?.sampleMonthSimulation?.targetMarket?.distributionPisCofins,
      electricityWithLosses: unit?.sampleMonthSimulation?.targetMarket?.electricityWithLosses,
      pisCofinsHighlight: unit?.sampleMonthSimulation?.targetMarket?.pisCofinsHighlight,
      electricityTariffCost: unit?.sampleMonthSimulation?.targetMarket?.electricityTariffCost,
      securityAndModulationEnergyCharges: unit?.sampleMonthSimulation?.targetMarket?.securityAndModulationEnergyCharges,
      targetMarketContributionCcee: unit?.sampleMonthSimulation?.targetMarket?.targetMarketContributionCcee,
      clarkeManagement: unit?.sampleMonthSimulation?.targetMarket?.clarkeManagement,
      totalWithoutTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithoutTaxes,
      monthGeneratorFactor: unit?.sampleMonthSimulation?.targetMarket?.monthGeneratorFactor,
      totalWithTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithTaxes,
      managementPrice: unit?.sampleMonthSimulation?.targetMarket?.managementPrice,
    };

    const sampleMonth: ISampleMonth = {
      currentMarket,
      targetMarket,
      report: unit?.sampleMonthSimulation.report,
    };

    const unitReport: IUnitReport = {
      priceListComposition: unit?.priceListComposition,
      premises,
      totalSavingWithTaxes: unit?.totalSavingWithTaxes,
      totalSavingInPercentageWithTaxes: unit?.totalSavingInPercentageWithTaxes,
      averageConsumption: unit?.averageConsumption,
      carbonDioxideEmissionInTonsAvoidedByYear: unit?.carbonDioxideEmissionInTonsAvoidedByYear,
      currentSpentByYear: unit?.currentSpentByYear,
      averageBillAmount: unit?.averageBillAmount,
      energyFeeMonthlyByYear: unit?.energyFeeMonthlyByYear,
      grossMerchandiseVolumeByYear: unit?.grossMerchandiseVolumeByYear,
      initialExpenses: unit?.initialExpenses,
      lowVoltageFinalTariffsList: unit?.lowVoltageFinalTariffsList,
      mediumVoltageFinalTariffsList: unit?.mediumVoltageFinalTariffsList,
      managementPriceByYear: unit?.managementPriceByYear,
      manualAclPrice: unit?.manualAclPrice,
      nickname: unit?.nickname,
      priceList: unit?.priceList,
      priceListWithInflation: unit?.priceListWithInflation,
      rawPriceList: unit?.rawPriceList,
      usedPriceProjection: unit?.usedPriceProjection,
      sampleMonthSimulation: sampleMonth,
      savingByYearInPercentage: unit?.savingByYearInPercentage,
      savingByYear: unit?.savingByYear,
      savingByMonth: unit?.savingByMonth,
      takeRateByYear: unit?.takeRateByYear,
      targetSpentByYear: unit?.targetSpentByYear,
      totalCarbonDioxideEmissionInTonsAvoided: unit?.totalCarbonDioxideEmissionInTonsAvoided,
      totalGrossMerchandiseVolume: unit?.totalGrossMerchandiseVolume,
      totalManagementPrice: unit?.totalManagementPrice,
      totalMonths: unit?.totalMonths,
      totalSavingInPercentage: unit?.totalSavingInPercentage,
      totalSaving: unit?.totalSaving,
      totalTakeRate: unit?.totalTakeRate,
      totalConsumptionSummationInMwh: unit?.totalConsumptionSummationInMwh,
      totalLifetimeValue: unit?.totalLifetimeValue,
    };
    return unitReport;
  });
}
