import { HeroIcon } from '@clarke-energia/foton';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import { cardsDetailData } from './static-data';
import './styles.css';

interface IClarkeBenefits {
  header: { title: string; image: string };
  body: {
    largestTechCompany: { title: string; icon: string };
    independentEnergyManager: { title: string; icon: string };
    simpleClientArea: { title: string; icon: string };
    lowestManagementCost: { title: string; icon: string };
    noTerminationFee: { title: string; icon: string };
  };
}

interface ICommercialConditions {
  header: { title: string; image: string };
  body: {
    energisaSupplier: { title: string; icon: string };
    retailMode: { title: string; icon: string };
    flexibility: { title: string; icon: string };
    fullChargesCoverage: { title: string; icon: string };
    noGuarantee: { title: string; icon: string };
  };
}

function Card({ header, body }: IClarkeBenefits | ICommercialConditions) {
  return (
    <div className="proposal-summary-card">
      <div className="proposal-summary-card-header">
        <div className="proposal-summary-card-icon-container">
          <img className="proposal-summary-card-icon" src={header.image} alt={header.title} />
        </div>
        <h1 className="proposal-summary-card-title">{header.title}</h1>
      </div>
      <div>
        {Object.values(body).map((item: any) => (
          <div key={item.title} className="proposal-summary-card-item">
            <div className="proposal-summary-card-item-icon">
              <HeroIcon className="proposal-summary-card-item-hero-icon" icon={item.icon} />
            </div>
            <h1 className="proposal-summary-card-item-title" dangerouslySetInnerHTML={{ __html: item.title }} />
          </div>
        ))}
      </div>
    </div>
  );
}

function ProposalSummary() {
  return (
    <SlideContainer className="min-h-fit xl:h-screen">
      <section className="min-h-full p-5 xl:px-20 xl:py-10 2xl:px-20">
        <LegendContainer legend="Resumo da Proposta" />
        <div className="proposal-summary-content">
          <h1 className="proposal-summary-heading">Condições Comerciais</h1>
          <div className="proposal-summary-cards">
            <Card {...cardsDetailData.commercialConditions} />
            <Card {...cardsDetailData.clarkeBenefits} />
          </div>
        </div>
      </section>
    </SlideContainer>
  );
}

export { ProposalSummary };