import substationBackground from '@assets/images/substation-bg.png';
import ConclusiveSectionLayout from '@components/molecules/product-two/simulation/conclusive-section-layout';

const gadgetNumberedContent = [
  {
    titleText: 'Primeiros passos',
    text: 'Análise de viabilidade econômica.',
  },
  {
    titleText: 'Compra de energia',
    text: 'Cotação com diversos fornecedores de energia renovável.',
  },
  {
    titleText: 'Migração',
    text: 'Adequação do Sistema de Medição para Faturamento (SMF).',
  },
  {
    titleText: 'Migração',
    text: 'Adesão à Câmara de Comercialização de Energia Elétrica (CCEE).',
  },
  {
    titleText: 'Gestão do cliente',
    text: 'Gestão com acompanhamento e relatórios mensais.',
  },
];

const ProcessFlow: React.FC = () => {
  return (
    <ConclusiveSectionLayout
      mainTitle="Você não se preocupa, o processo da migração fica inteiro com a gente!"
      backgroundImg={substationBackground}
      gadgetNumberedContent={gadgetNumberedContent}
    />
  );
};

export default ProcessFlow;
