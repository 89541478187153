import { IProductTwoSimulationExtendedResult } from '@contexts/product-two/types';

import GreenSectionSlide from '@components/molecules/general/simulation/green-section-slide';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import ProductEconomySlide from '@components/molecules/general/simulation/economy-slide';

interface IProductTwoSimulationUnitsEconomySection {
  simulationScenarios?: IProductTwoSimulationExtendedResult;
  proposalValidity?: string;
}

const ProductTwoSimulationUnitsEconomySection = ({ simulationScenarios,proposalValidity }: IProductTwoSimulationUnitsEconomySection) => (
  <>
    {simulationScenarios?.units && simulationScenarios.units.length > 1 && (
      <>
        <SlideContainer>
          <GreenSectionSlide title="Detalhamento por Unidade" />
        </SlideContainer>
        {simulationScenarios.units.map((unitSimulationResult) => (
          <SlideContainer key={`product-two-simulation-unit-detailed-${unitSimulationResult.nickname}`}>
            <ProductEconomySlide
              showSideData
              simulationResult={unitSimulationResult}
              proposalValidity={proposalValidity}
              hasTraderDescription={true}
            />
          </SlideContainer>
        ))}
      </>
    )}
  </>
);

export default ProductTwoSimulationUnitsEconomySection;
