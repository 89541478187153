import { useState } from 'react';
import { TARIFF, TARIFF_LABELS, ENERGY_DISTRIBUTION_COMPANIES } from '@utils/constants';
import {
  getConsumptionLowVoltageTableData,
  getConsumptionMediumVoltageTableData,
  getCurrentMarketUnitTableData,
  getEconomyUnitTableData,
  getLowVoltageFinalTariffTableData,
  getMediumVoltageFinalTariffTableData,
  getPremissisesTableData,
  getPriceListCompositionTableData,
  getPriceListTableData,
  getTargetMarketUnitTableData,
} from '@pages/detailed-analysis/helper';
import { IUnitReport } from '@pages/detailed-analysis/types';
import { FormControl, InputLabel, MenuItem, Select, Box, Typography, Paper, SelectChangeEvent } from '@mui/material';
import { HeroIconSolidOrOutline } from '@components/atoms/form/hero-icon';
import { TableWithMultiplesValues } from '@components/atoms/detailedAnalysisGC/table-with-multiples-values';
import { DataTable } from '@components/atoms/detailedAnalysisGC/dataTable';
import { DropdownSection } from './dropdonw';

export function GenerateTablesByUnit({ units }: { units: Array<IUnitReport> }) {
  const [selectedUnit, setSelectedUnit] = useState<IUnitReport | null>(null);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    setSelectedUnit(selectedValue === 'all' ? null : units.find((unit) => unit?.nickname === selectedValue) || null);
  };

  const filteredUnits = selectedUnit ? [selectedUnit] : units;
  return (
    <>
      <Box mb={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="unit-select-label">Selecione uma unidade</InputLabel>
          <Select
            labelId="unit-select-label"
            id="unit-select"
            value={selectedUnit?.nickname || 'all'}
            onChange={(e) => handleSelectChange(e)}
            label="Selecione uma unidade"
            renderValue={(value) => (value === 'all' ? 'Todas as Unidades' : selectedUnit?.nickname)}
          >
            <MenuItem value="all">Todas as Unidades</MenuItem>
            {units?.map((unit) => (
              <MenuItem className="flex gap-x-5" key={unit?.nickname} value={unit?.nickname}>
                {unit?.nickname}
                {selectedUnit?.nickname === unit?.nickname && (
                  <HeroIconSolidOrOutline icon="CheckIcon" className="text-primary-70 size-5 ml-2" />
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {filteredUnits?.map((unit, index) => {
        const priceListCompositionTableData = getPriceListCompositionTableData(unit);
        const currentMarketUnitTableData = getCurrentMarketUnitTableData(unit);
        const targetMarketUnitTableData = getTargetMarketUnitTableData(unit);
        const economyUnitTableData = getEconomyUnitTableData(unit);
        const premissisesTableData = getPremissisesTableData(unit);
        const lowVoltageFinalTariffTableData = getLowVoltageFinalTariffTableData(unit);
        const mediumVoltageFinalTariffTableData = getMediumVoltageFinalTariffTableData(unit);
        const consumptionMediumVoltageTableData = getConsumptionMediumVoltageTableData(unit);
        const consumptionLowVoltageTableData =
          unit.premises.tariff == TARIFF.B3_OPTING || unit.premises.tariff == TARIFF.B3_STANDARD
            ? getConsumptionLowVoltageTableData(unit)
            : null;
        const priceListTableData = getPriceListTableData(unit);

        return (
          <DropdownSection
            key={index}
            title={unit?.premises?.nickname}
            iconName="OfficeBuildingIcon"
            iconColor="text-primary-70"
          >
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
              <Typography variant="h3" gutterBottom={true}>
                Análise detalhada da Unidade: {unit?.nickname}
              </Typography>

              <DropdownSection
                fixedOpen={true}
                title="Composição da Lista de Preços"
                iconName="CalculatorIcon"
                iconColor="text-primary-70"
                className="mb-20"
              >
                <TableWithMultiplesValues {...priceListCompositionTableData} />
              </DropdownSection>

              <DropdownSection iconColor="text-primary-70" title="Premissas de Cálculo">
                {unit.lowVoltageFinalTariffsList && (
                  <DropdownSection
                    fixedOpen={true}
                    title={`Tarifas Baixa Tensão ${
                      TARIFF_LABELS[unit.lowVoltageFinalTariffsList[0]?.tariff as unknown as keyof typeof TARIFF_LABELS]
                    } para distribuidora ${
                      ENERGY_DISTRIBUTION_COMPANIES[unit.lowVoltageFinalTariffsList[0]?.energyDistributionCompany.id]
                    }`}
                    iconName="CurrencyDollarIcon"
                    className="mb-20"
                  >
                    <TableWithMultiplesValues {...lowVoltageFinalTariffTableData} />
                  </DropdownSection>
                )}
                {unit.mediumVoltageFinalTariffsList && (
                  <DropdownSection
                    fixedOpen={true}
                    title={`Tarifas Média Tensão ${
                      TARIFF_LABELS[
                        unit.mediumVoltageFinalTariffsList[0]?.tariff as unknown as keyof typeof TARIFF_LABELS
                      ]
                    } para distribuidora ${
                      ENERGY_DISTRIBUTION_COMPANIES[unit.mediumVoltageFinalTariffsList[0]?.energyDistributionCompany.id]
                    }`}
                    iconName="CurrencyDollarIcon"
                    className="mb-20"
                  >
                    <TableWithMultiplesValues {...mediumVoltageFinalTariffTableData} />
                  </DropdownSection>
                )}
                <DropdownSection title="Premissas" iconName="ExclamationCircleIcon">
                  <DataTable data={premissisesTableData} />
                </DropdownSection>

                <DropdownSection title="Consumo Média Tensão" iconName="LightningBoltIcon">
                  <DataTable data={consumptionMediumVoltageTableData} />
                </DropdownSection>

                {consumptionLowVoltageTableData && (
                  <DropdownSection title="Consumo Baixa Tensão" iconName="LightningBoltIcon">
                    <DataTable data={consumptionLowVoltageTableData} />
                  </DropdownSection>
                )}

                <DropdownSection title="Lista de Preços" iconName="CurrencyDollarIcon">
                  <DataTable data={priceListTableData} formatString="$ 0,0.00" />
                </DropdownSection>
              </DropdownSection>

              <DropdownSection
                iconColor="text-primary-70"
                title={`Resultado de Simulação - ${unit?.sampleMonthSimulation?.report?.monthLabel}/${unit?.sampleMonthSimulation?.report?.year}`}
              >
                <DropdownSection title="Dados do Mercado Cativo" iconName="PresentationChartLineIcon">
                  <DataTable data={currentMarketUnitTableData} />
                </DropdownSection>

                <DropdownSection title="Dados do Mercado Livre" iconName="PresentationChartBarIcon">
                  <DataTable data={targetMarketUnitTableData} />
                </DropdownSection>

                <DropdownSection title="Dados de Economia" iconName="CalculatorIcon">
                  <DataTable data={economyUnitTableData} />
                </DropdownSection>
              </DropdownSection>
            </Paper>
          </DropdownSection>
        );
      })}
    </>
  );
}

export default GenerateTablesByUnit;
