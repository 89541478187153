import { twMerge } from 'tailwind-merge';
import { formatNumber } from '@utils/numeral';

import { IAclPrices } from '@contexts/product-one/types';

interface IScenarioCardProps {
  index: number;
  title: string;
  totalSavingPercentage: number;
  totalSaving: number;
  color: string;

  aclPrices: IAclPrices;

  totalSavingByMonth: number;
}

function ScenarioCard({
  index,
  title,
  totalSavingPercentage,
  totalSaving,
  aclPrices,
  color,
  totalSavingByMonth,
}: IScenarioCardProps) {
  return (
    <div className={twMerge('w-full flex flex-col items-start justify-start sm:px-3', `text-${color}`)}>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      <div className="w-full flex items-center justify-start gap-2 sm:gap-6">
        <strong className="text-display-large sm:text-display-xlarge leading-[6rem] sm:leading-[6.75rem] font-extrabold mt-2">
          {formatNumber(totalSavingPercentage, '0%')}
        </strong>
        <div className="flex flex-col text-heading-medium">
          <b>{formatNumber(totalSaving, '$ 0,0.00')}</b>
          <p className="text-paragraph-small sm:text-paragraph-medium text-[#2E2E2E] text-nowrap">
            Economia mensal líquida: {formatNumber(totalSavingByMonth, '$ 0,0.00')}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-4">
        <h3 className="text-paragraph-medium text-[#818181]">Preços de fornecimento (R$/MWh)</h3>
        <div className="flex flex-row gap-x-16 mt-2">
          {Object.entries(aclPrices).map(([year, value]) => (
            <div key={year}>
              <p className="text-paragraph-small font-light text-neutral-70">{year}</p>
              <p className="text-paragraph-medium font-regular text-nowrap text-[#2E2E2E]">
                {formatNumber(value, '$ 0,0.00')}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ScenarioCard;
