import numeral from 'numeral';
import { IAnnualBreakdownColumn } from '@components/molecules/simplified-proposal/simplified-proposal-annual-breakdown/helper';
import { ISimplifiedProposalSectionTwo } from './simplified-proposal-section-two';
import { IProductOneProposal } from '@contexts/product-one/types';

const subtitleData = [
  {
    color: 'neutral-30',
    description: 'Mercado Cativo',
  },
  {
    color: 'primary-60',
    description: 'Mercado Livre',
  },
  {
    color: 'black',
    description: '% de economia',
  },
];

export const getDataFromChart = (
  simulationExtendedResult: ISimplifiedProposalSectionTwo['simulationExtendedResult'],
) => {
  return simulationExtendedResult
    ? {
        labels: simulationExtendedResult.simulationResultYearsList,
        currentValues: simulationExtendedResult.currentSpentByYearList,
        targetValues: simulationExtendedResult.targetSpentByYearList,
        savingValues: simulationExtendedResult.savingByYearPercentageList,
        subtitleData,
      }
    : {
        labels: [],
        currentValues: [],
        targetValues: [],
        savingValues: [],
        subtitleData: [],
      };
};

export const getAnnualBreakdownDataList = (
  simulationExtendedResult: ISimplifiedProposalSectionTwo['simulationExtendedResult'],
  targetYear?: number,
) => {
  if (simulationExtendedResult && targetYear) {
    const annualBreakdownDataList: Array<IAnnualBreakdownColumn> =
      simulationExtendedResult.simulationResultYearsList.map((year) => ({
        year: year,
        priceList: numeral(simulationExtendedResult.unitsReports[0].priceList?.[targetYear][year])
          .format('$ 0,0.00')
          .concat('/MWh'),
        totalCurrentSpentByYear: numeral(simulationExtendedResult.totalCurrentSpentByYear[year]).format('$ 0,0.00'),
        totalTargetSpentByYear: numeral(simulationExtendedResult.totalTargetSpentByYear[year]).format('$ 0,0.00'),
        totalSavingByYear: numeral(simulationExtendedResult.totalSavingByYear[year]).format('$ 0,0.00'),
      }));

    return annualBreakdownDataList;
  }
};

export const getAnnualBreakdownDataListProductOne = (
  simulationExtendedResult: IProductOneProposal,
  targetYear?: number,
) => {
  if (simulationExtendedResult && targetYear) {
    const annualBreakdownDataList = Object.keys(
      simulationExtendedResult?.calculatorMetadata?.firstResult?.totalTargetSpentByYear,
    )?.map((year, _) => ({
      year: year,
      priceList: numeral(
        simulationExtendedResult?.calculatorMetadata?.firstResult?.unitsReports[0]?.priceList![targetYear][year],
      )
        .format('$ 0,0.00')
        .concat('/MWh'),
      totalCurrentSpentByYear: numeral(
        simulationExtendedResult?.calculatorMetadata?.firstResult?.totalCurrentSpentByYear[year],
      ).format('$ 0,0.00'),
      totalTargetSpentByYear: numeral(
        simulationExtendedResult?.calculatorMetadata?.firstResult?.totalTargetSpentByYear[year],
      ).format('$ 0,0.00'),
      totalSavingByYear: numeral(
        simulationExtendedResult?.calculatorMetadata?.firstResult?.totalSavingByYear[year],
      ).format('$ 0,0.00'),
    }));

    return annualBreakdownDataList;
  }
};
