import { ISimulationUnitPayloadBase } from '@contexts/types';

export const getMigrationDateList = (units: ISimulationUnitPayloadBase[]) => {
  return units.map(function (unit) {
    return Number(unit.migrationDate?.slice(-4));
  });
};

export const fullfillMissingYears = (yearsRange: number[]) => {
  const parsedYearRange = [];
  yearsRange.sort();

  for (let i = yearsRange[0]; i <= yearsRange[yearsRange.length - 1]; i++) {
    parsedYearRange.push(i);
  }
  return parsedYearRange;
};

const filterDuplicates = (migrationDateList: number[]) => {
  return migrationDateList.filter((item, index) => {
    return migrationDateList.indexOf(item) === index;
  });
};

const addTargetYearsToMigrationDateList = (fullfilldedMigrationDateList: number[], targetYear: number) => {
  const parsedYearsRange = [...fullfilldedMigrationDateList];
  for (let i = 1; i < targetYear; i++) {
    parsedYearsRange.push(fullfilldedMigrationDateList[fullfilldedMigrationDateList?.length - 1] + i);
  }
  return parsedYearsRange;
};

export const getYearsRange = (migrationDateList: number[], targetYear: number) => {
  const filteredMigrationDateList = filterDuplicates(migrationDateList);
  const fullfilldedMigrationDateList = fullfillMissingYears(filteredMigrationDateList);

  const parsedYearsRange = addTargetYearsToMigrationDateList(fullfilldedMigrationDateList, targetYear);
  return parsedYearsRange;
};
