const staticDataStepsTimeline = [
  {
    description: 'Apresentação da análise de viabilidade do projeto',
    stepDays: 0,
  },
  {
    description: 'Assinatura do contrato de gestão de mercado livre de energia',
    stepDays: 2,
  },
  {
    description: 'Reunião de definição de produto ideal - kickoff do projeto',
    stepDays: 3,
  },
  {
    description: 'Abertura da cotação de energia na plataforma da Clarke',
    stepDays: 4,
  },
  {
    description: 'Início da migração para o mercado livre',
    stepDays: 6,
  },
  {
    description: 'Primeira economia no mercado livre de energia*',
    legend: '* prazo dependerá da validade do contrato (CUSD/CCER) do cliente com sua distribuidora de energia',
    stepDays: 180,
  },
];

export default staticDataStepsTimeline;