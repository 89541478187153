import WhatsappIcon from '@assets/images/whatsaap-icon.png';

export const WhatsappButton = () => {
  const phoneNumber = '5551985389919';
  const conversationStartMessage = 'Ol%C3%A1,%20sou%20um%20parceiro%20da%20Clarke%20e%20gostaria%20de%20ajuda.';
  return (
    <button
      onClick={() => {
        window.open(`https://wa.me/${phoneNumber}/?text=${conversationStartMessage}`);
      }}
      className="absolute rounded-full h-9 w-9 bg-[#60D669] bottom-7 right-6 z-10 flex justify-center items-center"
    >
      <img className="h-6 w-6" src={WhatsappIcon}></img>
    </button>
  );
};
