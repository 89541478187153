import clarkeIcon from '@assets/newProposal/Clarke Icon.png';
import briefIcon from '@assets/newProposal/Briefcase.png';

export const cardsDetailData = {
  commercialConditions: {
    header: {
      title: 'Condições comerciais',
      image: briefIcon,
    },
    body: {
      energisaSupplier: {
        title:
          '<b>Energisa como fornecedor de energia</b> - empresa centenária com +100 milhões de patrimônio líquido.',
        icon: 'BuildingOfficeIcon',
      },
      retailMode: { title: 'Modalidade <b>varejista</b>', icon: 'LightBulbIcon' },
      flexibility: { title: '<b>Flexibilidade +/- 100%</b>', icon: 'PresentationChartBarIcon' },
      fullChargesCoverage: {
        title: '<b>Cobertura total de encargos setoriais (EER, ESS e todos os demais)</b> pelo contrato de energia',
        icon: 'DocumentIcon',
      },
      noGuarantee: { title: '<b>Sem garantia</b>', icon: 'CheckBadgeIcon' },
    },
  },
  clarkeBenefits: {
    header: {
      title: 'Benefícios da Clarke',
      image: clarkeIcon,
    },
    body: {
      largestTechCompany: {
        title: '<b>Maior empresa de tecnologia</b> do mercado livre de energia',
        icon: 'BuildingOffice2Icon',
      },
      independentEnergyManager: { title: '<b>Gestora Independente</b> de Energia', icon: 'BoltIcon' },
      simpleClientArea: { title: '<b>Área do cliente</b> simples e intuitiva', icon: 'ComputerDesktopIcon' },
      lowestManagementCost: { title: '<b>Menor custo</b> de gestão do Brasil', icon: 'CurrencyDollarIcon' },
      noTerminationFee: { title: '<b>Sem rescisão</b> no contrato de gestão', icon: 'DocumentDuplicateIcon' },
    },
  },
};
