import { ButtonIcon } from '@clarke-energia/foton';

import UserIconImage from '@assets/images/user-icon.png';

import { IProposalUser } from '@contexts/types';
import { useAuth } from '@src/auth-wrapper';

import { splitUserName } from './helper';

const UserIcon = ({ user }: { user?: IProposalUser }) => (
  <img src={user ? user?.pictureUrl : UserIconImage} className="h-8 rounded-full object-cover" />
);

const UserName = ({ user }: { user?: IProposalUser }) => {
  if (!user) return <></>;

  const { firstName, secondName } = splitUserName(user?.name);
  return (
    <div className="flex flex-col text-caption-medium font-normal text-center">
      <p>{firstName}</p>
      {secondName && <p>{secondName}</p>}
    </div>
  );
};

export const AuthorIcon = ({ author }: IProposalUsersSection) => (
  <div className="flex flex-col gap-2 items-center">
    <UserIcon user={author} />
    <UserName user={author} />
  </div>
);

export const SellerIcon = ({ seller, setIsModalOpen }: IProposalUsersSection) => {
  const { user } = useAuth();
  const isAdmin = user?.isAdmin;
  const isWhiteLabel = user?.isWhiteLabel;

  return (
    <div className="flex gap-2 items-start">
      <div className="flex flex-col items-center relative">
        <div className="relative">
          <UserIcon user={seller} />
          {isAdmin && !isWhiteLabel && (
            <div className="bg-white z-20 absolute bottom-1 -right-2 rounded-full p-[1px]">
              <ButtonIcon
                className="p-[0.1875rem] w-5 h-5"
                icon="PencilIcon"
                kind="secondary"
                onClick={() => setIsModalOpen && setIsModalOpen(true)}
              />
            </div>
          )}
        </div>
        <UserName user={seller} />
      </div>
    </div>
  );
};

interface IProposalUsersSection {
  author?: IProposalUser;
  seller?: IProposalUser;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ProposalUsersSection = ({ author, seller, setIsModalOpen }: IProposalUsersSection) => (
  <div>
    <h2 className="text-paragraph-medium font-bold mt-7">Criador(a) e Vendedor(a):</h2>
    <div className="flex flex-row justify-start gap-10 mt-4 pl-5">
      <AuthorIcon author={author} />
      <SellerIcon seller={seller} setIsModalOpen={setIsModalOpen} />
    </div>
  </div>
);
