import { Tooltip } from '@clarke-energia/foton';
import { IObjectKeys } from '@pages/detailed-analysis/types';
import { formatNumber } from '@utils/numeral';
import { DescriptionColunm, parseValue } from './helper';
import { TableHeader } from './tableHeader';

interface DataTableProps {
  data: IObjectKeys[];
  formatString?: string;
}

export function DataTable({ data, formatString }: DataTableProps) {
  const headers = Object.keys(data[0])
    .filter((key) => key !== 'tooltipContent')
    .map((key) => DescriptionColunm[key as keyof typeof DescriptionColunm]);

  return (
    <table className="min-w-full border border-gray-200">
      <TableHeader headers={headers} />
      <tbody>
        {data.map((item, i) => (
          <tr key={i} className="hover:bg-gray-50">
            <td className="py-2 px-4 border border-gray-300 text-left">
              <span className="flex items-center gap-10 justify-between pr-6">
                {item.label}
                {item.tooltipContent && <Tooltip content={item.tooltipContent} />}
              </span>
            </td>
            {!(item.consumptionOffPeak && item.consumptionPeak) && (
              <td className="py-2 px-4 border border-gray-300 text-left">
                {formatString && item.value ? formatNumber(Number(item.value), formatString) : parseValue(item.value)}
              </td>
            )}
            {item.consumptionOffPeak && (
              <td className="py-2 px-4 border border-gray-300 text-left">{item.consumptionOffPeak}</td>
            )}
            {item.consumptionPeak && (
              <td className="py-2 px-4 border border-gray-300 text-left">{item.consumptionPeak}</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
