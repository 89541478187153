import { FeedbackNotificationProps } from '@clarke-energia/foton';

export const CustomClipboardFeedbackNotificationContents = (
  onCloseNotification: () => void,
): Record<string, FeedbackNotificationProps> => {
  return {
    COPY_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'Informações da simulação foram copiadas.',
      onCloseNotification,
    },
    COPY_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Não foi possível copiar as informações da simulação.',
      onCloseNotification,
    },
    PASTE_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'Informações da simulação foram inseridas.',
      onCloseNotification,
    },
    PASTE_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Não foi possível inserir as informações da simulação.',
      onCloseNotification,
    },
  };
};

export const writeUserClipboard = (formValues: string) => {
  return navigator.clipboard.writeText(formValues);
};

export const readUserClipboard = () => {
  return navigator.clipboard.readText();
};

export const setCustomClipboardValue = (formValues: string) => {
  localStorage.setItem('CUSTOM_CLIPBOARD', formValues);
};

export const readCustomClipboardValue = () => {
  return localStorage.getItem('CUSTOM_CLIPBOARD');
};
