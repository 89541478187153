import { twMerge } from 'tailwind-merge';
import { HeroIcon, IconProps } from '@clarke-energia/foton';
import { IRetailcardDetailProps, IRetailTableCardData } from '@components/organisms/new-proposal/management/types';
import { formatNumber } from '@utils/numeral';
import './styles.css';

interface IServiceDetail {
  title: string;
  price?: number;
  isCardDistacled?: boolean;
}

function getFormattedPrice(price?: number, title?: string) {
  if (price === undefined || title === undefined) {
    return 'R$ 000,00';
  }
  const formattedPrice = `${formatNumber(price, '$ 00,0.00')}`;
  const priceSuffix = title === 'Serviço de migração' ? 'à vista' : '/mês';
  return `${formattedPrice} ${priceSuffix}`;
}

function ServiceDetail({ title, price }: IServiceDetail) {
  return (
    <span className="retail-card-service-detail">
      <h2 className="retail-card-service-title">{title}</h2>
      <p className="retail-card-service-price">{getFormattedPrice(price, title)}</p>
    </span>
  );
}

function RenderIcon({
  image,
  imageSvg,
  className,
}: {
  image?: IconProps['icon'];
  imageSvg?: string;
  className?: string;
}) {
  return (
    <div className={twMerge('retail-card-icon-container', className)}>
      <div>
        {imageSvg && imageSvg !== '' ? (
          <img className={'retail-card-icon text-primary-90'} src={imageSvg} alt="icon-img" />
        ) : (
          image && <HeroIcon icon={image} className={'retail-card-icon text-primary-90'} />
        )}
      </div>
    </div>
  );
}

function RetailTableCard({
  retailTableCardData,
  className,
}: {
  retailTableCardData: IRetailTableCardData;
  className: string;
}) {
  return (
    <div className="retail-card-table-container">
      <div className={twMerge('retail-card-table-content')}>
        <RenderIcon image={retailTableCardData.headerContent.image} className={className} />

        <div className="retail-card-table-data">
          <h2>{retailTableCardData.headerContent.title}</h2>
          <table className="retail-card-table">
            <tbody>
              <div className="retail-card-table-grid">
                {retailTableCardData.annualData.map((data, index) => (
                  <tr key={index} className="retail-card-table-row">
                    <td className="retail-card-table-year">{data.year}</td>
                    <td className="retail-card-table-price">{data.price}</td>
                  </tr>
                ))}
              </div>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function renderServiceDetail(title: string, price?: number) {
  return (
    <div className="retail-card-service-detail-container">
      <ServiceDetail title={title} price={price} isCardDistacled={false} />
    </div>
  );
}

function RetailCard({ headerContent, migrationCost, retailPrice }: IRetailcardDetailProps) {
  const cardData = [
    {
      image: headerContent.migrationImage,
      title: 'Serviço de migração',
      price: migrationCost,
    },
    {
      image: headerContent.managementImage,
      title: 'Serviço de gestão',
      price: retailPrice,
    },
  ];

  return (
    <div className="retail-card-container">
      <div className="retail-card-content">
        <div className="retail-card-detail">
          {cardData.map((cardDetail) => (
            <div key={`retail-card-${cardDetail.title}`} className="retail-card-detail-item">
              <RenderIcon image={cardDetail.image} />
              {renderServiceDetail(cardDetail.title, cardDetail.price)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function RetailCardDetail({ retailCardData }: { retailCardData: IRetailcardDetailProps }) {
  const { retailTableCardData } = retailCardData;
  return (
    <div className="retail-card-detail-container">
      <div className="retail-card-detail-header">
        <RetailCard {...retailCardData} />
      </div>
      <div className="retail-card-table-section">
        {retailTableCardData && (
          <RetailTableCard
            retailTableCardData={retailTableCardData}
            className="desktop:w-full desktop:max-w-[5rem] desktop:h-20"
          />
        )}
      </div>
    </div>
  );
}

export default RetailCardDetail;
