import React from 'react';

interface IGridElementsProps {
  data: any[];
  columnsNumber?: number;
}

const GridElements: React.FC<IGridElementsProps> = ({ data, columnsNumber }: IGridElementsProps) => (
  <div className={`py-1 w-full grid gap-x-[24px] grid-cols-${columnsNumber ? columnsNumber : '4'}`}>
    {data &&
      data.map((item) => (
        <div key={`grid-element-${item.title}`} className="flex flex-col gap-1 py-[8px]">
          <div className="flex text-[10px] leading-4 text-neutral-50">{item.title}</div>
          <div className="flex text-[10px] leading-4">{item.value}</div>
        </div>
      ))}
  </div>
);

export default GridElements;
