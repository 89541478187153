import { Layout, NavigationLinkProps } from '@clarke-energia/foton';
import HomeLinkSection from './home-link-section';

interface IHomeCommercialDealer {
  simulatorsLinks: Array<NavigationLinkProps>;
  isCommercialDealer?: boolean;
}

const HomeCommercialDealer = ({ simulatorsLinks, isCommercialDealer }: IHomeCommercialDealer) => {
  return (
    <Layout title="Área do Parceiro" darkerBackground className="text-heading-large font-bold">
      <div className="links-container">
        <div className="mb-11">
          <h1 className="text-heading-xlarge font-normal mb-3">Olá, Parceiro!</h1>
          <p className="w-2/3 text-paragraph-medium font-regular">
            Essa é a área do revendedor. Aqui você pode simular e acompanhar o histórico das etapas de suas simulações.
            Além de ter em mãos documentos e informações importantes.
          </p>
        </div>
        <HomeLinkSection
          title="O que o seu cliente precisa?"
          links={simulatorsLinks}
          isCommercialDealer={isCommercialDealer}
        />
      </div>
    </Layout>
  );
};

export default HomeCommercialDealer;
