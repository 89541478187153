import {
  LeadProposalsHubHistoryAccordion,
  LeadProposalsHubHistory,
} from '@components/atoms/general/lead-proposals-hub/history';
import { IProductOneProposal } from '@contexts/product-one/types';
import { ILeadProposalsHubHistoryBase } from '@contexts/types';

import ProductOneProposalPreviewBody from '../body';

export interface IProductOneLeadProposalsHub extends ILeadProposalsHubHistoryBase {
  onClickDeleteProposal: (leadId: string, selectedProposalId: string) => Promise<void>;
  proposalsList: Array<IProductOneProposal>;
}

const ProductOneLeadProposalsHubHistory = ({
  leadId,
  proposalsList,
  selectedProposalId,
  setSelectedProposalId,
  onClickDeleteProposal,
  feedbackNotificationsContent,
}: IProductOneLeadProposalsHub): JSX.Element => {
  return (
    <LeadProposalsHubHistory>
      <>
        {proposalsList?.map((proposal) => (
          <LeadProposalsHubHistoryAccordion
            key={`lead-proposals-hub-historic-p1-${proposal?.id}`}
            id={proposal?.id}
            sellerName={proposal.seller?.name}
            createdAt={proposal?.createdAt}
          >
            <ProductOneProposalPreviewBody
              leadProposal={proposal}
              leadId={leadId}
              selectedProposalId={selectedProposalId}
              setSelectedProposalId={setSelectedProposalId}
              onClickDeleteProposal={onClickDeleteProposal}
              feedbackNotificationsContent={feedbackNotificationsContent}
            />
          </LeadProposalsHubHistoryAccordion>
        ))}
      </>
    </LeadProposalsHubHistory>
  );
};
export default ProductOneLeadProposalsHubHistory;
