import useWhiteLabel from '@hooks/use-white-label';
import ContactUs from './contactUs';
import NextStepsProposal from './section';
import TimelineSection from './timeline';

function NextStepSectionProposalSection() {
  const { isWhiteLabelProposal } = useWhiteLabel();

  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  return (
    <>
      {isManagementPath && !isWhiteLabelProposal && (
        <>
          <NextStepsProposal />
          <TimelineSection />
        </>
      )}
      <ContactUs />
    </>
  );
}

export default NextStepSectionProposalSection;
