import { useNavigate } from 'react-router-dom';
import { Button, FeedbackNotification } from '@clarke-energia/foton';

import useWhiteLabel from '@hooks/use-white-label';

export function AddSupplierFormFooter() {
  const { useLoadingEffect, feedbackData, setFeedbackData } = useWhiteLabel();
  const isUpdateWhiteLabelSupplierPath = window.location.pathname.includes('editar');
  const navigate = useNavigate();
  const isLoading = useLoadingEffect();
  return (
    <div>
      <div className="fixed bottom-8 right-12">
        {feedbackData && feedbackData.message && feedbackData.kind && (
          <FeedbackNotification
            kind={feedbackData.kind}
            label={feedbackData.label}
            message={feedbackData.message}
            timeToCloseAutomaticallyInSeconds={10}
            onCloseNotification={() => setFeedbackData(undefined)}
          />
        )}
      </div>
      <div className="flex flex-row gap-4 mt-20">
        <Button
          kind="primary"
          type="submit"
          label={isUpdateWhiteLabelSupplierPath ? 'Salvar' : 'Adicionar fornecedora'}
          disabled={isLoading}
          loading={isLoading}
        />
        <Button
          kind="secondary"
          type="button"
          label="Cancelar"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => navigate(-1)}
        />
      </div>
    </div>
  );
}
