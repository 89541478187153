import { HeroIcon, NavigationLinkProps } from '@clarke-energia/foton';
import { useNavigate } from 'react-router-dom';
import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';
import { getCalculatorName } from '@utils/common';
import { ADMIN_PATH, CLARKE_MANAGEMENT_PATH, CLARKE_RETAIL_PATH } from '@routers/constants';
import { useAuth } from '@src/auth-wrapper';

interface IHomeLink {
  item: NavigationLinkProps;
  externalLink?: boolean;
  isCommercialDealer?: boolean;
}

const HomeLink = ({ item, externalLink, isCommercialDealer }: IHomeLink) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const pathLink = item.sublinks ? item.sublinks[0].path : item.path;

  const iconColor = isCommercialDealer && 'text-primary-60';
  const styleLinkTitle = isCommercialDealer && 'text-heading-large font-normal';

  const getEventLabel = (link: string) => {
    if (link.includes(CLARKE_MANAGEMENT_PATH)) {
      return eventLabels.BUTTON_MANAGEMENT_HOME;
    } else if (link.includes(CLARKE_RETAIL_PATH)) {
      return eventLabels.BUTTON_RETAIL_HOME;
    } else if (link.includes(ADMIN_PATH)) {
      return eventLabels.BUTTON_ADMIN_HOME;
    } else {
      return '-';
    }
  };

  const handleButtonHomeDinamicClick = () => {
    if (pathLink) {
      eventTracker.trackEvent(getEventLabel(pathLink), {
        product: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });

      if (externalLink) {
        window.location.href = pathLink;
      } else {
        navigate(pathLink);
      }
    }
  };

  return (
    <button className="link-item h-full" onClick={handleButtonHomeDinamicClick}>
      <div className="flex flex-col gap-4">
        {item.iconName && <HeroIcon icon={item.iconName} className={`h-6 w-6 ${iconColor}`} />}
        <p className={`link-title ${styleLinkTitle}`}>{item.label}</p>
      </div>
    </button>
  );
};

export default HomeLink;
