import useWhiteLabel from '@hooks/use-white-label';
import { IProductOneProposal } from '@contexts/product-one/types';
import { IProductTwoProposal } from '@contexts/product-two/types';
import { formatNumber } from '@utils/numeral';
import './styles.css';

interface AnnualDetail {
  year: string;
  economy: number;
  supply: number;
  captiveMarket: number;
  freeMarket: number;
}
export interface IAnnualDetail {
  year: string;
  economy: number;
  supply: number;
  captiveMarket: number;
  freeMarket: number;
}
function extractAnnualDetail(proposal: IProductOneProposal['calculatorMetadata']['firstResult']): AnnualDetail[] {
  const annualDetail: IAnnualDetail[] = Object.keys(proposal?.grossMerchandiseVolumeByYear || {}).map((year) => {
    const supply = proposal?.unitsReports?.[0].priceList![Object.keys(proposal?.unitsReports?.[0].priceList!)?.[0]][
      year
    ] as number;
    return {
      year,
      economy: proposal?.totalSavingByYear[year],
      captiveMarket: proposal?.totalCurrentSpentByYear[year],
      freeMarket: proposal?.totalTargetSpentByYear[year],
      supply,
    };
  });
  return annualDetail;
}

function extractAnnualDetailRetail(
  proposal: IProductTwoProposal['calculatorMetadata']['default']['bestRetailTraderResult']['calculatorMetadata'],
): AnnualDetail[] {
  const annualDetail: IAnnualDetail[] = Object.keys(proposal?.grossMerchandiseVolumeByYear || {}).map((year) => {
    const supply = proposal?.unitsReports?.[0].priceList![Object.keys(proposal?.unitsReports?.[0].priceList!)?.[0]][
      year
    ] as number;
    return {
      year,
      economy: proposal?.totalSavingByYear[year],
      captiveMarket: proposal?.totalCurrentSpentByYear[year],
      freeMarket: proposal?.totalTargetSpentByYear[year],
      supply,
    };
  });
  return annualDetail;
}

function extractChartDataRetail(
  proposal: IProductTwoProposal['calculatorMetadata']['default']['bestRetailTraderResult']['calculatorMetadata'],
): ChartData {
  const { isWhiteLabelProposal } = useWhiteLabel();

  const chartData: ChartData = {
    labels: [],
    currentValues: [],
    targetValues: [],
    savingValues: [],
    subtitleData: [
      { color: isWhiteLabelProposal ? 'white-label-brighter' : 'neutral-30', description: 'Mercado Cativo' },
      { color: isWhiteLabelProposal ? 'white-label-main' : 'primary-70', description: 'Mercado Livre' },
      { color: isWhiteLabelProposal ? 'white-label-darker' : 'black', description: '% de economia' },
    ],
  };

  if (proposal?.totalCurrentSpentByYear && proposal?.totalTargetSpentByYear && proposal?.totalSavingByYearPercentage) {
    Object.keys(proposal?.totalCurrentSpentByYear).forEach((year: string) => {
      chartData.labels.push(year);
      chartData.currentValues.push(proposal?.totalCurrentSpentByYear[year]);
      chartData.targetValues.push(proposal?.totalTargetSpentByYear[year]);
      chartData.savingValues.push(Math.round(parseFloat(proposal?.totalSavingByYearPercentage[year].toFixed(2)) * 100));
    });
  }

  return chartData;
}
export interface ChartData {
  labels: string[];
  currentValues: number[];
  targetValues: number[];
  savingValues: number[];
  subtitleData: { color: string; description: string }[];
}

function extractChartData(proposal: IProductOneProposal['calculatorMetadata']['firstResult']): ChartData {
  const { isWhiteLabelProposal } = useWhiteLabel();
  const chartData: ChartData = {
    labels: [],
    currentValues: [],
    targetValues: [],
    savingValues: [],
    subtitleData: [
      { color: isWhiteLabelProposal ? 'white-label-brighter' : 'neutral-30', description: 'Mercado Cativo' },
      { color: isWhiteLabelProposal ? 'white-label-main' : 'primary-70', description: 'Mercado Livre' },
      { color: isWhiteLabelProposal ? 'white-label-darker' : 'black', description: '% de economia' },
    ],
  };

  if (proposal?.totalCurrentSpentByYear && proposal?.totalTargetSpentByYear && proposal?.totalSavingByYearPercentage) {
    Object.keys(proposal?.totalCurrentSpentByYear).forEach((year: string) => {
      chartData.labels.push(year);
      chartData.currentValues.push(proposal?.totalCurrentSpentByYear[year]);
      chartData.targetValues.push(proposal?.totalTargetSpentByYear[year]);
      chartData.savingValues.push(Math.round(parseFloat(proposal?.totalSavingByYearPercentage[year].toFixed(2)) * 100));
    });
  }

  return chartData;
}

interface EconomyData {
  totalSaving: string;
  totalSavingPercentage: string;
  totalSavingByMonth: string;
  migrationDate: string;
  migrationCost: string;
  managementCost: string;
  discountPercentage: string;
}

function extractEconomyData(
  inputs:
    | IProductOneProposal['calculatorMetadata']['inputs']
    | IProductTwoProposal['calculatorMetadata']['default']['bestRetailTraderResult']['inputs'],
  firstResult:
    | IProductOneProposal['calculatorMetadata']['firstResult']
    | IProductTwoProposal['calculatorMetadata']['default']['bestRetailTraderResult']['calculatorMetadata'],
): EconomyData {
  // TODO remove conditional after 2 weeks of implementation
  const totalMonthlyManagementCost = firstResult?.totalMonthlyManagementCost
    ? firstResult.totalMonthlyManagementCost
    : inputs?.units &&
      inputs?.units.reduce((accumulator, unit) => {
        return accumulator + unit.managementPrice;
      }, 0);

  const economyData = {
    totalSaving: firstResult?.totalSaving,
    totalSavingPercentage: firstResult?.totalSavingPercentage ?? 0,
    totalSavingByMonth: firstResult?.totalSavingByMonth,
    migrationDate: inputs?.units?.[0]?.migrationDate,
    migrationCost: firstResult?.totalMigrationCost,
    managementCost: totalMonthlyManagementCost,
    discountPercentage: firstResult?.discountFreeServiceProposalPrices?.discountPercentage ?? 0,
  };
  return {
    totalSaving: formatNumber(economyData.totalSaving, '$ 00,0.00'),
    totalSavingPercentage: formatNumber(economyData.totalSavingPercentage, '0%'),
    totalSavingByMonth: formatNumber(economyData.totalSavingByMonth, '$ 00,0.00'),
    migrationDate: economyData.migrationDate as string,
    migrationCost: `${formatNumber(economyData.migrationCost, '$ 00,0.00')}  à vista`,
    managementCost: `${formatNumber(economyData.managementCost, '$ 00,0.00')}/mês`,
    discountPercentage: formatNumber(economyData.discountPercentage, '0%'),
  };
}

export { extractAnnualDetail, extractChartData, extractEconomyData, extractAnnualDetailRetail, extractChartDataRetail };
