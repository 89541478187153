import { useContextSelector } from 'use-context-selector';

import { LeadsContext } from '@contexts/lead';
import { ILeadsContext } from '@contexts/lead/types';

const useLeads = (): ILeadsContext => {
  const getLeads = useContextSelector(LeadsContext, (lead) => lead.getLeads);
  const createLead = useContextSelector(LeadsContext, (lead) => lead.createLead);
  const getLeadById = useContextSelector(LeadsContext, (lead) => lead.getLeadById);

  return {
    getLeads,
    createLead,
    getLeadById,
  };
};

export default useLeads;
