import { TableRow } from './tableRow';

interface TableBodyProps {
  rows: {
    row: (string | number)[];
    tagStyle: string;
  }[];
}

export function TableBody({ rows }: TableBodyProps) {
  return (
    <tbody>
      {rows?.map(({ row, tagStyle }, i) => (
        <TableRow key={i} row={row} tagStyle={tagStyle} rowIndex={i} />
      ))}
    </tbody>
  );
}
