import React from 'react';
import { useFieldArray } from 'react-hook-form';

import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';

import { IWhiteLabelSupplierPayload } from '@contexts/white-label/type';
import { WhiteLabelDifferenceGeneralSubsection } from '@components/atoms/form/contract/simulator-differences-section/contract-difference-general-subsection';
import { DifferentialsControls } from '@components/atoms/form/contract/simulator-differences-section/contract-difference-controls-subsection';

export function WhiteLabelSupplierFormDifferencesSection() {
  const { fields, remove, append } = useFieldArray<IWhiteLabelSupplierPayload>({
    name: 'differences',
    rules: { minLength: 1, required: true },
  });

  return (
    <ContractSectionContainer>
      <div className="grid grid-cols-3 col-span-full min-w-full">
        <ContractTitleAndSubtitleSubsection
          title="Diferenciais"
          subtitle="Até 4 benefícios que a fornecedora oferece a seus clientes."
        />
        <div className="col-span-2 columns-1">
          <div className="gap-10 flex flex-col">
            {fields.map((field, index) => (
              <React.Fragment key={`${field}-${index}`}>
                <WhiteLabelDifferenceGeneralSubsection key={field.id} differenceNum={index} />
                <DifferentialsControls
                  differenceNum={index}
                  removeDifference={remove}
                  appendDifference={() => append({ title: '', description: '', icon: '' })}
                  differencesLength={fields.length}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </ContractSectionContainer>
  );
}
