import { twMerge } from 'tailwind-merge';

interface ICardContainer {
  columnsSpan: number;
  children: JSX.Element;
  isPremisesCard?: boolean;
}

export const CardContainer = ({ columnsSpan, children, isPremisesCard }: ICardContainer) => {
  const containerClassFixed = 'h-fit md:h-full col-span-1 bg-white px-5 py-6 rounded-large';
  const containerClassDynamic = `${isPremisesCard && 'mt-32 md:mt-0'} xl:col-span-${columnsSpan}`;

  return <div className={twMerge(containerClassFixed, containerClassDynamic)}>{children}</div>;
};
