import serviceBackground from '@assets/images/service-bg.png';
import fingerPrintIcon from '@assets/images/finger-print.png';
import userCircleIcon from '@assets/images/user-circle.png';
import lightBulbIcon from '@assets/images/light-bulb.png';
import leafIcon from '@assets/images/Leaf.png';
import ConclusiveSectionLayout from '@components/molecules/product-two/simulation/conclusive-section-layout';

const gadgetWithIconContent = [
  {
    imgSrc: fingerPrintIcon,
    titleText: 'Consultoria independente',
    text: 'Encontramos a melhor solução para empresas. Não estamos presos a nenhum fornecedor de energia, e por isso, vamos sempre sugerir o contrato mais adequado para o seu negócio.',
  },
  {
    imgSrc: userCircleIcon,
    titleText: 'Atendimento consultivo',
    text: 'O cliente não se preocupa com burocracia e com processos. Cuidamos de todo o diagnóstico técnico e damos suporte à otimização dos custos com energia dos nossos clientes.',
  },
  {
    imgSrc: lightBulbIcon,
    titleText: 'Plataforma tecnológica',
    text: 'Experiência simplificada nos processos do mercado livre de energia. Acompanhamento das economias (em R$) e da redução das emissões de CO2.',
  },
  {
    imgSrc: leafIcon,
    titleText: 'Energia limpa com preço competitivo',
    text: 'Na Clarke, toda energia vendida é de fontes limpas e renováveis, e nosso marketplace é a única plataforma do Brasil que garante o melhor preço de energia.',
  },
];

const AboutService: React.FC = () => {
  return (
    <ConclusiveSectionLayout
      mainTitle="Conheça a sua Área do Cliente cheia de tecnologia e informação."
      backgroundImg={serviceBackground}
      gadgetWithIconContent={gadgetWithIconContent}
    />
  );
};

export default AboutService;
