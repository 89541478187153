import { IWhiteLabelSupplierPayload, IWhiteLabelSupplierColors } from '@contexts/white-label/type';
import { ColorPickerFormField } from '../fields/color-picker-form-field';

enum colorsEnum {
  main = 'Cor principal',
  brighter = 'Cor mais clara',
  darker = 'Cor mais escura',
}

export function RenderSupplierColors({
  colors,
  required = false,
}: {
  colors?: IWhiteLabelSupplierColors;
  required: boolean;
}) {
  return (
    <>
      {colors &&
        Object.keys(colors).map((key) => {
          const colorKey = key as keyof IWhiteLabelSupplierColors;
          return (
            <ColorPickerFormField<IWhiteLabelSupplierPayload>
              key={colorKey}
              label={`${colorsEnum[colorKey]} (HEX) `}
              placeholder={'#4787ff'}
              field={`colors.${colorKey}`}
              data-cy={`colors.${colorKey}`}
              id={`colors.${colorKey}`}
              options={{ required, validate: (value) => (value ? true : 'Campo obrigatório') }}
            />
          );
        })}
    </>
  );
}
