import { useFieldArray } from 'react-hook-form';

import { IManagementContractPayload } from '@contexts/management-contract/types';

import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';

import ContractFormUnitSection from './contract-form-unit-section';

interface IContractFormUnitsSection {
  fieldsController?: Record<keyof IManagementContractPayload | string, boolean>;
}

const contractUnitDefaultValues = {
  name: undefined,
  address: undefined,
  installationNumber: undefined,
};

function ContractFormUnitsSection({ fieldsController }: IContractFormUnitsSection) {
  const { fields, append, remove } = useFieldArray<IManagementContractPayload>({
    name: 'inputs.units',
    rules: { minLength: 1, required: true },
  });

  return (
    <ContractSectionContainer>
      <>
        <ContractTitleAndSubtitleSubsection
          title="Unidades consumidoras"
          subtitle="Quais os locais que irão consumir a energia?"
        />
        <div className="flex flex-col w-full">
          <div className="flex flex-col gap-12 w-full">
            {fields.map((field, index) => (
              <ContractFormUnitSection
                key={field.id}
                unitNum={index}
                removeUnit={remove}
                appendUnit={() => append(contractUnitDefaultValues)}
                fieldsController={fieldsController}
                unitsLength={fields.length}
              />
            ))}
          </div>
        </div>
      </>
    </ContractSectionContainer>
  );
}

export default ContractFormUnitsSection;
