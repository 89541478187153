import { twMerge } from 'tailwind-merge';
import { formatNumber } from '@utils/numeral';
import { useAuth } from '@src/auth-wrapper';

interface IProductOneProposalKeyMetricsPanel {
  totalSaving?: number;
  totalSavingPercentage?: number;
  totalAverageConsumption?: number;
  targetYear?: number;
  energyFeeMonthly?: Record<string, number>;
  managementFeeMonthly?: number;
  grossMerchandiseVolume?: number;
  takeRate?: number;
  lifetimeValue?: number;
  carbonDioxideEmissionInTonsAvoided?: number;
  isMainContent?: boolean;
}

export interface IKeyMetric {
  title: string;
  value: string;
}

export const ProductOneProposalKeyMetricsPanel = ({
  totalSaving,
  totalSavingPercentage,
  totalAverageConsumption,
  targetYear,
  energyFeeMonthly,
  managementFeeMonthly,
  grossMerchandiseVolume,
  lifetimeValue,
  takeRate,
  carbonDioxideEmissionInTonsAvoided,
  isMainContent,
}: IProductOneProposalKeyMetricsPanel) => {
  const { user } = useAuth();

  const keyMetricsForCommercialDealer: Array<IKeyMetric> = [
    {
      title: `Economia em ${targetYear} ano(s) (R$)`,
      value: totalSaving ? formatNumber(totalSaving, '$ 0,0.00') : '-',
    },
    {
      title: `Economia em ${targetYear} ano(s) (%)`,
      value: totalSavingPercentage ? formatNumber(totalSavingPercentage, '% 0,0') : '-',
    },
    {
      title: 'Emissão de CO² evitadas',
      value: carbonDioxideEmissionInTonsAvoided
        ? formatNumber(carbonDioxideEmissionInTonsAvoided, '0,0').concat(' toneladas')
        : '-',
    },
  ];

  const keyMetricsDefault: Array<IKeyMetric> = [
    {
      title: `Economia em ${targetYear} ano(s) (R$)`,
      value: totalSaving ? formatNumber(totalSaving, '$ 0,0.00') : '-',
    },
    {
      title: `Economia em ${targetYear} ano(s) (%)`,
      value: totalSavingPercentage ? formatNumber(totalSavingPercentage, '% 0,0') : '-',
    },
    {
      title: 'Energy Fee Monthly (EFM)',
      value: energyFeeMonthly ? formatNumber(Object.values(energyFeeMonthly)[0], '$ 0,0.00') : '-',
    },
    {
      title: 'Management Fee Monthly (MFM)',
      value: managementFeeMonthly ? formatNumber(managementFeeMonthly, '$ 0,0.00') : '-',
    },
    {
      title: 'Gross Merchandise Volume (GMV)',
      value: grossMerchandiseVolume ? formatNumber(grossMerchandiseVolume, '$ 0,0.00') : '-',
    },
    {
      title: 'Take Rate (TR)',
      value: takeRate ? formatNumber(takeRate, '$ 0,0.00') : '-',
    },
    {
      title: 'Life Time Value (LTV)',
      value: lifetimeValue ? formatNumber(lifetimeValue, '$ 0,0.00') : '-',
    },
    {
      title: 'Emissão de CO² evitadas',
      value: carbonDioxideEmissionInTonsAvoided
        ? formatNumber(carbonDioxideEmissionInTonsAvoided, '0,0').concat(' toneladas')
        : '-',
    },
    {
      title: 'Consumo Médio',
      value: totalAverageConsumption ? formatNumber(totalAverageConsumption, '0,0.000').concat(' MWm') : '-',
    },
  ];

  const keyMetrics = user?.isCommercialDealer ? keyMetricsForCommercialDealer : keyMetricsDefault;

  const dinamicClass = user?.isCommercialDealer ? 'grid-cols-2' : isMainContent ? 'grid-cols-3' : 'grid-cols-2';

  return (
    <div className={twMerge('grid mb-5 mt-8 gap-x-10', dinamicClass)}>
      {keyMetrics.map((keyMetric) => (
        <div className="flex flex-col col-span-1 mb-6" key={`proposal-key-metric-${keyMetric.title}`}>
          <label className="text-paragraph-small font-normal text-neutral-50">{keyMetric.title}</label>
          <label>{keyMetric.value}</label>
        </div>
      ))}
    </div>
  );
};
