import SemanticMarquee from '@components/atoms/general/semantic-marquee';

import LeadProposalsHubFooter, { IProposalPreviewFooter } from '../general/lead-proposals-hub/footer';

interface ILeadProposalsHubBodyWrapper {
  children: JSX.Element;
  simulationPreviewFooterProps: IProposalPreviewFooter;
  isProposalComplete?: boolean;
}

const LeadProposalsHubBodyWrapper = ({
  children,
  simulationPreviewFooterProps,
  isProposalComplete = false,
}: ILeadProposalsHubBodyWrapper): JSX.Element => {
  return (
    <div className="flex flex-col justify-start">
      <div className="mb-6 mt-3">
        {isProposalComplete ? (
          <>{children}</>
        ) : (
          <SemanticMarquee kind="ERROR">
            Esta proposta possui dados faltantes ou não pôde ser processada corretamente. Copie os dados e tente
            novamente ou ative o suporte técnico.
          </SemanticMarquee>
        )}
      </div>
      <LeadProposalsHubFooter {...simulationPreviewFooterProps} isProposalComplete={isProposalComplete} />
    </div>
  );
};

export default LeadProposalsHubBodyWrapper;
