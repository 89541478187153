import React, { useState } from 'react';
import { Modal } from '@clarke-energia/foton';

import { GENERAL_LIST_PATH, CLARKE_RETAIL_PATH } from '@routers/constants';

import ProductTwoSimulatorForm from './product-two-simulator-form';

const ProductTwoSimulator = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);

  return (
    <>
      <ProductTwoSimulatorForm {...{ setSuccessModal, setOpenModal }} />
      <Modal
        open={openModal}
        setOpen={() => setOpenModal((previous) => !previous)}
        title={successModal ? 'Sucesso' : 'Houve um problema...'}
        id="modal-product-two-result"
        description={
          successModal
            ? 'O formulário foi enviado com sucesso. Estamos calculando os possíveis cenários e em instantes os resultados estarão na listagem de propostas Clarke Varejo.'
            : 'Houve um problema ao enviar os dados. Verifique o formulário e tente novamente. Caso persista, contate o setor responsável.'
        }
        modalButtons={{
          primary: {
            label: successModal ? 'Ir para lista' : 'Fechar',
            onClick: () =>
              successModal ? (location.href = `${CLARKE_RETAIL_PATH}/${GENERAL_LIST_PATH}`) : setOpenModal(false),
          },
        }}
      />
    </>
  );
};

export default ProductTwoSimulator;
