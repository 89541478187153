import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosPromise } from 'axios';

import {
  FeedbackNotification,
  FeedbackNotificationProps,
  Pagination,
  Table,
  ThreeDotsMenuProps,
} from '@clarke-energia/foton';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { buildLeadProposalsPath } from '@routers/path-builder';

import {
  IPaginatedResponseBase,
  IProposalLead,
  IProposalsAggregatedByLeadBase,
  IResponseBase,
  SimulatorTypeEnum,
} from '@contexts/types';

import { getCalculatorName, getProposalManagementFeedbackNotificationContent } from '@utils/common';

import { useAuth } from '@src/auth-wrapper';

import { IProductTwoProposal } from '@contexts/product-two/types';
import { IProductOneProposal } from '@contexts/product-one/types';
import { useLeadsHistory } from '@hooks/use-leads-history';

import SimulatorLayoutBase from '@components/organisms/simulator-layout-base';
import ModalDeleteAllProposalsByLeadId from '@components/molecules/simulations-list/modal-delete-all-proposals-by-lead-id';
import TableSkeleton from '@components/atoms/skeletons/table-skeleton';
import {
  commercialDealerTableColumnsForProductOne,
  commercialDealerTableColumnsForProductTwo,
  defaultTableColumns,
  productTwoTableColumns,
} from '@components/atoms/general/simulations-list/table/proposals-history-table-columns/helper';
import { getProposalsHistoryTableColumns } from '@components/atoms/general/simulations-list/table/proposals-history-table-columns';

export type IProposalsHistoryPaginated<T> = IPaginatedResponseBase<IProposalsAggregatedByLeadBase<T>>;

interface ILeadsHistory<T> {
  simulatorType: keyof typeof SimulatorTypeEnum;
  deleteAllProposalsByLeadIdHandler: (leadId: string) => AxiosPromise<IResponseBase<undefined>>;
  selectedLeadId?: string;
  setSelectedLeadId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: JSX.Element;
}

function LeadsHistory<T extends IProductOneProposal | IProductTwoProposal>({
  simulatorType,
  deleteAllProposalsByLeadIdHandler,
  selectedLeadId,
  setSelectedLeadId,
  setIsDrawerOpen,
  children,
}: ILeadsHistory<T>): JSX.Element {
  const { user } = useAuth();
  const { leadId } = useParams<{ leadId: string }>();
  const navigate = useNavigate();
  const { fetchData, setSearchText, loading, leadList, setCurrentPage } = useLeadsHistory<T>(simulatorType);

  const [feedbackNotificationsContent, setFeedbackNotificationsContent] = React.useState<FeedbackNotificationProps>();
  const [openProposalsDeleteModal, setOpenProposalsDeleteModal] = React.useState<boolean>(false);

  const deleteAllProposalsByLeadId = async (leadId: string) => {
    const feedbackNotificationContent = getProposalManagementFeedbackNotificationContent(() =>
      setFeedbackNotificationsContent(undefined),
    );

    try {
      const response = await deleteAllProposalsByLeadIdHandler(leadId);
      if (response.data.success) {
        setFeedbackNotificationsContent(feedbackNotificationContent.DELETE_ALL_PROPOSALS_BY_LEAD_SUCCESS);
        fetchData();
      }
    } catch {
      setFeedbackNotificationsContent(feedbackNotificationContent.DELETE_ALL_PROPOSALS_BY_LEAD_FAILURE);
    }
  };

  const getMoreOptionsButtons = (leadId: string): ThreeDotsMenuProps['menuOptions'] => [
    {
      icon: 'EyeIcon',
      onClick: () => {
        setIsDrawerOpen(true);
        navigate(buildLeadProposalsPath(leadId));
      },
      label: 'Ver detalhes',
    },
    {
      icon: 'TrashIcon',
      onClick: () => {
        setSelectedLeadId(leadId);
        setOpenProposalsDeleteModal(true);
      },
      label: 'Excluir',
    },
  ];

  const getTableColumns = () => {
    const isProductTwoProposal = simulatorType === 'CLARKE_RETAIL' && true;
    if (user?.isCommercialDealer) {
      if (isProductTwoProposal) {
        return commercialDealerTableColumnsForProductTwo;
      }
      return commercialDealerTableColumnsForProductOne;
    } else if (isProductTwoProposal) {
      return productTwoTableColumns;
    } else {
      return defaultTableColumns;
    }
  };

  const getLeadNameById = (selectedLeadId: string): string | undefined => {
    const leadFound = leadList && leadList?.data?.find((lead) => lead.id === selectedLeadId);
    return leadFound?.name;
  };

  const openLeadProposalsHub = (leadId: string) => {
    setIsDrawerOpen(true);
    setSelectedLeadId(leadId);
    navigate(buildLeadProposalsPath(leadId));
  };

  React.useEffect(() => {
    if (leadId) {
      openLeadProposalsHub(leadId);
    }
  }, [leadId]);

  return (
    <SimulatorLayoutBase
      title="Histórico"
      simulatorType={simulatorType}
      simulatorFlow="LIST"
      searchBar={{
        onTypedTermChangedCallback: setSearchText,
        placeholder: 'Pesquisar',
        onClick: () => {
          eventTracker.trackEvent(eventLabels.SEARCH_IN_SIMULATIONS_LIST, {
            product: getCalculatorName(location.href),
            user_groups: user?.groups ? user?.groups.join(', ') : '',
            user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
          });
        },
      }}
    >
      <div>
        {loading ? (
          <TableSkeleton />
        ) : leadList && leadList.total > 0 ? (
          <>
            {feedbackNotificationsContent && (
              <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit z-[200]">
                <FeedbackNotification {...feedbackNotificationsContent} />
              </div>
            )}
            <Table<IProposalLead>
              data={leadList.data}
              tableColumns={getProposalsHistoryTableColumns(getMoreOptionsButtons, getTableColumns(), simulatorType)}
              onRowClickAction={(row) => openLeadProposalsHub(row.id)}
              onRowDoubleClickAction={(row) => openLeadProposalsHub(row.id)}
            />
            <Pagination
              currentPage={leadList.page}
              rowsByPage={leadList.limit}
              totalRows={leadList.total}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : (
          <p className="w-full truncate rounded-large bg-neutral-10 border-neutral-20 border-1 px-3 py-6 text-center text-paragraph-medium text-neutral-90 font-semibold">
            Nenhuma proposta foi encontrada. Acesse a página de simulação e crie uma nova.
          </p>
        )}
      </div>
      {children}
      <ModalDeleteAllProposalsByLeadId
        leadName={selectedLeadId && getLeadNameById(selectedLeadId)}
        selectedLeadId={selectedLeadId}
        deleteAllProposalsByLeadId={deleteAllProposalsByLeadId}
        openProposalsDeleteModal={openProposalsDeleteModal}
        setOpenProposalsDeleteModal={setOpenProposalsDeleteModal}
      />
    </SimulatorLayoutBase>
  );
}

export default LeadsHistory;
