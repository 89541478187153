import { useState } from 'react';
import { AxiosPromise } from 'axios';
import { FeedbackNotification, FeedbackNotificationProps } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';

import { IProposalLinkedUsers, IProposalUser, IResponseBase, IUpdateSellerPayload } from '@contexts/types';

import ModalChangeSeller from '@components/molecules/simulations-list/modal-change-seller';

import { getCustomChangeSellerFeedbackNotificationContents } from './helper';
import { ProposalUsersSection } from './users-section';
import { DescriptionSection } from './description-section';

interface IProposalPreviewDescriptionAndUsers extends IProposalLinkedUsers {
  proposalId: string;
  description?: string;
  updateProposalSeller: (updateSellerData: IUpdateSellerPayload) => AxiosPromise<IResponseBase>;
  leadId: string;
}
interface IProposalUsersSectionAndModalChangeSeller extends IProposalLinkedUsers {
  proposalId: string;
  description?: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateProposalSeller: (updateProposalSellerData: IUpdateSellerPayload) => Promise<void>;
  currentSeller?: IProposalUser;
  leadId: string;
}

const ProposalUsersSectionAndModalChangeSeller = ({
  proposalId,
  author,
  isModalOpen,
  setIsModalOpen,
  handleUpdateProposalSeller,
  currentSeller,
  leadId,
}: IProposalUsersSectionAndModalChangeSeller): JSX.Element => {
  return (
    <>
      <ProposalUsersSection author={author} seller={currentSeller} setIsModalOpen={setIsModalOpen} />
      {isModalOpen && (
        <ModalChangeSeller
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleUpdateProposalSeller={handleUpdateProposalSeller}
          proposalId={proposalId}
          leadId={leadId}
        />
      )}
    </>
  );
};

const ProposalPreviewDescriptionAndUsers = ({
  proposalId,
  description,
  author,
  seller,
  updateProposalSeller,
  leadId,
}: IProposalPreviewDescriptionAndUsers): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSeller, setCurrentSeller] = useState<IProposalUser | undefined>(seller ?? undefined);
  const [customChangeSellerFeedbackNotificationContent, setCustomChangeSellerFeedbackNotificationContent] =
    useState<FeedbackNotificationProps>();

  const { user } = useAuth();

  const handleUpdateProposalSeller = async (updateProposalSellerData: IUpdateSellerPayload) => {
    const feedbackNotificationsContents = getCustomChangeSellerFeedbackNotificationContents(() =>
      setCustomChangeSellerFeedbackNotificationContent(undefined),
    );
    try {
      const responseUpdateProposalSeller = await updateProposalSeller(updateProposalSellerData);
      if (responseUpdateProposalSeller.data.success) {
        setCurrentSeller(updateProposalSellerData.seller);
        setIsModalOpen(false);
        setCustomChangeSellerFeedbackNotificationContent(feedbackNotificationsContents.CHANGE_SELLER_SUCCESS);
      } else throw Error;
    } catch {
      setIsModalOpen(false);
      setCustomChangeSellerFeedbackNotificationContent(feedbackNotificationsContents.CHANGE_SELLER_FAILURE);
    }
  };

  return (
    <div className="flex flex-col justify-start">
      {customChangeSellerFeedbackNotificationContent && (
        <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
          <FeedbackNotification {...customChangeSellerFeedbackNotificationContent} />
        </div>
      )}
      <DescriptionSection description={description} />
      {!user?.isCommercialDealer && (
        <ProposalUsersSectionAndModalChangeSeller
          proposalId={proposalId}
          leadId={leadId}
          author={author}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleUpdateProposalSeller={handleUpdateProposalSeller}
          currentSeller={currentSeller}
        />
      )}
    </div>
  );
};

export default ProposalPreviewDescriptionAndUsers;
