import { twMerge } from 'tailwind-merge';
import { FeedbackNotification, HeroIcon } from '@clarke-energia/foton';

import useWhiteLabel from '@hooks/use-white-label';
import { CardContainer } from '@components/atoms/simplified-proposal/card-container';
import CardHeader from '@components/atoms/simplified-proposal/card-header';

import { useAuth } from '@src/auth-wrapper';
import { ProposalCards, ICardItems } from './static-data-cards';

export const CardItems = ({ title, iconTitle, imageSvg, cardContent }: ICardItems) => {
  const { isWhiteLabelProposal } = useWhiteLabel();

  return (
    <>
      <CardHeader title={title} icon={iconTitle} imageSvg={imageSvg} isPremisesCard={false} />
      {cardContent &&
        cardContent.map(({ icon, imageSvg, label, strong, label2 }, idx) => (
          <div className="flex items-center mt-6" key={`items-card-simplified-proposal${idx}`}>
            <div>
              {imageSvg !== '' ? (
                <img className="h-6 w-6" src={imageSvg} alt="Imagem SVG" />
              ) : (
                <HeroIcon
                  icon={icon}
                  className={twMerge('h-6 w-6', isWhiteLabelProposal ? 'text-white-label-main' : 'text-primary-70')}
                />
              )}
            </div>
            <h2 className="text-paragraph-small ml-5 xl:text-paragraph-medium">
              {label}
              <strong>{strong}</strong>
              {label2}
            </h2>
          </div>
        ))}
    </>
  );
};

const SimplifiedProposalCards = (): JSX.Element => {
  const { user } = useAuth();
  const simplifiedProposalCardsDataExtract = [ProposalCards()[1], ProposalCards()[2]];
  return (
    <div className="grid grid-cols-1 px-5 pb-7 gap-7 justify-center md:grid-cols-2  md:px-0 md:pb-0 xl:gap-9">
      <div className="grid lg:hidden col-span-1 md:col-span-2">
        <CardContainer isPremisesCard={false} columnsSpan={2}>
          <CardItems
            title={ProposalCards()[0].title}
            iconTitle={ProposalCards()[0].iconTitle}
            imageSvg={ProposalCards()[0].imageSvg}
            cardContent={ProposalCards()[0].cardContent}
          />
        </CardContainer>
      </div>
      {simplifiedProposalCardsDataExtract.map(({ title, iconTitle, imageSvg, cardContent }, index) => (
        <div key={`card-simplified-proposal-${index}`} className="flex-1">
          <CardContainer isPremisesCard={false} columnsSpan={2}>
            <CardItems title={title} iconTitle={iconTitle} imageSvg={imageSvg} cardContent={cardContent} />
          </CardContainer>
        </div>
      ))}
      {user?.isCommercialDealer && (
        <div className="col-span-full">
          <FeedbackNotification
            message={'Esta proposta está sendo apresentada por um parceiro Clarke.'}
            kind={'neutral'}
            label={''}
            onCloseNotification={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
        </div>
      )}
    </div>
  );
};
export default SimplifiedProposalCards;
