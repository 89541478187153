import { AxiosPromise } from 'axios';
import { useEffect, useState } from 'react';
import { Button, FeedbackNotification, FeedbackNotificationProps, Tag, Tooltip } from '@clarke-energia/foton';

import { ICommercialDealerComission, IResponseBase } from '@contexts/types';

import { formatNumber } from '@utils/numeral';

import Slider from '@components/atoms/form/slider/slider';
import {
  calculateInInstallmentsAmountUpdate,
  getCommercialDealerComissionFeedbackNotificationContents,
} from './helper';

interface ICommercialDealerComissionTrading {
  proposalId: string;
  commercialDealerComission: ICommercialDealerComission;
  updateProposalCommercialDealerComission: (
    proposalId: string,
    commercialDealerComissionData: ICommercialDealerComission,
  ) => AxiosPromise<IResponseBase>;
}
interface ICommissionUpdateObject {
  cashCommission: number;
  valueOfInstallments: number;
  inInstallmentsAmountUpdate: number;
}

const CommercialDealerComissionTrading = ({
  proposalId,
  commercialDealerComission,
  updateProposalCommercialDealerComission,
}: ICommercialDealerComissionTrading) => {
  const { initialAmount, inCashAmount, numberOfInstallments } = commercialDealerComission;

  const commissionUpdateObjectDefault = {
    cashCommission: inCashAmount,
    valueOfInstallments: initialAmount / numberOfInstallments,
    inInstallmentsAmountUpdate: calculateInInstallmentsAmountUpdate(initialAmount, inCashAmount),
  };

  const [commissionUpdateObject, setcommissionUpdateObject] =
    useState<ICommissionUpdateObject>(commissionUpdateObjectDefault);
  const { cashCommission, valueOfInstallments, inInstallmentsAmountUpdate } = commissionUpdateObject;
  const [
    commercialDealerComissionFeedbackNotificationContent,
    setCommercialDealerComissionFeedbackNotificationContent,
  ] = useState<FeedbackNotificationProps>();
  const [wasUpdated, setwasUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  const commercialDealerComissionData = {
    initialAmount: initialAmount,
    inCashAmount: cashCommission,
    inInstallmentsAmount: inInstallmentsAmountUpdate,
    totalAmount: cashCommission + inInstallmentsAmountUpdate,
    numberOfInstallments: numberOfInstallments,
  };

  const handleUpdateCommercialDealerComission = async (
    proposalId: string,
    commercialDealerComissionData: ICommercialDealerComission,
  ) => {
    setLoading(true);
    const feedbackNotificationsContents = getCommercialDealerComissionFeedbackNotificationContents(() => {
      setCommercialDealerComissionFeedbackNotificationContent(undefined);
    });
    try {
      const responseUpdate = await updateProposalCommercialDealerComission(proposalId, commercialDealerComissionData);
      if (responseUpdate.data.success) {
        setwasUpdated(false);
        setCommercialDealerComissionFeedbackNotificationContent(
          feedbackNotificationsContents.CHANGE_COMMERCIAL_DEALER_COMISSION_SUCCESS,
        );
      } else throw Error;
    } catch {
      setCommercialDealerComissionFeedbackNotificationContent(
        feedbackNotificationsContents.CHANGE_COMMERCIAL_DEALER_COMISSION_FAILURE,
      );
    } finally {
      setLoading(false);
    }
  };
  const handleInCashComissionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cashValue = (Number(e.target.value) * initialAmount) / 100;

    setcommissionUpdateObject({
      ...commissionUpdateObject,
      cashCommission: cashValue,
      inInstallmentsAmountUpdate: calculateInInstallmentsAmountUpdate(
        initialAmount,
        cashCommission,
        totalInstallmentWithInterest,
      ),
    });
    setwasUpdated(true);
  };

  useEffect(() => {
    const valueDefaultOfInstallments =
      cashCommission === initialAmount
        ? 0
        : calculateInInstallmentsAmountUpdate(initialAmount, cashCommission) / numberOfInstallments;
    const positiveValue = Math.max(valueDefaultOfInstallments, 0);
    setcommissionUpdateObject({
      ...commissionUpdateObject,
      valueOfInstallments: positiveValue,
      inInstallmentsAmountUpdate: calculateInInstallmentsAmountUpdate(initialAmount, cashCommission),
    });
  }, [cashCommission, inInstallmentsAmountUpdate]);

  const totalInstallmentWithInterest = cashCommission + inInstallmentsAmountUpdate;
  const labelTextWithOrWithoutInterest =
    initialAmount.toFixed(2) === cashCommission.toFixed(2) ? '(sem juros)' : '(com juros)';
  return (
    <div className="container relative">
      <div className="flex flex-col w-full">
        <p className="text-heading-xsmall font-bold">Comissão do parceiro</p>
        <p className="text-neutral-70 mt-5 mb-7">Comissão inicial: {formatNumber(initialAmount, '$ 0,0.00')}</p>
        <label>
          Escolher <strong>valor à vista</strong>
        </label>
        <div className="flex items-center w-full justify-between">
          <Slider
            className="w-5/6 mb-4"
            initialValueInPercentage={(inCashAmount * 100) / initialAmount}
            onValueChangedCallback={handleInCashComissionChange}
          />
          <p>{`${formatNumber(cashCommission, '$ 0,0.00')}`}</p>
        </div>
        <div className="flex items-center mt-7 mb-4">
          <p>Pagamento à prazo (com juros)</p>
          <p>
            : {numberOfInstallments} x {formatNumber(valueOfInstallments, '$ 0,0.00')}
          </p>
          <Tooltip
            className="ml-2"
            content={
              'Você receberá o valor à prazo com 50% de juros simples incidido. Por exemplo, se você escolheu receber R$ 500 à prazo, o valor pago a você será de R$ 750.'
            }
          />
        </div>
        <p>
          <strong>Total à prazo </strong>(com juros):
          <strong> {formatNumber(inInstallmentsAmountUpdate, '$ 0,0.00')}</strong>
        </p>
        <div className="flex items-center mt-7">
          <p>
            <strong>
              {`Valor total ${labelTextWithOrWithoutInterest}:`}{' '}
              {formatNumber(totalInstallmentWithInterest, '$ 0,0.00')}
            </strong>
          </p>
          <Tag
            className="ml-3"
            kind={'default'}
            label={`+ ${formatNumber(
              calculateInInstallmentsAmountUpdate(initialAmount, cashCommission, totalInstallmentWithInterest),
              '$ 0,0.00',
            )}`}
            color={'green'}
          />
        </div>
      </div>
      {wasUpdated && (
        <div className="flex w-full items-center justify-end mt-8">
          <Button
            label="Salvar"
            loading={loading}
            onClick={() => handleUpdateCommercialDealerComission(proposalId, commercialDealerComissionData)}
          />
        </div>
      )}
      {commercialDealerComissionFeedbackNotificationContent && (
        <div className="fixed bottom-0 right-0 my-6 mx-6 max-w-full lg:w-fit">
          <FeedbackNotification {...commercialDealerComissionFeedbackNotificationContent} />
        </div>
      )}
    </div>
  );
};
export default CommercialDealerComissionTrading;
