import React from 'react';
import { twMerge } from 'tailwind-merge';
import checkIcon from '@assets/newProposal/check-badge.svg';
import clockIcon from '@assets/newProposal/clock.svg';
import './styles.css'
export interface IStep {
  description: string;
  stepDays: number;
  legend?: string;
  date?: string
}

interface IStepTimelineProps {
  step: IStep;
  index: number;
  isLastIndex: boolean;
}

const StepTimeline: React.FC<IStepTimelineProps> = ({ step, index, isLastIndex }) => {
  const imageSelected = index === 0 ? checkIcon : clockIcon;

  return (
    <tr className={twMerge('timeline-tr-styles', !isLastIndex && 'border-b-1')}>
      <td className="xl:col-span-1 mx-auto">
        <img className="w-7 xl:w-6" src={imageSelected} alt="Icon" />
      </td>
      <td className="timeline-description-container-styles">
        <p className="step-description-styles">{step.description}</p>
        {step.legend && <p className="step-legend-styles">{step.legend}</p>}
      </td>
      <td className="step-date-styles">{step.date}</td>
    </tr>
  );
};

export default React.memo(StepTimeline);