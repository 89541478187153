import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import { HeroIconSolidOrOutline } from '@components/atoms/form/hero-icon';

export function DropdownSection({
  title,
  children,
  className,
  iconColor,
  iconName,
  fixedOpen,
}: {
  title: string;
  children: React.ReactNode;
  className?: string;
  iconColor?: string;
  fixedOpen?: boolean;
  iconName?:
    | 'CalculatorIcon'
    | 'CashIcon'
    | 'ChartBarIcon'
    | 'CurrencyDollarIcon'
    | 'ExclamationCircleIcon'
    | 'LightningBoltIcon'
    | 'OfficeBuildingIcon'
    | 'PresentationChartBarIcon'
    | 'PresentationChartLineIcon'
    | 'TableIcon'
    | 'TrendingDownIcon'
    | 'TrendingUpIcon';
}) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={twMerge(!className && 'border-2 p-2 flex flex-col bg-slate-50')}>
      <button
        onClick={() => !fixedOpen && setIsOpen(!isOpen)}
        className={twMerge(
          className,
          'flex gap-4 w-full text-left py-2 px-4 rounded-md focus:outline-none bg-gray-200 hover:bg-gray-300 ',
        )}
      >
        {iconName ? (
          <HeroIconSolidOrOutline
            useSolid={false}
            className={twMerge('w-6 h-6 ', iconColor || 'text-gray-500')}
            icon={iconName}
          />
        ) : (
          <HeroIconSolidOrOutline
            className="w-6 h-6 text-primary-70"
            useSolid={false}
            icon={isOpen ? 'FolderOpenIcon' : 'FolderIcon'}
          />
        )}
        {title}
      </button>
      {isOpen && <div className="p-6">{children}</div>}
    </div>
  );
}
