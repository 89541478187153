import clarkeLogo from '@assets/images/clarke-new-logo.png';

const GreenBoxWithLogo = () => {
  return (
    <div className="py-8 bg-primary-60 cols-span-1 flex justify-center items-center rounded-large lg:h-full lg:py-0">
      <img className="w-2/3" src={clarkeLogo} alt="clarke-logo" />
    </div>
  );
};

const BackCover = () => {
  return (
    <div className="grid grid-cols-1 gap-6 w-full h-full p-6 lg:grid-cols-2 xl:h-screen relative">
      <GreenBoxWithLogo />
      <div className="flex flex-col justify-evenly items-center">
        <div className="flex flex-col gap-8 justify-center items-center">
          <h1 className="font-bold text-6xl">Fale conosco!</h1>
          <h2 className="font-normal text-4xl">
            <strong>E-mail:</strong> <span>vendas@clarke.com.br</span>
          </h2>
          <h2 className="font-normal text-4xl">
            <strong>Telefone:</strong> <span>+55 11 934 090 834</span>
          </h2>
        </div>
        <div className="font-normal flex gap-4 absolute bottom-16 text-sm">
          <strong>Clarke Energia </strong>
          <span>35.472.171/0001-06 | Rua dos Pinheiros, 610, Sala 41 | São Paulo/SP</span>
        </div>
      </div>
    </div>
  );
};

export default BackCover;
