import { Dispatch, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { ButtonIcon } from '@clarke-energia/foton';
import moment from 'moment';
import useWhiteLabel from '@hooks/use-white-label';

interface SuppliersProjectionsHistoryTableButtonsColumnProps {
  suppliersProjection: any;
  setRenderSuppliersProjections: Dispatch<React.SetStateAction<boolean>>;
  renderSuppliersProjections: boolean;
}

export function SuppliersProjectionsHistoryTableButtonsColumn({
  suppliersProjection,
  renderSuppliersProjections,
  setRenderSuppliersProjections,
}: SuppliersProjectionsHistoryTableButtonsColumnProps) {
  const { deleteWhiteLabelSupplier } = useWhiteLabel();

  const disable = useCallback((suppliersProjection: any): boolean => {
    return moment(suppliersProjection.expirationDate, 'YYYY/MM/DD hh:mm').isBefore(moment());
  }, []);

  const handleDelete = async () => {
    const hasDeleted = await deleteWhiteLabelSupplier(suppliersProjection.id);
    if (hasDeleted) {
      setRenderSuppliersProjections(!renderSuppliersProjections);
    } else {
      alert('Erro ao deletar fornecedor');
    }
  };
  const navigate = useNavigate();
  return (
    <td className="col-sapn-1 flex justify-center gap-5 min-h-full my-2">
      <ButtonIcon
        kind="ghost"
        icon="PencilSquareIcon"
        onClick={() => navigate(`./editar/${suppliersProjection.id}`)}
        disabled={disable(suppliersProjection)}
        className={twMerge(
          disable(suppliersProjection) ? 'text-neutral-20' : 'hover:bg-neutral-10',
          'h-fit self-center',
        )}
      />
      <ButtonIcon
        kind="ghost"
        icon="TrashIcon"
        onClick={handleDelete}
        className="hover:bg-neutral-10 h-fit self-center"
      />
    </td>
  );
}
