import { IconProps } from '@clarke-energia/foton';
import ClarkeIcon from '@assets/images/Clarke Icon.svg';
import useWhiteLabel from '@hooks/use-white-label';

export interface ICardContent {
  icon: IconProps['icon'];
  imageSvg: string;
  label: string;
  strong: string;
  label2: string;
}

export interface ICardItems {
  title: string;
  iconTitle: IconProps['icon'];
  imageSvg: string;
  cardContent: ICardContent[];
}

export const ProposalCards = () => {
  const { isWhiteLabelProposal } = useWhiteLabel();
  const simplifiedProposalCardsData: ICardItems[] = [
    {
      title: 'Condições Comerciais',
      iconTitle: 'BriefcaseIcon',
      imageSvg: '',
      cardContent: [
        {
          icon: 'BuildingOfficeIcon',
          imageSvg: '',
          label: '',
          strong: 'Fornecedor de energia seguro',
          label2: ' com + R$ 100 milhões de patrimônio líquido',
        },
        {
          icon: 'LightBulbIcon',
          imageSvg: '',
          label: 'Modalidade',
          strong: ' varejista',
          label2: '',
        },
        {
          icon: 'PresentationChartBarIcon',
          imageSvg: '',
          label: '',
          strong: 'Flexibilidade +/- 100%',
          label2: '',
        },
        {
          icon: 'DocumentIcon',
          imageSvg: '',
          label: '',
          strong: 'Cobertura total de encargos',
          label2: ' pelo contrato de energia',
        },
        {
          icon: 'ShieldCheckIcon',
          imageSvg: '',
          label: '',
          strong: 'Sem garantia',
          label2: '',
        },
      ],
    },
    {
      title: 'Conheça a Clarke',
      iconTitle: 'BuildingOfficeIcon',
      imageSvg: isWhiteLabelProposal ? '' : ClarkeIcon,
      cardContent: [
        {
          icon: 'BuildingOffice2Icon',
          imageSvg: '',
          label: '',
          strong: 'Maior empresa de tecnologia',
          label2: ' do mercado livre de energia',
        },
        {
          icon: 'BoltIcon',
          imageSvg: '',
          label: '',
          strong: 'Gestora Independente',
          label2: ' de Energia',
        },
        {
          icon: 'ComputerDesktopIcon',
          imageSvg: '',
          label: '',
          strong: 'Área do cliente',
          label2: ' simples e intuitiva',
        },
        {
          icon: 'CurrencyDollarIcon',
          imageSvg: '',
          label: '',
          strong: 'Menor custo',
          label2: ' de gestão do Brasil',
        },
        {
          icon: 'DocumentTextIcon',
          imageSvg: '',
          label: '',
          strong: 'Sem rescisão',
          label2: ' no contrato de gestão',
        },
      ],
    },
    {
      title: 'Mercado Livre de energia',
      iconTitle: 'BoltIcon',
      imageSvg: '',
      cardContent: [
        {
          icon: 'FlagIcon',
          imageSvg: '',
          label: '',
          strong: 'Proteção contra as bandeiras tarifárias',
          label2: '',
        },
        {
          icon: 'BanknotesIcon',
          imageSvg: '',
          label: '',
          strong: 'Previsibilidade orçamentária',
          label2: '',
        },
        {
          icon: 'ClockIcon',
          imageSvg: '',
          label: '',
          strong: 'Mesmo preço de energia',
          label2: ' em qualquer horário do dia',
        },
        {
          icon: 'GlobeAmericasIcon',
          imageSvg: '',
          label: ' Consumindo energia',
          strong: ' 100% renovável',
          label2: '',
        },
      ],
    },
  ];

  return simplifiedProposalCardsData;
};
