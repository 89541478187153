import React from 'react';

export interface ResponsiveCarouselItemProps {
  breakpoint: number;
  itemsToShow?: number;
  itemsToSlide?: number;
  itemsWidth?: number;
  gap?: number;
  dots?: boolean;
  mode?: string;
  arrows?: boolean;
}
export interface CarouselProps extends React.ComponentProps<'div'> {
  itemsToShow?: number;
  itemsToSlide?: number;
  itemsWidth?: number;
  gap?: number;
  paddingInline?: number;
  children: React.ReactNode[];
  dots?: boolean;
  mode?: string;
  arrows?: boolean;
  responsive?: ResponsiveCarouselItemProps[];
}

export interface UseCarouselProps {
  setActive: React.Dispatch<React.SetStateAction<number>>;
  nextItem: () => void;
  previousItem: () => void;
  component?: JSX.Element;
  renderDots: () => JSX.Element;
}

interface CarouselComponentProps {
  onDragStartMouse: (event: MouseEvent) => void;
  onMouseMove: (event: MouseEvent) => void;
  onDragEndMouse: (event: MouseEvent) => void;
  onDragEndTouch: (event: MouseEvent) => void;
  onDragStartTouch: (event: TouchEvent) => void;
  onTouchMove: (event: TouchEvent) => void;
  translateSpace: number;
  widthItem: number;
  paddingInline: number;
  gap: number;
  children: React.ReactNode[];
}

const CarouselComponent: React.FC<CarouselComponentProps> = ({
  onDragStartMouse,
  onMouseMove,
  onDragStartTouch,
  onDragEndTouch,
  onDragEndMouse,
  onTouchMove,
  translateSpace,
  widthItem,
  gap,
  paddingInline,
  children,
}: CarouselComponentProps) => {
  return (
    <div className="relative">
      <div
        className="flex overflow-hidden justify-start items-start py-4 w-full select-none"
        // @ts-expect-error - react <> html props
        onMouseDown={onDragStartMouse}
        // @ts-expect-error - react <> html props
        onMouseMove={onMouseMove}
        // @ts-expect-error - react <> html props
        onMouseUp={onDragEndMouse}
        // @ts-expect-error - react <> html props
        onTouchStart={onDragStartTouch}
        // @ts-expect-error - react <> html props
        onTouchMove={onTouchMove}
        // @ts-expect-error - react <> html props
        onTouchEnd={onDragEndTouch}
      >
        <div
          style={{
            transform: `translateX(${translateSpace * -1}px)`,
            gap: `${gap}px`,
            paddingInline: `${paddingInline}px`,
          }}
          className="flex transition-transform ease-linear w-fit duration-[0.50]"
        >
          {children.map((item, id) => (
            <div key={id} style={{ width: `${widthItem}px` }}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

function Carousel({
  itemsToShow = 1,
  itemsToSlide = 1,
  gap = 10,
  itemsWidth = 100,
  mode = 'normal',
  paddingInline = 0,
  children,
}: CarouselProps): UseCarouselProps {
  const ref = React.useRef<HTMLInputElement>(null);

  const [containerWidth, setContainerWidth] = React.useState(0);
  const [active, setActive] = React.useState(0);
  const [dragStartX, setDragStartX] = React.useState(0);
  const [dragged, setDragged] = React.useState(false);
  const [leftDrag, setLeftDrag] = React.useState(0);
  const [widthItem, setWidthItem] = React.useState(0);
  const [direction, setDirection] = React.useState('');
  const [translateSpace, setTranslateSpace] = React.useState((widthItem + gap) * active);
  const [saveTranslateSpace, setSaveTranslateSpace] = React.useState(0);
  const [slidesListWidth, setSlidesListWidth] = React.useState(0);

  const shouldNavigatePrevious = (): boolean => active > 0;
  const shouldNavigateNext = (): boolean => {
    const endOfItemsPosition = slidesListWidth - translateSpace;
    const lastItemIsFullyVisible = endOfItemsPosition > containerWidth + widthItem;
    return lastItemIsFullyVisible;
  };

  const resizeWidth = (): void => setContainerWidth(ref.current ? ref.current.offsetWidth : 0);

  const previousItem = (): void => {
    if (shouldNavigatePrevious()) {
      setDirection('previous');
      setActive(active - itemsToSlide);
    }
  };

  const nextItem = (): void => {
    const totalItems = Math.ceil(children?.length / itemsToSlide);
    if (shouldNavigateNext() && active < totalItems - 1) {
      setDirection('next');
      setActive(active + itemsToSlide);
    }
  };

  const onMouseMove = (event: MouseEvent): void => {
    if (dragged) {
      const left = event.clientX - dragStartX;
      setTranslateSpace(saveTranslateSpace + left * -1);
      setLeftDrag(left);
    }
  };
  const onTouchMove = (event: TouchEvent): void => {
    if (dragged) {
      const touch = event.targetTouches[0];
      const left = touch.clientX - dragStartX;
      setTranslateSpace(saveTranslateSpace + left * -1);
      setLeftDrag(left);
    }
  };

  const onDragStart = (clientX: number): void => {
    setDragged(true);
    setDragStartX(clientX);
  };
  const onDragStartTouch = (event: TouchEvent): void => {
    const touch = event.targetTouches[0];
    setSaveTranslateSpace(translateSpace);
    onDragStart(touch.clientX);
  };
  const onDragStartMouse = (event: MouseEvent): void => {
    setSaveTranslateSpace(translateSpace);
    onDragStart(event.clientX);
  };

  const getNewActiveFromDrag = (active: number, leftDrag: number, widthItem: number): number => {
    const newActive = active + Math.round((leftDrag * -1) / widthItem);
    if (newActive < 0) return 0;
    else if (children && newActive > children.length - itemsToShow) return active;
    return newActive;
  };

  const onDragEnd = (): void => {
    if (dragged) {
      setDragged(false);
      setActive(getNewActiveFromDrag(active, leftDrag, widthItem));
    }
  };

  const onDragEndMouse = (): void => {
    window.removeEventListener('mousemove', onMouseMove);
    onDragEnd();
  };

  const onDragEndTouch = (): void => {
    window.removeEventListener('touchmove', onTouchMove);
    onDragEnd();
  };

  const getClassName = (i: number) => {
    let className = `h-4 w-4 bg-gray-300 rounded-full cursor-pointer`;
    if (i === active / itemsToSlide) {
      className += ' bg-gray-500';
    }
    return className;
  };

  const renderDots = () => {
    const dots = [];
    if (children) {
      const totalItems = Math.ceil(children.length / itemsToSlide);
      for (let i = 0; i < totalItems; i++) {
        dots.push(<span key={i} className={getClassName(i)} onClick={() => setActive(i * itemsToSlide)} />);
      }
    }

    return <div className="flex items-center gap-2">{dots}</div>;
  };

  React.useEffect(() => {
    window.addEventListener('resize', resizeWidth);
    window.removeEventListener('resize', resizeWidth);
    resizeWidth();
  }, []);

  React.useEffect(() => {
    if (children) {
      const itemsOverflowAtTheEnd = slidesListWidth - translateSpace - (widthItem + gap) > containerWidth;
      const shouldShowLastItemToTheEnd = mode !== 'normal' && direction === 'next' && !itemsOverflowAtTheEnd;
      if (shouldShowLastItemToTheEnd) {
        setTranslateSpace(children.length * (widthItem + gap) - gap - containerWidth - 40);
      } else if (mode === 'center' && active !== 0) {
        const nbOfItems = Math.floor(containerWidth / (widthItem + gap));
        const shiftToCenter = (containerWidth - nbOfItems * (widthItem + gap)) / 2 + gap / 2;
        setTranslateSpace(active * (widthItem + gap) - shiftToCenter);
      } else {
        setTranslateSpace(active * (widthItem + gap));
      }
    }
  }, [active, dragged]);

  React.useEffect(() => {
    setActive(0);
    setWidthItem(itemsWidth);
  }, [mode, itemsWidth, gap]);

  React.useEffect(() => {
    children && setSlidesListWidth(children.length * (widthItem + gap) - gap);
  }, [widthItem, gap]);

  return {
    setActive,
    nextItem,
    previousItem,
    renderDots,
    component: children && (
      <CarouselComponent
        onDragEndMouse={onDragEndMouse}
        onDragEndTouch={onDragEndTouch}
        onDragStartMouse={onDragStartMouse}
        onDragStartTouch={onDragStartTouch}
        onMouseMove={onMouseMove}
        onTouchMove={onTouchMove}
        gap={gap}
        paddingInline={paddingInline}
        translateSpace={translateSpace}
        widthItem={widthItem}
      >
        {children}
      </CarouselComponent>
    ),
  };
}
export default Carousel;
