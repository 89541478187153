import React from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from '@components/molecules/general/sidebar';

const ScreenLayout: React.FC = () => {
  return (
    <div className="flex flex-row h-screen">
      <div className="h-full">
        <Sidebar />
      </div>
      <div className="h-full w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default ScreenLayout;
