import tradersLogo, { tradersFixedData } from '@utils/traders';
import { isArrayEmpty } from '@utils/common';

import {
  IProductTwoSimulationExtendedResult,
  IProductTwoProposal,
  IProductTwoSimulationResultSummary,
  IProductTwoSimulationResultSummaryWithFixedTraderData,
} from '@contexts/product-two/types';

import Cover from '@components/molecules/general/simulation/cover';
import GreenSectionSlide from '@components/molecules/general/simulation/green-section-slide';
import { TradersResultsEconomyComparison } from '@components/molecules/product-two/simulation/economy-scenario-by-trader-proposal';
import TradersDifferentials from '@components/molecules/product-two/simulation/trader-differentials';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import ProductEconomySlide from '@components/molecules/general/simulation/economy-slide';

interface IProductTwoSimulationGroupEconomySection {
  proposal: IProductTwoProposal;
  simulationScenarios: IProductTwoSimulationExtendedResult;
  leadName?: string;
}

const ProductTwoSimulationGroupEconomySection = ({
  proposal,
  simulationScenarios,
  leadName,
}: IProductTwoSimulationGroupEconomySection) => {
  const tradersResults: Array<IProductTwoSimulationResultSummary> =
    proposal.calculatorMetadata.default.retailTradersResult;

  const tradersResultsWithTradersFixedData: Array<IProductTwoSimulationResultSummaryWithFixedTraderData> = [];
  tradersResults?.map((traderResult) => {
    const traderFixedData = tradersFixedData.find((trader) => {
      return trader.id === traderResult.retailTraderId;
    });
    tradersResultsWithTradersFixedData.push({
      ...traderResult,
      traderLogo: tradersLogo[traderResult.retailTraderId],
      cceeDuty: (traderFixedData && traderFixedData.cceeDuty) || '-',
      paymentTerms: (traderFixedData && traderFixedData.paymentTerms) || '-',
      penalty: (traderFixedData && traderFixedData.penalty) || '-',
      IREC: (traderFixedData && traderFixedData.IREC) || '-',
    });
  });

  return (
    <>
      <SlideContainer>
        <Cover companyName={leadName} />
      </SlideContainer>
      <SlideContainer>
        <GreenSectionSlide title="Proposta de economia" />
      </SlideContainer>
      {!isArrayEmpty(tradersResults) && (
        <>
          {tradersResults.length > 1 && (
            <SlideContainer>
              <TradersResultsEconomyComparison tradersResults={tradersResults} />
            </SlideContainer>
          )}
          <SlideContainer>
            <TradersDifferentials tradersResultsWithTradersFixedData={tradersResultsWithTradersFixedData} />
          </SlideContainer>
        </>
      )}
      <SlideContainer>
        <ProductEconomySlide
          showSideData={simulationScenarios.units && simulationScenarios.units.length === 1}
          simulationResult={simulationScenarios}
          proposalValidity={proposal?.inputs?.proposalValidity}
        />
      </SlideContainer>
    </>
  );
};

export default ProductTwoSimulationGroupEconomySection;
