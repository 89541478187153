import { get } from 'lodash';
import { FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import Select, {
  GroupBase,
  InputProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  components,
  PlaceholderProps,
} from 'react-select';
import { HeroIcon } from '@clarke-energia/foton';

function Input<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: InputProps<Option, IsMulti, Group>,
) {
  return <components.Input {...props} inputClassName="focus:ring-transparent" />;
}

function MultiValueContainer<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueGenericProps<Option, IsMulti, Group>) {
  return (
    <components.MultiValueContainer
      {...props}
      innerProps={{
        ...props.innerProps,
      }}
    />
  );
}

function MultiValueLabel<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: MultiValueGenericProps<Option, IsMulti, Group>,
) {
  return <components.MultiValueLabel {...props} innerProps={{ ...props.innerProps, className: 'text-white p-px' }} />;
}

function Placeholder<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: PlaceholderProps<Option, IsMulti, Group>,
) {
  return <components.Placeholder {...props} innerProps={{ ...props.innerProps, className: 'text-paragraph-medium' }} />;
}

function MultiValueRemove<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>,
) {
  return (
    <components.MultiValueRemove {...props} innerProps={{ ...props.innerProps, className: 'text-white' }}>
      <HeroIcon icon="XCircleIcon" extraClassNames="w-5 h-5" />
    </components.MultiValueRemove>
  );
}

export interface MultiSelectFormFieldProps<T extends FieldValues> {
  label: string;
  id: string;
  inputOptions: { label: string; value: string }[];
  placeholder?: string;
  options?: RegisterOptions<T>;
  isLoading?: boolean | undefined;
  onInputChange?: (inputValue: string) => void;
  onMenuScrollToBottom?: () => void;
}
export function MultiSelectFormField<T extends FieldValues>({
  label,
  id,
  inputOptions,
  onInputChange,
  placeholder,
  options,
  isLoading,
  onMenuScrollToBottom,
}: MultiSelectFormFieldProps<T>) {
  const {
    formState: { errors },
  } = useFormContext<T>();
  const fieldErrors = get(errors, id as FieldPath<T>);
  return (
    <div className="overflow-visible flex flex-col gap-2 w-1/2">
      <label className="text-paragraph-medium">
        {label}
        {options?.required && <span className="text-danger-60">*</span>}
      </label>
      <Select<{ label: string; value: string }, true>
        options={inputOptions}
        isMulti={true}
        data-cy={id}
        inputId={id}
        isLoading={isLoading}
        onBlur={options?.onBlur}
        className="z-50 rounded-small text-paragraph-medium min-w-full"
        components={{ Input, MultiValueContainer, MultiValueLabel, MultiValueRemove, Placeholder }}
        placeholder={placeholder}
        name={id}
        onInputChange={(inputValue) => onInputChange && onInputChange(inputValue)}
        onChange={(newValue) => {
          options?.onChange && options?.onChange(newValue.map((selected) => selected.value));
        }}
        required={true}
        menuPortalTarget={document.body}
        onMenuScrollToBottom={onMenuScrollToBottom}
        styles={{
          valueContainer: (base, state) => ({
            ...base,
            overflow: `${state.hasValue ? 'auto' : 'hidden'}`,
            maxHeight: '1.5rem',
            height: '1.5rem',
            minHeight: '1.5rem',
            marginBottom: '0.5rem',
          }),
          multiValueLabel: (base) => ({
            ...base,
            padding: 'None',
            margin: 'None',
          }),
          multiValue: (base) => ({
            ...base,
            padding: 'None',
            magin: 'None',
          }),
          control: (base) => ({
            ...base,
            '&:hover': { borderColor: fieldErrors?.message ? 'red' : '#C5C5C5' },
            border: `1px solid ${fieldErrors?.message ? 'red' : ' #C5C5C5'}`,
            boxShadow: 'none',
            maxHeight: 32,
            height: 32,
            minHeight: 32,
          }),
          menuPortal: (defaultStyles) => ({
            ...defaultStyles,
            zIndex: 9999,
          }),
        }}
      />
    </div>
  );
}
