import { TableHeader } from './tableHeader';
import { TableBody } from './tableBody';

interface IDataTableProps {
  headers: string[];
  rows: {
    row: (string | number)[];
    tagStyle: string;
  }[];
}

export function TableWithMultiplesValues({ headers, rows }: IDataTableProps) {
  const hasOverflow = rows.some((row) => row.row.length > 9);
  return (
    <div className={`${hasOverflow ? 'overflow-x-scroll' : ''}`}>
      <table className={`min-w-full border border-gray-200 -mt-30 `}>
        <TableHeader headers={headers} />
        <TableBody rows={rows} />
      </table>
    </div>
  );
}
