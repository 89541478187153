import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useForm, FormProvider } from 'react-hook-form';
import { FeedbackNotification } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { IProposalUser, ISimulationUnitPayloadBase } from '@contexts/types';
import { IProductOneSimulatorInputs } from '@contexts/product-one/types';
import {
  enforceUnitsManagementPriceSuggestion,
  parseUnitsConsumptionHistory,
} from '@contexts/calculator/units-payload-handlers';

import useCustomClipboard from '@hooks/use-custom-clipboard';
import useProductOne from '@hooks/use-product-one';

import { getCalculatorName, getProductType, isObjectEmpty, parseStringToBoolean } from '@utils/common';
import { addWorkdays, startDate } from '@utils/datetime';

import {
  compareAndGetProposalValidity,
  getSimulatorInitialValues,
} from '@components/molecules/form/simulator/default-values-getters';
import ProductOneSimulatorFormCore from '@components/organisms/product-one/simulator/product-one-simulator-form-core';
import { CalculatorFormFooter } from '@components/molecules/calculator-form-footer';
import SimulatorLayoutBase from '@components/organisms/simulator-layout-base';
import useWhiteLabel from '@hooks/use-white-label';

interface IProductOneSimulatorForm {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

const ProductOneSimulatorForm = ({
  setOpenModal,
  setSuccessModal,
  isLoading,
}: IProductOneSimulatorForm): JSX.Element => {
  const { CopyFormValues, PasteFormValues, customClipboardFeedbackNotificationContent } = useCustomClipboard();
  const { createProductOneSimulation, createProductOneProposal } = useProductOne();

  const formRef = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const formController = useForm<IProductOneSimulatorInputs>({
    defaultValues: getSimulatorInitialValues('CLARKE_MANAGEMENT') as IProductOneSimulatorInputs,
    mode: 'all',
  });
  const {
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = formController;
  const formValues = watch();
  const { whiteLabelSupplier } = useWhiteLabel();
  const { user } = useAuth();
  const authorInfo =
    user &&
    ({
      id: user.id,
      name: user.name,
      pictureUrl: user.pictureUrl,
    } as IProposalUser);

  const onSubmit = async (values: IProductOneSimulatorInputs) => {
    setLoading(true);
    const productType = getProductType(values.units, values.productType);
    const parsedUnits = parseUnitsConsumptionHistory(values.units) as ISimulationUnitPayloadBase[];
    const unitsWithEnforcedManagementPriceSuggestion = enforceUnitsManagementPriceSuggestion(
      parsedUnits,
    ) as ISimulationUnitPayloadBase[];

    const sellerValue = values.seller?.id === undefined ? authorInfo : values.seller;

    const finalValues: IProductOneSimulatorInputs = {
      ...values,
      units: user?.isCommercialDealer ? unitsWithEnforcedManagementPriceSuggestion : parsedUnits,
      proposalValidity: compareAndGetProposalValidity(values.proposalValidity),
      productType,
      author: authorInfo,
      seller: sellerValue,
      manualAclPricesHasChargesCoverage:
        values.manualAclPricesHasChargesCoverage &&
        parseStringToBoolean(values.manualAclPricesHasChargesCoverage as unknown as string),
      whiteLabelSupplierId: user?.isWhiteLabel ? whiteLabelSupplier?.id : undefined,
      serviceProposalPricesDiscountPercentage:
        user?.isWhiteLabel && whiteLabelSupplier.prices?.discount
          ? whiteLabelSupplier.prices?.discount / 100
          : undefined,
    };

    CopyFormValues(finalValues, false, false);

    try {
      const response = await createProductOneSimulation(finalValues);
      const { data: dataEx } = await createProductOneProposal(response);
      if (dataEx) {
        setOpenModal(true);
        setSuccessModal(true);
        await eventTracker.trackEvent(eventLabels.FORM_SUBMITTED, {
          form: getCalculatorName(location.href),
          user_groups: user?.groups ? user?.groups.join(', ') : '',
          user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
        });
      }
    } catch {
      alert('Ocorreu um erro. Verifique o formulário e tente novamente.');
    }
  };

  React.useEffect(() => {
    if (location.href.includes('refazer-calculo')) {
      PasteFormValues(reset, 'CLARKE_MANAGEMENT', false);
      setValue('proposalValidity', addWorkdays(startDate(), 1).format('DD/MM/YYYY'));
    } else {
      eventTracker.trackEvent(eventLabels.PAGE_VIEW_SIMULATOR, {
        product: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
      eventTracker.trackEvent(eventLabels.FORM_STARTED, {
        form: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
    }
  }, []);
  return (
    <SimulatorLayoutBase<IProductOneSimulatorInputs>
      title="Simulador"
      simulatorType="CLARKE_MANAGEMENT"
      simulatorFlow="SIMULATOR_FORM"
      setFormValues={reset}
      formValues={formValues}
    >
      {isLoading ? (
        <div className="grid m-5 table-auto">
          <Skeleton count={10} height={20} />
        </div>
      ) : (
        <div className="col-span-full flex flex-col w-full lg:w-3/4 p-7">
          <FormProvider {...formController}>
            {customClipboardFeedbackNotificationContent && (
              <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
                <FeedbackNotification {...customClipboardFeedbackNotificationContent} />
              </div>
            )}
            <ProductOneSimulatorFormCore {...{ formRef, onSubmit, formController }} />
            <CalculatorFormFooter
              error={!isObjectEmpty(errors)}
              trigger={trigger}
              {...{ formRef, loading, onSubmit }}
            />
          </FormProvider>
        </div>
      )}
    </SimulatorLayoutBase>
  );
};

export default ProductOneSimulatorForm;
