import React from 'react';
import { twMerge } from 'tailwind-merge';
import { HeroIcon, IconProps } from '@clarke-energia/foton';
import useWhiteLabel from '@hooks/use-white-label';

interface ICardHeader {
  title: string;
  icon: IconProps['icon'];
  imageSvg: string;
  isPremisesCard?: boolean;
}

const CardHeader: React.FC<ICardHeader> = ({ title, icon, imageSvg, isPremisesCard }: ICardHeader) => {
  const { isWhiteLabelProposal } = useWhiteLabel();
  const titleClassFixed = isPremisesCard && 'xl:text-heading-small';
  const titleClassDynamic = 'text-heading-xsmall font-bold ml-6';

  const containerClassFixed = 'flex items-center mb-6';
  const containerClassDynamic = isPremisesCard ? ' md:mb-9' : 'xl:mb-9';

  const iconClassFixed = 'h-8 w-8 xl:h-[3.75rem] xl:w-[3.75rem] p-4 xl:p-5 rounded-full';

  return (
    <div className={twMerge(containerClassFixed, containerClassDynamic)}>
      <div>
        {imageSvg !== '' ? (
          <img
            className={twMerge(
              iconClassFixed,
              isWhiteLabelProposal ? 'bg-white-label-brighter text-white-label-main' : 'bg-primary-10 text-primary-70',
            )}
            src={imageSvg}
            alt="icon-img"
          />
        ) : (
          icon && (
            <HeroIcon
              icon={icon}
              className={twMerge(
                iconClassFixed,
                isWhiteLabelProposal
                  ? 'bg-white-label-brighter text-white-label-main'
                  : 'bg-primary-10 text-primary-70',
              )}
            />
          )
        )}
      </div>
      <div className={twMerge(titleClassFixed, titleClassDynamic)}>{title}</div>
    </div>
  );
};

export default CardHeader;
