import Logo2W from '../assets/tradersLogos/658f2c26-8607-4f67-a588-e562a4ad0e66.png';
import LogoAmerica from '../assets/tradersLogos/edc132c7-5198-4c7c-af45-dc0df8298cc8.png';
import LogoBTG from '../assets/tradersLogos/4368e2c6-a3b0-483e-97c5-e3966f6a07e7.png';
import LogoCapitale from '../assets/tradersLogos/6085ac3e-c5d5-48b2-9ed7-7f56491db535.png';
import LogoComerc from '../assets/tradersLogos/6748b17b-0d09-4c3f-8127-cc4698460e0f.png';
import LogoEDP from '../assets/tradersLogos/0f6501e0-dc27-42c2-b83a-0074315c1a13.png';
import LogoEnergizou from '../assets/tradersLogos/7a8bc53b-4951-41e3-9cdc-f098efd32287.png';
import LogoEsfera from '../assets/tradersLogos/81b3fbdf-b33e-4cc2-8987-9988e48fd90e.png';
import LogoMatrix from '../assets/tradersLogos/00e51ea2-ff23-4122-8fcb-b428b0189050.png';
import LogoParaty from '../assets/tradersLogos/a81d315d-a0f8-4bff-bd3e-3e1b26f6f63f.png';
import LogoSkopos from '../assets/tradersLogos/c0bb9e29-b6ae-4451-a0d0-e0e7141425ad.png';
import LogoAuren from '../assets/tradersLogos/b3ca1211-a974-4c48-906b-e913ec0f890d.png';
import LogoEnergisa from '../assets/tradersLogos/61a5868c-882b-4f3f-bd9f-3c41b369f2c3.png';

const tradersLogoDictionary: Record<string, string> = {
  '658f2c26-8607-4f67-a588-e562a4ad0e66': Logo2W,
  '00e51ea2-ff23-4122-8fcb-b428b0189050': LogoMatrix,
  '0f6501e0-dc27-42c2-b83a-0074315c1a13': LogoEDP,
  '81b3fbdf-b33e-4cc2-8987-9988e48fd90e': LogoEsfera,
  'c0bb9e29-b6ae-4451-a0d0-e0e7141425ad': LogoSkopos,
  '6748b17b-0d09-4c3f-8127-cc4698460e0f': LogoComerc,
  '6085ac3e-c5d5-48b2-9ed7-7f56491db535': LogoCapitale,
  '4368e2c6-a3b0-483e-97c5-e3966f6a07e7': LogoBTG,
  '7a8bc53b-4951-41e3-9cdc-f098efd32287': LogoEnergizou,
  'a81d315d-a0f8-4bff-bd3e-3e1b26f6f63f': LogoParaty,
  'edc132c7-5198-4c7c-af45-dc0df8298cc8': LogoAmerica,
  'b3ca1211-a974-4c48-906b-e913ec0f890d': LogoAuren,
  '61a5868c-882b-4f3f-bd9f-3c41b369f2c3': LogoEnergisa,
};

export default tradersLogoDictionary;

export const tradersFixedData = [
  {
    // 2W
    id: '658f2c26-8607-4f67-a588-e562a4ad0e66',
    cceeDuty: 'Não cobre',
    paymentTerms: '7º dia útil',
    penalty: '30% do remanescente,  bilateral',
    IREC: 'Incluso',
  },
  {
    // Matrix
    id: '00e51ea2-ff23-4122-8fcb-b428b0189050',
    cceeDuty: 'Cobertura total',
    paymentTerms: '15º dia útil',
    penalty: '30% do remanescente + perdas e danos, bilateral',
    IREC: 'Não cobre',
  },
  {
    // Paraty
    id: 'a81d315d-a0f8-4bff-bd3e-3e1b26f6f63f',
    cceeDuty: 'Cobertura total',
    paymentTerms: '10º dia útil',
    penalty: '30% do remanescente + perdas e danos,  bilateral',
    IREC: 'Não cobre',
  },
  {
    // America
    id: 'edc132c7-5198-4c7c-af45-dc0df8298cc8',
    cceeDuty: 'Cobertura total',
    paymentTerms: '6º dia útil',
    penalty: '50% do remanescente, bilateral',
    IREC: 'Não cobre',
  },
  {
    // Auren
    id: 'b3ca1211-a974-4c48-906b-e913ec0f890d',
    cceeDuty: 'Cobertura total',
    paymentTerms: '20º dia útil',
    penalty: '30% do remanescente + perdas e danos,  bilateral',
    IREC: 'Incluso',
  },
  {
    // Energisa
    id: '61a5868c-882b-4f3f-bd9f-3c41b369f2c3',
    cceeDuty: 'Cobertura total',
    paymentTerms: '6º dia útil',
    penalty: '30% do remanescente + perdas e danos,  bilateral',
    IREC: 'Não cobre',
  },
];
