import { IProductTwoSimulationExtendedResult } from '@contexts/product-two/types';

import GreenSectionSlide from '@components/molecules/general/simulation/green-section-slide';
import BackCover from '@components/molecules/general/simulation-back-cover';

import AboutTeam from '@components/molecules/general/simulation/about-team';
import AboutService from '@components/molecules/general/simulation/about-service';
import ProcessFlow from '@components/molecules/general/simulation/process-flow';
import ServiceProposal from '@components/molecules/general/simulation/service-proposal';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import CompetitiveAnalysis from '@components/molecules/general/simulation/competitor-comparison';

export interface IProductTwoSimulationConclusiveSection {
  simulationScenarios?: IProductTwoSimulationExtendedResult;
  groupManagementPrice?: number;
}

const ProductTwoSimulationConclusiveSection = ({
  simulationScenarios,
  groupManagementPrice,
}: IProductTwoSimulationConclusiveSection) => {
  return (
    <>
      <SlideContainer>
        <GreenSectionSlide title="Entregas da Clarke e Benefícios do Mercado Livre" />
      </SlideContainer>
      <SlideContainer>
        <AboutTeam />
      </SlideContainer>
      <SlideContainer>
        <AboutService />
      </SlideContainer>
      <SlideContainer>
        <ProcessFlow />
      </SlideContainer>
      {simulationScenarios?.competitiveAnalysis && simulationScenarios?.competitiveAnalysis.length >= 2 && (
        <SlideContainer>
          <CompetitiveAnalysis competitiveAnalysis={simulationScenarios?.competitiveAnalysis} />
        </SlideContainer>
      )}
      {groupManagementPrice !== undefined && (
        <SlideContainer>
          <ServiceProposal
            proposalValidity={simulationScenarios?.proposalValidity}
            unitsNumber={simulationScenarios?.unitsReports?.length}
            totalMigrationCost={simulationScenarios?.totalMigrationCost}
            groupManagementPrice={groupManagementPrice}
          />
        </SlideContainer>
      )}
      <SlideContainer>
        <BackCover />
      </SlideContainer>
    </>
  );
};

export default ProductTwoSimulationConclusiveSection;
