import { useContextSelector } from 'use-context-selector';

import { UsersContext } from '@contexts/users';
import { IUsersContext } from '@contexts/users/types';

const useUsers = (): IUsersContext => {
  const getSellersUsersHandler = useContextSelector(UsersContext, (users) => users.getSellersUsersHandler);
  const sellersUsers = useContextSelector(UsersContext, (users) => users.sellersUsers);
  const isLoading = useContextSelector(UsersContext, (users) => users.isLoading);

  return {
    getSellersUsersHandler,
    sellersUsers,
    isLoading,
  };
};

export default useUsers;
