import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { IProductOneSimulatorInputs } from '@contexts/product-one/types';

import SimulatorFormGeneralSection from '@components/molecules/form/simulator/sections/simulator-form-general-section';
import SimulatorFormIRecSection from '@components/molecules/form/simulator/sections/simulator-form-i-rec-section';
import SimulatorFormUnitsSection from '@components/molecules/form/simulator/sections/simulator-form-units-section';
import SimulatorFormTargetYearsSection from '@components/molecules/form/simulator/sections/simulator-form-target-years-section';
import SimulatorFormFinancialProjectionSection from '@components/molecules/form/simulator/sections/simulator-form-financial-projection-section';
import SimulatorFormCompetitorsListSection from '@components/molecules/form/simulator/sections/simulator-form-competitors-list-section';
import SimulatorFormTotalMigrationCostSection from '@components/molecules/form/simulator/sections/simulator-form-total-migration-cost-section';
interface IProductOneSimulatorFormCore {
  formController: UseFormReturn<IProductOneSimulatorInputs, any>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onSubmit: (values: IProductOneSimulatorInputs) => void;
}

const ProductOneSimulatorFormCore = ({ formController, formRef, onSubmit }: IProductOneSimulatorFormCore) => {
  return (
    <form
      ref={formRef}
      onSubmit={formController.handleSubmit(onSubmit)}
      className="flex flex-col flex-wrap w-full gap-3 justify-start"
    >
      <SimulatorFormGeneralSection<IProductOneSimulatorInputs>
        fields={{
          productType: true,
        }}
      />
      <SimulatorFormIRecSection />
      <SimulatorFormTargetYearsSection outputAsArray />
      <SimulatorFormFinancialProjectionSection />
      <SimulatorFormCompetitorsListSection<IProductOneSimulatorInputs> simulatorType="CLARKE_MANAGEMENT" />
      <SimulatorFormUnitsSection<IProductOneSimulatorInputs> />
      {/* TODO for later implementation */}
      {/* <SimulatorFormPremisesSection simulatorType={SimulatorTypeEnum.CLARKE_MANAGEMENT} /> */}
      <SimulatorFormTotalMigrationCostSection />
    </form>
  );
};

export default ProductOneSimulatorFormCore;
