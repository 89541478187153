import React from 'react';
import { Modal } from '@clarke-energia/foton';

interface IModalDeleteProposal {
  leadId?: string;
  selectedProposalId: string | undefined;
  onClickDeleteProposal: (leadId: string, selectedProposalId: string) => Promise<void>;
  openModalDelete: boolean;
  setOpenModalDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDeleteProposal = ({
  leadId,
  selectedProposalId,
  onClickDeleteProposal,
  openModalDelete,
  setOpenModalDelete,
}: IModalDeleteProposal): JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDeleteProposalsButtonClick = () => {
    setLoading(true);
    leadId && selectedProposalId && onClickDeleteProposal(leadId, selectedProposalId).finally(() => setLoading(false));
    setOpenModalDelete(false);
  };
  return (
    <Modal
      open={openModalDelete}
      setOpen={() => setOpenModalDelete(false)}
      title="Deletar proposta"
      description="Tem certeza que deseja excluir essa propostas?"
      modalButtons={{
        primary: {
          label: 'Excluir',
          style: { backgroundColor: 'red', width: '100%' },
          onClick: handleDeleteProposalsButtonClick,
          loading,
        },
        secondary: {
          label: 'Cancelar',
          style: { width: '100%' },
          onClick: () => setOpenModalDelete(false),
          disabled: loading,
        },
      }}
    />
  );
};

export default ModalDeleteProposal;
