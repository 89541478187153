import { Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import { GenerateTablesByUnit } from '@components/atoms/general/generateTablesByUnits';
import { IProductOneUnitReport } from '@contexts/product-one/types';
import useProductTwo from '@hooks/use-product-two';
import useProductOne from '@hooks/use-product-one';
import { TARIFF } from '@utils/constants';
import { parseProposalToUnitsReportsRetail, parseProposalToUnitsReportsManagement } from './helper';
import { IUnitReport } from './types';
import { GenerateTableGroupInfo } from '@components/atoms/general/generateTableGroupInfo';
import { ISimulationResultBase } from '@contexts/types';

enum UnitTypes {
  BT = 'Baixa Tensão',
  MT = 'Média Tensão',
  ALL = 'Baixa e Média Tensão',
}

const UnitSectionByType = ({ unit, unitType }: { unit: IUnitReport[]; unitType: string }) => {
  if (unit?.length === 0) return null;
  return (
    <div className="flex flex-col relative border-2 p-5 shadow-lg">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        {unitType === 'BT' ? 'Unidades Baixa Tensão' : 'Unidades Média Tensão'}
      </h3>

      <div className="flex flex-col gap-5">
        <GenerateTablesByUnit units={unit} />
      </div>
    </div>
  );
};
export function DetailedAnalysis() {
  const { getProductTwoProposalById, proposal: productTwoProposal } = useProductTwo();
  const { getProductOneProposalById, proposal: productOneProposal } = useProductOne();

  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const isRetailPath = window.location.pathname.includes('clarke-varejo');

  const proposalData: ISimulationResultBase = isRetailPath
    ? productTwoProposal?.calculatorMetadata?.default?.bestRetailTraderResult?.calculatorMetadata
    : productOneProposal?.calculatorMetadata?.firstResult;

  const unitsReport = isRetailPath
    ? parseProposalToUnitsReportsRetail(proposalData?.unitsReports)
    : parseProposalToUnitsReportsManagement(proposalData?.unitsReports as Array<IProductOneUnitReport>);

  const { id } = useParams();
  useEffect(() => {
    isRetailPath && getProductTwoProposalById(id);
    isManagementPath && getProductOneProposalById(id as string);
  }, [id]);
  const lowVoltageUnits = unitsReport?.filter(
    (unit) => unit?.premises?.tariff === TARIFF?.B3_STANDARD || unit?.premises?.tariff === TARIFF?.B3_OPTING,
  );
  const mediumVoltageUnits = unitsReport?.filter(
    (unit) => !(unit?.premises?.tariff === TARIFF?.B3_STANDARD || unit?.premises?.tariff === TARIFF?.B3_OPTING),
  );
  const multipleUnits = lowVoltageUnits?.length > 0 && mediumVoltageUnits?.length > 0;
  const unitType = ['BT', 'MT', 'ALL'];
  const [selectedUnitType, setSelectedUnitType] = useState<UnitTypes>(UnitTypes.ALL);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedUnitType(UnitTypes[selectedValue as keyof typeof UnitTypes]);
  };
  return (
    <div className="flex flex-col p-6">
      <LegendContainer className="mb-6" fontSizeInPX={40} legend="Análise Detalhada" />
      <div className={`grid grid-cols-1 gap-x-8 ${multipleUnits && 'mb-10'}`}>
        {multipleUnits && (
          <div className="mb-6">
            <label htmlFor="unit-select" className="block mb-2 text-sm font-medium text-gray-700">
              Exibir unidades por tipo
            </label>
            <select
              id="unit-select"
              onChange={handleSelectChange}
              className="bg-primary-60 rounded-full w-60 px-5 py-4 border "
            >
              {unitType?.map((type) => (
                <option className="bg-primary-10 py-4 rounded-se-md rounded-tl-md" key={type} value={type}>
                  {UnitTypes[type as keyof typeof UnitTypes]}
                </option>
              ))}
            </select>
          </div>
        )}
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Paper>
            {
              <div className="flex flex-col relative">
                <h3 className="text-lg font-semibold mb-4 flex items-center pl-4 pt-2">Dados do Grupo</h3>
                <div className="flex flex-col gap-5">
                  <GenerateTableGroupInfo proposalData={proposalData} />
                </div>
              </div>
            }
          </Paper>
          {selectedUnitType === UnitTypes.ALL && (
            <div className=" flex flex-col gap-5">
              <UnitSectionByType unitType="BT" unit={lowVoltageUnits as IUnitReport[]} />
              <UnitSectionByType unitType="MT" unit={mediumVoltageUnits as IUnitReport[]} />
            </div>
          )}
          {selectedUnitType === UnitTypes.BT && (
            <div className="flex flex-col relative">
              <h3 className="text-lg font-semibold mb-4 flex items-center">Unidades Baixa Tensão</h3>
              <div className="flex flex-col gap-5">
                <GenerateTablesByUnit units={lowVoltageUnits} />
              </div>
            </div>
          )}
          {selectedUnitType === UnitTypes.MT && (
            <div className="flex flex-col relative">
              <h3 className="text-lg font-semibold mb-4 flex items-center">Unidades Média Tensão</h3>
              <div className="flex flex-col gap-5">
                <GenerateTablesByUnit units={mediumVoltageUnits} />
              </div>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}
