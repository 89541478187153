import { FeedbackNotificationProps } from '@clarke-energia/foton';
import config from '@src/config';
export const getCommercialDealerComissionFeedbackNotificationContents = (
  onCloseNotification: () => void,
): Record<string, FeedbackNotificationProps> => {
  return {
    CHANGE_COMMERCIAL_DEALER_COMISSION_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'A forma de pagamento da comissão foi atualizada.',
      onCloseNotification,
    },
    CHANGE_COMMERCIAL_DEALER_COMISSION_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Não foi possível alterar a forma de pagamento da comissão.',
      onCloseNotification,
    },
  };
};

export const calculateInInstallmentsAmountUpdate = (
  initialAmount: number,
  cashCommission: number,
  totalInstallmentWithInterest?: number,
) => {
  const nonCashComissionAmount = initialAmount - cashCommission;
  const inInstallmentsAmountWithInterest = nonCashComissionAmount * config.INTEREST_FACTOR_FOR_INSTALLMENTS_COMMISSION;

  if (totalInstallmentWithInterest) {
    const interestAmount = Math.round(totalInstallmentWithInterest) - Math.round(initialAmount);
    return interestAmount < 0 ? 0 : interestAmount;
  }
  return inInstallmentsAmountWithInterest < 0 ? 0 : inInstallmentsAmountWithInterest;
};
