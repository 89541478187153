import ResponsiveHeaderLine from '../responsive-header-line';

type ISlideHeaderContent = {
  title: string;
  subtitle?: string;
  hiddenHeaderLine?: boolean;
};

const SlideHeader = ({ title, subtitle, hiddenHeaderLine }: ISlideHeaderContent): JSX.Element => (
  <div className="flex flex-col gap-5 w-full justify-between p-8">
    {!hiddenHeaderLine && <ResponsiveHeaderLine color="green" />}
    <h2 className="text-3xl font-black sm:text-4xl md:text-display-small md:font-bold">{title}</h2>
    {subtitle && (
      <h1 className="text-heading-xsmall font-normal max-w-[41.75rem] sm:text-heading-small sm:font-normal">
        {subtitle}
      </h1>
    )}
  </div>
);

export default SlideHeader;
