import useWhiteLabel from '@hooks/use-white-label';
import { formatNumber } from '@utils/numeral';

enum EnergyEfficiencyAnalyzerHeaders {
  UnitConsumption = 'Unidade consumidora',
  EconomyPercent = 'Percentual de economia',
  TotalEconomy = 'Economia total',
  NetMonthlyEconomy = 'Economia líquida mensal',
}
export interface IDataUnitsSaving {
  nickname: string;
  totalSavingInPercentage: number;
  totalSaving: number;
  netMonthlyEconomy: number;
}
export interface IUnitsSaving {
  unitsSaving: Array<IDataUnitsSaving>;
}

function UnitsSavingTable({ unitsSaving }: IUnitsSaving) {
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();
  return (
    <table className="table-auto w-10/12">
      <thead>
        <tr>
          {Object.values(EnergyEfficiencyAnalyzerHeaders)?.map((header, index) => (
            <th
              key={index}
              className=" w-20 sw:32 h-16 sm:h-14 text-start text-paragraph-small sm:text-paragraph-large pr-5"
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {unitsSaving?.map((item, index) => (
          <tr className="border-t-2" key={index}>
            <td className="min-w-10 sm:min-w-20 sm:w-60 h-14 text-start text-paragraph-small sm:text-paragraph-large pr-3">
              {item.nickname}
            </td>
            <td
              style={{
                color: isWhiteLabelProposal ? whiteLabelSupplier?.colors?.main : '#00DF7C',
              }}
              className="min-w-10 sm:min-w-20 sm:w-60 h-14 text-start text-paragraph-small sm:text-paragraph-large font-bold pr-3"
            >
              {formatNumber(item.totalSavingInPercentage, '0%')}
            </td>
            <td
              style={{
                color: isWhiteLabelProposal ? whiteLabelSupplier?.colors?.main : '#00DF7C',
              }}
              className="min-w-10 sm:min-w-20 sm:w-60 h-14 text-start text-paragraph-small sm:text-paragraph-large pr-3"
            >
              {formatNumber(item.totalSaving, '$ 0,0.00')}
            </td>
            <td className="min-w-10 sm:min-w-20 sm:w-60 h-14 text-start text-paragraph-small sm:text-paragraph-large pr-3">
              {formatNumber(item.netMonthlyEconomy, '$ 0,0.00')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default UnitsSavingTable;
