import { User } from '@auth0/auth0-react';
import { UserGroup } from '@contexts/users/types';
import configuration from '@config';
import axios, { AxiosPromise } from 'axios';

class Auth0ApiService {
  auth0ApiKey = '';

  constructor(auth0ApiKey: string) {
    this.auth0ApiKey = auth0ApiKey;
  }

  auth0Api = axios.create({
    baseURL: `https://${configuration.DOMAIN}/api/v2`,
  });

  executeAuth0ApiRequest = (
    endpoint: string,
    getRequest?: boolean,
    payload?: Record<string, any>,
    additionalParams?: Record<string, any>,
  ): AxiosPromise => {
    const commonHeaders = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${this.auth0ApiKey}`,
      },
      params: {
        ...additionalParams,
      },
    };

    if (getRequest) {
      return this.auth0Api.get(endpoint, commonHeaders);
    } else {
      return this.auth0Api.post(endpoint, payload, commonHeaders);
    }
  };

  getUsers(userAttribute?: string): AxiosPromise<Array<User>> {
    const includeNeverLoggedUsersParam = '(NOT _exists_:logins_count OR logins_count:0 OR logins_count:[0 TO *])';
    const filterUserByNameOrEmailParam = `(email:*${userAttribute}* OR name:*${userAttribute}*)`;
    const finalQueryString = `${includeNeverLoggedUsersParam} ${
      userAttribute ? `AND ${filterUserByNameOrEmailParam}` : ''
    }`;

    return this.executeAuth0ApiRequest('/users', true, undefined, { search_engine: 'v3', q: finalQueryString });
  }

  getSellersUsers(group: UserGroup, name?: string, isWhiteLabel?: boolean): AxiosPromise<Array<User>> {
    const includeNeverLoggedUsersParam = '(NOT _exists_:logins_count OR logins_count:0 OR logins_count:[0 TO *])';
    const filterUserBySellerGroup = `(app_metadata.authorization.groups:"${group}")`;
    const filterUserByName = `(name:*${name ?? ''}*)`;
    const finalQueryString = isWhiteLabel
      ? `${includeNeverLoggedUsersParam} AND ${filterUserByName}`
      : `${includeNeverLoggedUsersParam} AND ${filterUserBySellerGroup} AND ${filterUserByName}`;

    return this.executeAuth0ApiRequest('/users', true, undefined, { search_engine: 'v3', q: finalQueryString });
  }

  getUserById(userId: string): AxiosPromise<User> {
    const userIdFilter = `app_metadata.uuid:("${userId}")`;

    return this.executeAuth0ApiRequest('/users', true, undefined, { search_engine: 'v3', q: userIdFilter });
  }

  getUsersByIdList(usersId: string[]): AxiosPromise<Array<User>> {
    const usersIdListFilter = `app_metadata.uuid:(${usersId.map((userId) => `"${userId}"`)})`;

    return this.executeAuth0ApiRequest('/users', true, undefined, { search_engine: 'v3', q: usersIdListFilter });
  }
}

export default Auth0ApiService;
