import { ISimulationReportBase } from '@contexts/types';

export const calculateProposalManagementFeeMonthly = (unitsReports: Array<ISimulationReportBase>) => {
  const managementFeeMonthlyByUnit: Array<number> = unitsReports.map(({ totalManagementPrice, totalMonths }) => {
    return totalManagementPrice / totalMonths;
  });

  const totalManagementFeeMonthly = managementFeeMonthlyByUnit.reduce(
    (managementFeeMonthlyA, managementFeeMonthlyB) => managementFeeMonthlyA + managementFeeMonthlyB,
    0,
  );

  return totalManagementFeeMonthly;
};
