import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useProductOne from '@hooks/use-product-one';
import useWhiteLabel from '@hooks/use-white-label';

import { SIMULATION_NOT_FOUND_PATH } from '@routers/constants';

import { ManagementSectionProposalSection } from '@components/organisms/new-proposal/management';

import AboutSectionProposalContent from '@components/organisms/new-proposal/about';
import FeasibilitySectionProposalContent from '@components/organisms/new-proposal/feasibility';
import NextStepSectionProposalContant from '@components/organisms/new-proposal/next-steps';

import './styles.css';
import { IProductOneSimulationExtendedResult } from '@contexts/product-one/types';
import { parseProductOneSimulationResults } from '@contexts/product-one/parser';

function ProductOneProposalSectionCore() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { proposal, getProductOneProposalById } = useProductOne();

  const simulationResults = proposal && parseProductOneSimulationResults(proposal);
  const { isLoading, getWhiteLabelSupplierById } = useWhiteLabel();

  const getProposal = async (id: string) => {
    const response = await getProductOneProposalById(id);
    !response && navigate(SIMULATION_NOT_FOUND_PATH);
  };

  React.useEffect(() => {
    if (id && !proposal) getProposal(id);
    else if (proposal) {
      const supplierId = proposal?.inputs?.whiteLabelSupplierId;
      if (supplierId) {
        getWhiteLabelSupplierById(supplierId);
      }
    }
  }, [id, proposal]);

  if (proposal && !isLoading) {
    return (
      <section className="section-core-proposal">
        <AboutSectionProposalContent />
        <FeasibilitySectionProposalContent />
        <ManagementSectionProposalSection simulationResults={simulationResults && simulationResults[0]} />
        <NextStepSectionProposalContant />
      </section>
    );
  } else return <></>;
}

export default ProductOneProposalSectionCore;
