export const DescriptionSection = ({ description }: { description?: string }) => (
  <>
    {description && (
      <div>
        <h2 className="text-heading-xsmall font-bold mb-4">Descrição</h2>
        <p className="text-paragraph-small">{description}</p>
      </div>
    )}
  </>
);
