import numeral from 'numeral';

export const generatePreMigrationTableData = (totalMigrationCost?: number) => [
  {
    title: totalMigrationCost === undefined ? 'Migração' : `Migração ${numeral(totalMigrationCost).format('$ 0,0')}`,
    description:
      totalMigrationCost && totalMigrationCost > 0 ? 'pago à vista antes da migração para o Mercado Livre' : '',
  },
  {
    title: 'Cotação (inclusa)',
    description:
      'Consultoria na definição do melhor produto de energia e acesso ao Marketplace com mais de 50 fornecedores para cotar as melhores condições e preços de energia.',
  },
  {
    title: 'Migração (inclusa)',
    description:
      'Representação e assessoria em todo o processo de migração para o mercado livre, cuidando das rotinas e dos contratos junto à distribuidora, ao fornecedor de energia e aos órgãos competentes.',
  },
];

export const generatePostMigrationTableData = (managementFee?: number) => [
  {
    title: managementFee === undefined ? 'Gestão' : `Gestão ${numeral(managementFee).format('$ 0,0')} / mês`,
    description: managementFee === undefined ? '' : `para todas as unidades consumidoras.`,
  },
  {
    title: 'Gestão (inclusa)',
    description:
      'Consultoria e auditoria no mercado livre, para garantir sempre as melhores condições contratadas em energia e assessoria em todas as rotinas junto à distribuidora, ao fornecedor de energia e aos órgãos competentes.',
  },
];
