import { useContextSelector } from 'use-context-selector';

import { ProductTwoContext } from '@contexts/product-two';
import { IProductTwoContext } from '@contexts/product-two/types';

const useProductTwo = (): IProductTwoContext => {
  const proposal = useContextSelector(ProductTwoContext, (sales) => sales.proposal);

  const createProductTwoSimulation = useContextSelector(
    ProductTwoContext,
    (productTwo) => productTwo.createProductTwoSimulation,
  );
  const getProductTwoProposals = useContextSelector(
    ProductTwoContext,
    (productTwo) => productTwo.getProductTwoProposals,
  );
  const getProductTwoProposalById = useContextSelector(
    ProductTwoContext,
    (productTwo) => productTwo.getProductTwoProposalById,
  );
  const deleteProductTwoProposalsHandler = useContextSelector(
    ProductTwoContext,
    (productTwo) => productTwo.deleteProductTwoProposalsHandler,
  );
  const deleteProductTwoAllProposalsByLeadIdHandler = useContextSelector(
    ProductTwoContext,
    (sales) => sales.deleteProductTwoAllProposalsByLeadIdHandler,
  );
  const updateSellerOfProductTwoProposal = useContextSelector(
    ProductTwoContext,
    (productTwo) => productTwo.updateSellerOfProductTwoProposal,
  );
  const updateProductTwoProposalCommercialDealerComission = useContextSelector(
    ProductTwoContext,
    (productTwo) => productTwo.updateProductTwoProposalCommercialDealerComission,
  );
  return {
    proposal,
    createProductTwoSimulation,
    getProductTwoProposals,
    getProductTwoProposalById,
    deleteProductTwoProposalsHandler,
    deleteProductTwoAllProposalsByLeadIdHandler,
    updateSellerOfProductTwoProposal,
    updateProductTwoProposalCommercialDealerComission,
  };
};

export default useProductTwo;
