import { IconProps } from '@clarke-energia/foton';

import { IProductOneProposal } from '@contexts/product-one/types';
import useWhiteLabel from '@hooks/use-white-label';

import clipboardDocumentList from '@assets/newProposal/clipboard-document-check.svg';

import { generateDescription } from './helper';

export const dataForManagementProposalDetail = (calculatorMetadata: IProductOneProposal['calculatorMetadata']) => {
  if (calculatorMetadata) {
    const inputs = calculatorMetadata.inputs;
    const discountFreeServiceProposalPrices = calculatorMetadata.firstResult?.discountFreeServiceProposalPrices;

    if (!discountFreeServiceProposalPrices)
      return {
        proposalValidity: inputs?.proposalValidity,
        totalMigrationCost: inputs?.totalMigrationCost,
        totalManagementPrice: calculatorMetadata?.firstResult?.totalManagementPrice,
      };

    return {
      proposalValidity: inputs?.proposalValidity,
      totalMigrationCost: discountFreeServiceProposalPrices?.totalMigrationCost,
      // TODO remove conditional after two weeks of implementation
      totalMonthlyManagementPrice: discountFreeServiceProposalPrices?.totalMonthlyManagementPrice? discountFreeServiceProposalPrices?.totalMonthlyManagementPrice: discountFreeServiceProposalPrices?.totalManagementPrice,
      discountInPercentage: discountFreeServiceProposalPrices?.discountPercentage,
      discountTotalMigrationCost: inputs?.totalMigrationCost,
      // TODO remove conditional after two weeks of implementation
      discountTotalMonthlyManagementPrice: calculatorMetadata.firstResult?.totalMonthlyManagementCost? calculatorMetadata.firstResult?.totalMonthlyManagementCost: calculatorMetadata.firstResult?.totalManagementPrice,
    };
  }
  return null;
};

interface IManagementcardDetailProps {
  headerContent: {
    title: string;
    description?: string;
    image?: IconProps['icon'];
    imageSvg?: string;
  };
  managementPrice: number;
  migrationCost: number;
  isCardDistacled?: boolean;
}

export function createManagementcardData(
  proposalDetail: any,
  calculatorMetadata: IProductOneProposal['calculatorMetadata'],
): Array<IManagementcardDetailProps> {
  // TODO remove conditional after two weeks of implementation - remove total_months and isNewVersionOfProposal
  const totalMonths = calculatorMetadata?.firstResult?.unitsReports[0]?.totalMonths || 1;
  const isNewVersionOfProposal = calculatorMetadata?.firstResult?.discountFreeServiceProposalPrices?.totalMonthlyManagementPrice;

  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();

  const baseList = [
    {
      headerContent: {
        title: `Preço padrão da ${isWhiteLabelProposal ? whiteLabelSupplier?.presentation?.name : 'Clarke Energia'}`,
        image: 'ClipboardDocumentListIcon' as IconProps['icon'],
      },
      // TODO remove conditional after two weeks of implementation
      managementPrice: isNewVersionOfProposal ? proposalDetail?.totalMonthlyManagementPrice: (proposalDetail?.totalManagementPrice ?? 0) / totalMonths,
      migrationCost: proposalDetail?.totalMigrationCost ?? 0,
    },
    {
      headerContent: {
        title: 'Condição especial para a sua empresa',
        image: 'ClipboardDocumentCheckIcon' as IconProps['icon'],
        description: generateDescription(proposalDetail),
        imageSvg: clipboardDocumentList,
      },
      // TODO remove conditional after two weeks of implementation
      managementPrice: isNewVersionOfProposal ? (proposalDetail?.discountTotalMonthlyManagementPrice) :
        (proposalDetail?.discountTotalManagementPrice ?? proposalDetail?.totalManagementPrice) / totalMonths,
      migrationCost: proposalDetail?.discountTotalMigrationCost ?? proposalDetail?.totalMigrationCost,
      isCardDistacled: true,
    },
  ];

  if (!proposalDetail?.discountInPercentage) {
    return baseList.slice(1);
  } else {
    return baseList;
  }
}
