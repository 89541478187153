import { useFormContext } from 'react-hook-form';

import { ISimulationPayloadBase } from '@contexts/types';

import { CheckboxFormField } from '@components/atoms/form/fields/checkbox-form-field';

const SimulatorFormIRecSection = (): JSX.Element => {
  const { watch } = useFormContext<ISimulationPayloadBase>();
  const useIRecCertificationValue = watch('useIRecCertification');

  return (
    <div className="bg-neutral-10 mt-6 pt-4 pb-6 px-6 rounded-medium ">
      <CheckboxFormField<ISimulationPayloadBase>
        label=""
        field="useIRecCertification"
        inputOptions={[
          {
            id: 'useIRecCertification',
            optionLabel: 'Ativar certificado I-REC',
            value: 'useIRecCertification',
            defaultChecked: useIRecCertificationValue,
          },
        ]}
      />
    </div>
  );
};

export default SimulatorFormIRecSection;
