import React from 'react';
import { createContext } from 'use-context-selector';

import Auth0ApiService from '@services/auth0';
import { useAuth } from '@src/auth-wrapper';

import { IUsersContext, UserInfo } from './types';

export const UsersContext = createContext({} as IUsersContext);

export const UsersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [sellersUsers, setSellersUsers] = React.useState<Array<UserInfo>>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const {
    authStatus: { accessToken },
  } = useAuth();

  const auth0ApiService = accessToken ? new Auth0ApiService(accessToken) : null;

  function getSellersUsersHandler(name?: string) {
    setLoading(true);
    auth0ApiService
      ?.getSellersUsers('Vendas Revendedor', name)
      .then((response) => {
        const parsedUsersList: UserInfo[] = response.data.map(
          (user) =>
            ({
              id: user.app_metadata.uuid as string,
              authId: user.user_id,
              email: user.email,
              isAdmin: user.app_metadata.authorization.groups.includes('Vendas Admin'),
              isCommercialDealer: user.app_metadata.authorization.groups.includes('Vendas Revendedor'),
              isWhiteLabel: user.app_metadata.authorization.groups.includes('Vendas White Label'),
              name: user.name,
              phone: user.phone_number,
              pictureUrl: user.picture,
              groups: user.app_metadata.authorization.groups,
            } as UserInfo),
        );

        setSellersUsers(parsedUsersList);
      })
      .finally(() => setLoading(false));
  }

  return (
    // @ts-expect-error -> generic error caused by external lib
    <UsersContext.Provider
      value={{
        sellersUsers,
        getSellersUsersHandler,
        isLoading,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
