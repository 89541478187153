import LegendContainer from '@components/atoms/new-proposal/legend-container';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import TableTimeline from '@components/molecules/new-proposal/timeline';
import './styles.css';

function TimelineSection() {
  return (
    <SlideContainer>
      <section className="timeline-section-styles">
        <LegendContainer legend="Próximos passos" />
        <div className="timeline-text-container-styles">
          <h1 className="text-primary-70 text-start text-heading-xlarge 2xl:text-heading-2xlarge">Cronograma</h1>
          <TableTimeline />
        </div>
      </section>
    </SlideContainer>
  );
}

export default TimelineSection;
