import { twMerge } from 'tailwind-merge';
import smartphoneImage from '@assets/newProposal/smartphone.png';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import { NewIconCard } from '@components/atoms/new-proposal/icon-card/index';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import { exclusiveWhiteLabelCardData, howWeDoCardData } from '@components/molecules/new-proposal/static';
import useWhiteLabel from '@hooks/use-white-label';
import './styles.css';

function HowWeDoItSection() {
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();
  const legend = {
    default: 'Como a Clarke faz?',
    whiteLabelSupplier: 'Sobre',
  };
  return (
    <SlideContainer className="min-h-screen h-fit">
      <section className="how-we-do-it-section-styles xl:min-h-screen xl:pb-7">
        <LegendContainer
          isWhiteLabelProposal={isWhiteLabelProposal}
          legend={isWhiteLabelProposal ? legend.whiteLabelSupplier : legend.default}
        />
        <div className={twMerge('how-we-do-it-text-container', isWhiteLabelProposal && 'items-center')}>
          <div className="w-full gap-4 sm:py-10 flex flex-col sm:gap-4 sm:pl-5">
            <span className={twMerge('text-styles-how-we-do-it-span', isWhiteLabelProposal && 'min-w-fit')}>
              <p>
                Além de ser o caminho mais
                <b className={twMerge('text-primary-70 mx-3', isWhiteLabelProposal && `text-white-label-main`)}>
                  simples e barato
                </b>
                para comprar{' '}
                <b className={twMerge('text-primary-70', isWhiteLabelProposal && `text-white-label-main`)}>
                  energia limpa
                </b>
                ,
              </p>
              {!isWhiteLabelProposal ? (
                <b className="text-heading-medium text-primary-70 xl:text-heading-xlarge 2xl:text-heading-2xlarge mt-2 sm:break-normal">
                  a Clarke também te oferece todas essas condições:
                </b>
              ) : (
                <b className="text-heading-medium xl:text-heading-xlarge 2xl:text-display-small mt-2 sm:break-normal text-white-label-main text-left">
                  nossa empresa também te oferece todas essas condições:
                </b>
              )}
            </span>
          </div>

          <div className={twMerge(isWhiteLabelProposal ? 'grid-styles-how-we-do-it-wl' : 'grid-styles-how-we-do-it')}>
            {(isWhiteLabelProposal && whiteLabelSupplier?.differences
              ? exclusiveWhiteLabelCardData(whiteLabelSupplier.differences)
              : howWeDoCardData
            ).map((item) => (
              <NewIconCard
                key={`proposal-how-we-do-it-section-${item.title}`}
                {...item}
                sizeCard="max-h-32 xl:max-h-[7.875rem] w-full sm:h-28 sm:items-center justify-start sm:flex-row 2xl:h-48 overflow-clip"
                isCardDescription={true}
                iconColor={item.iconColor}
              />
            ))}
          </div>

          {!isWhiteLabelProposal && (
            <div className="h-fit w-fit min-w-">
              <img src={smartphoneImage} alt="Smartphone" className="image-styles-how-we-do-it" />
            </div>
          )}
        </div>
      </section>
    </SlideContainer>
  );
}

export default HowWeDoItSection;
