import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Button, FeedbackNotification } from '@clarke-energia/foton';

import { IManagementContractPayload } from '@contexts/management-contract/types';
import useManagementContract from '@hooks/use-management-contract';

import validateContractFormFields from './validate-contract-form-fields';

interface IContractFooter {
  hasPreviousDraft: boolean;
  setHasPreviousDraft: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ContractFormFooter({ hasPreviousDraft, setHasPreviousDraft, setIsModalOpen }: IContractFooter) {
  const navigate = useNavigate();
  const [isDocumentsAttached, setIsDocumentsAttached] = React.useState<boolean>(false);

  const { handleCreateContract, handleSaveDraft, isLoading, feedbackData, setFeedbackData } = useManagementContract();
  const { reset, watch, setError } = useFormContext<IManagementContractPayload>();

  const formValues = watch();
  const attachments = watch('attachments');

  React.useEffect(() => {
    if (attachments?.length && attachments?.length > 0) {
      setIsDocumentsAttached(true);
    } else {
      setIsDocumentsAttached(false);
    }
  }, [attachments]);

  const forwardToCreateContract = (
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setFeedbackData: React.Dispatch<React.SetStateAction<any>>,
  ) => {
    const validationResponse = validateContractFormFields(formValues, setError);
    if (!validationResponse) {
      setFeedbackData({
        kind: 'error',
        label: 'Erro',
        message: 'Formulário incompleto. Favor preencher os campos obrigatórios.',
      });
    } else {
      handleCreateContract(formValues, hasPreviousDraft, setIsModalOpen);
    }
  };

  React.useEffect(() => {
    isLoading && setFeedbackData({ kind: 'neutral', label: 'Aguarde', message: 'Estamos processando a solicitação.' });
  }, [isLoading]);

  return (
    <div>
      <div className="fixed bottom-8 right-12">
        {feedbackData && feedbackData.message && feedbackData.kind && (
          <FeedbackNotification
            kind={feedbackData.kind}
            label={feedbackData.label}
            message={feedbackData.message}
            timeToCloseAutomaticallyInSeconds={10}
            onCloseNotification={() => setFeedbackData(undefined)}
          />
        )}
      </div>
      <div className="flex flex-row gap-4 mt-20">
        <Button
          kind="primary"
          type="submit"
          label="Criar contrato"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => forwardToCreateContract(setIsModalOpen, setFeedbackData)}
        />
        <Button
          kind="secondary"
          type="submit"
          label="Salvar rascunho"
          disabled={isLoading || isDocumentsAttached}
          loading={isLoading}
          onClick={() => handleSaveDraft(formValues, hasPreviousDraft, setHasPreviousDraft, reset)}
        />
        <Button
          kind="secondary"
          type="submit"
          label="Cancelar"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => navigate(-1)}
        />
      </div>
    </div>
  );
}
