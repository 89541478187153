import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonIcon } from '@clarke-energia/foton';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { getCalculatorName } from '@utils/common';

interface ISimulationsListColumnBase {
  simulationId: string;
  simulationPath: string;
  disabled?: boolean;
}

export const SimulationsListPreviewButton = ({
  simulationId,
  simulationPath,
  disabled,
}: ISimulationsListColumnBase) => (
  <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
    <Link
      to={`${simulationPath}/previa/${simulationId}`}
      className={`text-green-600 hover:text-green-900 ${disabled && 'opacity-50 pointer-events-none cursor-default'}`}
      onClick={() =>
        eventTracker.trackEvent(eventLabels.CLICK_VIEW_SIMULATION_DESCRIPTION, {
          product: getCalculatorName(location.href),
        })
      }
    >
      Pré-visualização
    </Link>
  </td>
);

export const SimulationsListViewButton = ({ simulationId, simulationPath, disabled }: ISimulationsListColumnBase) => (
  <ButtonIcon
    kind="primary"
    icon={disabled ? 'EyeSlashIcon' : 'EyeIcon'}
    onClick={() => {
      window.open(`${simulationPath}/${simulationId}`, '_blank', 'noopener,noreferrer');
      eventTracker.trackEvent(eventLabels.CLICK_OPEN_SIMULATION, {
        product: getCalculatorName(location.href),
      });
    }}
    disabled={disabled}
    className={disabled ? 'bg-neutral-10 hover:bg-neutral-20' : ''}
  />
);

interface ISimulationsListCommercialGroup {
  leadName: string;
  description?: string;
}

export const SimulationsListCommercialGroup = ({
  leadName,
  description,
}: ISimulationsListCommercialGroup): JSX.Element => {
  return (
    <td>
      <div className="flex flex-col items-start">
        <h1 className="text-paragraph-large font-normal">{leadName}</h1>
        <p className="text-paragraph-small font-normal	">{description}</p>
      </div>
    </td>
  );
};
interface ISimulationsListSeller {
  pictureUrl?: string;
}

export const SimulationsListSeller = ({ pictureUrl }: ISimulationsListSeller): JSX.Element => {
  return (
    <td>
      <div className="flex items-center ">
        <img className="w-6 h-6 rounded-full object-cover" src={pictureUrl} alt="picture-seller" />
      </div>
    </td>
  );
};
