import { StatesNamesByUF } from '@utils/states-names-by-uf';

import { IManagementContractPayload } from '@contexts/management-contract/types';

import ContractFieldContainer from '@components/atoms/form/contract/contract-field-container';
import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { GenericFormField } from '@components/atoms/form/fields/generic-form-field';
import { SelectFormField } from '@components/atoms/form/fields/select-form-field';

export default function ContractFormForumSection(): JSX.Element {
  return (
    <ContractSectionContainer>
      <>
        <ContractTitleAndSubtitleSubsection title="Foro" subtitle="Dados sobre o Foro relacionado ao contrato" />
        <ContractFieldContainer>
          <div className="grid grid-cols-2 col-span-1 gap-6 items-baseline">
            <SelectFormField<IManagementContractPayload>
              id="inputs.forum.state"
              field="inputs.forum.state"
              label="Estado"
              inputOptions={Object.entries(StatesNamesByUF).map((leadEntry) => ({
                value: leadEntry[1][0],
                optionLabel: leadEntry[1][1],
              }))}
            />
            <GenericFormField<IManagementContractPayload>
              id="inputs.forum.city"
              field="inputs.forum.city"
              label="Cidade"
            />
          </div>
        </ContractFieldContainer>
      </>
    </ContractSectionContainer>
  );
}
