import React from 'react';
import { Layout, NavigationLinkProps } from '@clarke-energia/foton';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';
import { getCalculatorName } from '@utils/common';

import HomeCommercialDealer from '@components/molecules/general/home-commercial-dealer';
import HomeLinkSection from '@components/molecules/general/home-link-section';
import { WhatsappButton } from '@components/atoms/general/whatsapp-button';
import {
  generateSidebarNavigationLinksPropsForAdmin,
  generateSidebarNavigationLinksPropsForCommercialDealer,
  generateSidebarNavigationLinksPropsForSuperAdmin,
  generateSidebarNavigationLinksPropsForWhiteLabel,
} from '@components/atoms/general/sidebar/generate-sidebar-navigation-links-props';

import { useAuth } from '@src/auth-wrapper';
import './styles.css';

export const OtherPlatformsLinksIndex: NavigationLinkProps[] = [
  {
    label: 'Admin Clarke',
    iconName: 'AdjustmentsVerticalIcon',
    path: 'https://admin.clarke.com.br/',
  },
];

const Home: React.FC = () => {
  const { user } = useAuth();
  const simulatorsLinks = user?.isCommercialDealer
    ? generateSidebarNavigationLinksPropsForCommercialDealer(false).slice(1, 3)
    : user?.isWhiteLabel
    ? generateSidebarNavigationLinksPropsForWhiteLabel(false).slice(1, 5)
    : generateSidebarNavigationLinksPropsForAdmin(false).slice(1, 5);
  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.PAGE_VIEW_HOME, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  }, []);

  return user?.isCommercialDealer ? (
    <HomeCommercialDealer
      simulatorsLinks={generateSidebarNavigationLinksPropsForSuperAdmin(false).slice(1, 3)}
      isCommercialDealer
    />
  ) : (
    <Layout title="Área do Vendedor" darkerBackground>
      <div className="links-container">
        <HomeLinkSection title="Simuladores" links={simulatorsLinks} />
        {!user?.isWhiteLabel && <HomeLinkSection title="Outras Plataformas" links={OtherPlatformsLinksIndex} />}
        {user?.isCommercialDealer && <WhatsappButton />}
      </div>
    </Layout>
  );
};

export default Home;
