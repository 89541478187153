import { IProductOneProposal } from '@contexts/product-one/types';
import { IProductTwoProposal } from '@contexts/product-two/types';

export const dataForUnitsSaving = (
  unitsReports:
    | IProductOneProposal['calculatorMetadata']['firstResult']['unitsReports']
    | IProductTwoProposal['calculatorMetadata']['default']['bestRetailTraderResult']['calculatorMetadata']['unitsReports'],
): Array<{ nickname: string; totalSavingInPercentage: number; totalSaving: number; netMonthlyEconomy: number }> => {
  if (unitsReports) {
    return unitsReports?.map((unit) => {
      return {
        nickname: unit.nickname,
        totalSavingInPercentage: unit.totalSavingInPercentage,
        totalSaving: unit.totalSaving,
        netMonthlyEconomy: unit.totalSaving / unit.totalMonths,
      };
    });
  }
  return [];
};