import React from 'react';

import { CompetitiveAnalysisProposal } from './competitive-analysis';

import useWhiteLabel from '@hooks/use-white-label';
import ManagementProposal from './section';
import IRecSection from './i-rec';
import NextStepsProposal from '../next-steps/section';
import { ProposalSummary } from '../proposal-summary';
import ManagementProposalDetail from './detail';

export interface ISimulationResultsProps {
  simulationResults: any;
}

export function ManagementSectionProposalSection({ simulationResults }: ISimulationResultsProps) {
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();
  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  return (
    <React.Fragment>
      {isManagementPath ? (
        <>
          <ManagementProposal />
          {!isWhiteLabelProposal && <ManagementProposalDetail />}
          {isWhiteLabelProposal && whiteLabelSupplier?.type === 'MANAGER' && <ManagementProposalDetail />}
          <CompetitiveAnalysisProposal simulationResults={simulationResults} />
          {!isWhiteLabelProposal && <IRecSection />}
        </>
      ) : (
        <>
          <NextStepsProposal />
          <ManagementProposalDetail />
          <ProposalSummary />
          <CompetitiveAnalysisProposal simulationResults={simulationResults} />
        </>
      )}
    </React.Fragment>
  );
}
