import React from 'react';

import ClarkeLogo from '@assets/illustrations/clarke-logo.svg';
import ClarkeSpin from '@assets/illustrations/spin.svg';

const LoadingPage: React.FC = () => {
  return (
    <div className="flex flex-col justify-center content-center items-center h-screen text-center bg-white">
      <div className="flex relative justify-center content-center items-center my-4 w-1/3 h-48">
        <img className="absolute h-36 animate-spin" src={ClarkeSpin} alt="Clarke Spin" />
        <img className="absolute h-28" src={ClarkeLogo} alt="Clarke logo" />
      </div>
      <p>Carregando informações</p>
    </div>
  );
};

export default LoadingPage;
