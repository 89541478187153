import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { HeroIcon, IconCardProps } from '@clarke-energia/foton';
import useWhiteLabel from '@hooks/use-white-label';
import { applyWhiteLabelColors } from './content/helper';
import CardContent from './content';
import { HeroIconSolidOrOutline, IHeroIcon } from '@components/atoms/form/hero-icon';

export interface INewIconCard extends Omit<IconCardProps, 'iconColor'> {
  title: string;
  label: string;
  iconColor?: 'primary' | 'neutral' | 'main' | 'special' | 'variantMain' | 'variantBrighter';
  index?: number;
  sizeCard?: string;
  isSpecialColor?: boolean;
  isWhiteLabelProposal?: boolean;
  isCardDescription?: boolean;
  customAttrs?: Record<any, any>;
  whiteLabelSupplierIcon?: IHeroIcon['icon'];
}

export function NewIconCard({
  title,
  label,
  icon,
  whiteLabelSupplierIcon,
  alternativeIcon,
  iconColor = 'main',
  index,
  sizeCard,
  isSpecialColor,
  isCardDescription,
  isWhiteLabelProposal = false,
  customAttrs,
  ...attrs
}: INewIconCard) {
  const { className, ...restAttrs } = attrs;
  const { whiteLabelSupplier } = useWhiteLabel();

  const DEFAULT_COLOR_GETTER: Record<
    'primary' | 'neutral' | 'main' | 'special' | 'variantMain' | 'variantBrighter',
    string
  > = {
    primary: 'bg-primary-60',
    neutral: 'bg-neutral-20 text-[#818181]',
    main: 'bg-[#BEF4C1] bg-opacity-90 text-primary-60',
    special: 'bg-[#FF6347] bg-opacity-30 text-[#FF6347]',
    variantMain: `bg-white-label-main text-white`,
    variantBrighter: `bg-white-label-brighter text-white-label-main`,
  };

  const color = DEFAULT_COLOR_GETTER[iconColor];
  const isMainCard = index === 0 && !isSpecialColor && !isCardDescription;
  const containerClasses =
    'w-full h-full flex sm:flex-col gap-7 items-center justify-start sm:items-start p-7 rounded-large pr-8';
  const iconWrapperClasses = `rounded-2xl flex items-center justify-center ${color}`;
  const isStandardCard = !isSpecialColor && !isCardDescription && !isWhiteLabelProposal;

  useEffect(() => {
    if (isWhiteLabelProposal && whiteLabelSupplier?.colors) {
      applyWhiteLabelColors({ ...whiteLabelSupplier.colors });
    }
  }, [isWhiteLabelProposal, whiteLabelSupplier]);

  return (
    <div
      {...customAttrs}
      {...restAttrs}
      key={`icon-card-${color}`}
      className={twMerge(
        containerClasses,
        isMainCard && !isWhiteLabelProposal
          ? 'bg-[#BEF4C1] bg-opacity-20'
          : isMainCard && isWhiteLabelProposal
          ? 'text-white-label-main bg-white-label-brighter bg-opacity-20'
          : 'bg-[#F2F2F2F2] text-[#818181]',
        sizeCard ?? 'sm:h-60 2xl:h-72 min-h-24 p-7',
      )}
    >
      <div className={iconWrapperClasses}>
        <div className="w-8 h-8 md:w-10 md:h-10 flex flex-col p-4 bg-15/14">
          {whiteLabelSupplierIcon && (
            <HeroIconSolidOrOutline icon={whiteLabelSupplierIcon} className="text-white-label-main" />
          )}
          {icon && icon !== 'None' && <HeroIcon icon={icon} />}
          {alternativeIcon && <img src={alternativeIcon} alt="Icon" className="w-full h-full" />}
        </div>
      </div>

      {isStandardCard && <CardContent title={title} label={label} index={index} />}
      {isSpecialColor && <CardContent title={title} label={label} index={index} type="specialColor" />}
      {isCardDescription && <CardContent title={title} label={label} index={index} type="description" />}
      {isWhiteLabelProposal && (
        <CardContent
          title={title}
          label={label}
          index={index}
          whiteLabelSupplierStyles={whiteLabelSupplier?.colors}
          type="variant"
        />
      )}
    </div>
  );
}
