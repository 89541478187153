import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import config from '@config';

import { useAuth } from '@src/auth-wrapper';
import { parseContractPayload } from '@contexts/management-contract/parser';

import { IManagementContractPayload, IManagementContract, IManagementContractContext } from './types';

export const ManagementContractContext = createContext({} as IManagementContractContext);

interface Provider {
  children: React.ReactNode;
}

const ManagementContractProvider: React.FC<Provider> = ({ children }) => {
  const {
    authStatus: { accessToken },
  } = useAuth();

  const salesApiClient = axios.create({
    baseURL: config.SALES_API_HOST,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
    formSerializer: { metaTokens: false, indexes: null },
  });

  function getManagementContractDraft(userId: string, proposalId: string): AxiosPromise {
    return salesApiClient.get('/api/v1/contract/draft', {
      params: {
        user_id: userId,
        proposal_id: proposalId,
      },
    });
  }
  function getManagementContracts(product: string, status: string, proposalId?: string): AxiosPromise {
    return salesApiClient.get('/api/v1/contract/', {
      params: {
        product: product,
        status: status,
        proposal_id: proposalId,
      },
    });
  }
  function createManagementContract(contract: IManagementContractPayload): AxiosPromise {
    return salesApiClient.post('/api/v1/contract/', parseContractPayload(contract));
  }

  function createManagementContractDraft(draft: IManagementContractPayload): AxiosPromise {
    return salesApiClient.post('/api/v1/contract/draft', parseContractPayload(draft));
  }

  function updateManagementContractDraft(draft: IManagementContract): AxiosPromise {
    return salesApiClient.post(`/api/v1/contract/draft/${draft.id}`, parseContractPayload(draft));
  }

  function publishManagementContractDraft(contract: IManagementContract): AxiosPromise {
    return salesApiClient.post('/api/v1/contract/draft/publish', parseContractPayload(contract));
  }

  return (
    // @ts-expect-error -> generic error caused by external lib

    <ManagementContractContext.Provider
      value={{
        createManagementContractDraft,
        updateManagementContractDraft,
        publishManagementContractDraft,
        getManagementContractDraft,
        getManagementContracts,
        createManagementContract,
      }}
    >
      {children}
    </ManagementContractContext.Provider>
  );
};

export default ManagementContractProvider;
