import { transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase } from '@utils/common';
import { IWhiteLabelSupplier, IWhiteLabelSupplierPayload } from './type';
import { parseMultipartPayload } from '@utils/form-data';

export const parseWhiteLabelSupplier = (
  whiteLabelSupplierSupplierRegistration: Record<string, any>,
): IWhiteLabelSupplier => {
  return transformObjectKeysToCamelCase(whiteLabelSupplierSupplierRegistration) as IWhiteLabelSupplier;
};

export const parseWhiteLabelSuppliers = (
  whiteLabelSupplierSupplierRegistration: Record<string, any>,
): Array<IWhiteLabelSupplier> => {
  return transformObjectKeysToCamelCase(whiteLabelSupplierSupplierRegistration) as IWhiteLabelSupplier[];
};

export const parseWhiteLabelSupplierPayload = (payload: IWhiteLabelSupplier | IWhiteLabelSupplierPayload) => {
  const cleanedPayload = { ...payload };

  const snakedCasePayload = transformObjectKeysToSnakeCase(cleanedPayload);
  const parsedPayload = parseMultipartPayload(snakedCasePayload, ['file_list']);
  return parsedPayload;
};

export const parseDataWhiteLabelSupplier = (whiteLabelSupplierSupplierData: IWhiteLabelSupplierPayload) => {
  const result = transformObjectKeysToSnakeCase(whiteLabelSupplierSupplierData);
  return result;
};
