import React, { useState } from 'react';
import { AxiosPromise } from 'axios';
import { UseFormReset } from 'react-hook-form';
import { useContextSelector } from 'use-context-selector';

import { ManagementContractContext } from '@contexts/management-contract';
import {
  IManagementContract,
  IManagementContractContext,
  IManagementContractPayload,
} from '@contexts/management-contract/types';
import { parseAdditionalServicesResponse } from '@contexts/management-contract/parser';

import { transformObjectKeysToCamelCase } from '@utils/common';

export interface IFeedbackData {
  message: string;
  kind: 'neutral' | 'primary' | 'error';
  label: string;
}

const getFeedbackData = (): Record<string, IFeedbackData> => {
  return {
    CREATE_CONTRACT_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Erro na criação de contrato.',
    },
    CREATE_DRAFT_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Erro na criação do rascunho de contrato',
    },
    UPDATE_DRAFT_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Erro na atualização do rascunho de contrato',
    },
    CREATE_DRAFT_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'Rascunho de contrato salvo com sucesso',
    },
    UPDATE_DRAFT_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'Rascunho de contrato atualizado com sucesso',
    },
    GET_PREVIOUS_DRAFT_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Erro ao buscar rascunhos de contrato prévios para essa proposta',
    },
  };
};
interface IUseManagementContract extends IManagementContractContext {
  handleCreateContract: (
    formValues: IManagementContractPayload,
    hasPreviousDraft: boolean,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
  handleSaveDraft: (
    formValues: IManagementContractPayload,
    hasPreviousDraft: boolean,
    setHasPreviousDraft: React.Dispatch<React.SetStateAction<boolean>>,
    reset: UseFormReset<IManagementContractPayload>,
  ) => void;
  handleSetFormDefaultValues: (
    userId: string,
    proposalId: string,
    setHasPreviousDraft: React.Dispatch<React.SetStateAction<boolean>>,
    reset: UseFormReset<IManagementContractPayload>,
  ) => void;
  isLoading: boolean;
  feedbackData: IFeedbackData | undefined;
  setFeedbackData: React.Dispatch<React.SetStateAction<IFeedbackData | undefined>>;
}
const useManagementContract = (): IUseManagementContract => {
  const createManagementContractDraft = useContextSelector(
    ManagementContractContext,
    (contract) => contract.createManagementContractDraft,
  );
  const updateManagementContractDraft = useContextSelector(
    ManagementContractContext,
    (contract) => contract.updateManagementContractDraft,
  );
  const publishManagementContractDraft = useContextSelector(
    ManagementContractContext,
    (contract) => contract.publishManagementContractDraft,
  );
  const getManagementContractDraft = useContextSelector(
    ManagementContractContext,
    (contract) => contract.getManagementContractDraft,
  );
  const getManagementContracts = useContextSelector(
    ManagementContractContext,
    (contract) => contract.getManagementContracts,
  );
  const createManagementContract = useContextSelector(
    ManagementContractContext,
    (contract) => contract.createManagementContract,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [feedbackData, setFeedbackData] = React.useState<IFeedbackData | undefined>();

  function handleCreateContract(
    formValues: IManagementContractPayload | IManagementContract,
    hasPreviousDraft: boolean,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ): AxiosPromise | void {
    setIsLoading(true);
    if (!hasPreviousDraft) {
      createManagementContract(formValues)
        .then((response) => {
          if (response.data.message[0].success) {
            setIsModalOpen(true);
          } else {
            setFeedbackData(getFeedbackData().CREATE_CONTRACT_FAILURE);
          }
        })
        .catch(() => {
          setFeedbackData(getFeedbackData().CREATE_CONTRACT_FAILURE);
        })
        .finally(() => setIsLoading(false));
    } else {
      publishManagementContractDraft(formValues as IManagementContract)
        .then((response) => {
          if (response.data.message[0].success) {
            setIsModalOpen(true);
          } else {
            setFeedbackData(getFeedbackData().CREATE_CONTRACT_FAILURE);
          }
        })
        .catch(() => {
          setFeedbackData(getFeedbackData().CREATE_CONTRACT_FAILURE);
        })
        .finally(() => setIsLoading(false));
    }
  }

  const handleSaveDraft = (
    formValues: IManagementContractPayload | IManagementContract,
    hasPreviousDraft: boolean,
    setHasPreviousDraft: React.Dispatch<React.SetStateAction<boolean>>,
    reset: UseFormReset<IManagementContractPayload>,
  ) => {
    setIsLoading(true);
    if (!hasPreviousDraft) {
      createManagementContractDraft(formValues)
        .then((response) => {
          if (response.data.message[0].success) {
            setFeedbackData(getFeedbackData().CREATE_DRAFT_SUCCESS);
            formValues.user.id &&
              handleSetFormDefaultValues(formValues.user.id, formValues.proposalId, setHasPreviousDraft, reset);
          } else {
            setFeedbackData(getFeedbackData().CREATE_DRAFT_FAILURE);
          }
        })
        .catch(() => {
          setFeedbackData(getFeedbackData().CREATE_DRAFT_FAILURE);
        })
        .finally(() => setIsLoading(false));
    } else {
      updateManagementContractDraft(formValues as IManagementContract)
        .then((response) => {
          if (response.data.message[0].success) {
            setFeedbackData(getFeedbackData().UPDATE_DRAFT_SUCCESS);
            formValues.user.id &&
              handleSetFormDefaultValues(formValues.user.id, formValues.proposalId, setHasPreviousDraft, reset);
          } else {
            setFeedbackData(getFeedbackData().UPDATE_DRAFT_FAILURE);
          }
        })
        .catch(() => {
          setFeedbackData(getFeedbackData().UPDATE_DRAFT_FAILURE);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleSetFormDefaultValues = async (
    userId: string,
    proposalId: string,
    setHasPreviousDraft: React.Dispatch<React.SetStateAction<boolean>>,
    reset: UseFormReset<IManagementContractPayload>,
  ) => {
    setIsLoading(true);
    try {
      const response = await getManagementContractDraft(userId, proposalId);
      if (response.data.success && response.data.code !== 'object-not-found') {
        const previousContractDraft = parseAdditionalServicesResponse(
          transformObjectKeysToCamelCase(response.data.data),
        );
        setHasPreviousDraft(true);
        reset(previousContractDraft as unknown as IManagementContract);
      }
    } catch (error) {
      setFeedbackData(getFeedbackData().GET_PREVIOUS_DRAFT_FAILURE);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createManagementContractDraft,
    updateManagementContractDraft,
    publishManagementContractDraft,
    getManagementContractDraft,
    getManagementContracts,
    createManagementContract,
    handleCreateContract,
    handleSaveDraft,
    handleSetFormDefaultValues,
    isLoading,
    feedbackData,
    setFeedbackData,
  };
};

export default useManagementContract;
