import { IProductOneSimulationExtendedResult } from '@contexts/product-one/types';
import { IProductTwoSimulationExtendedResult } from '@contexts/product-two/types';

import SimplifiedProposalEconomyChartSection from '@components/molecules/simplified-proposal/simplified-proposal-economy-chart';

import { ProposalCards } from '@components/molecules/simplified-proposal/static-data-cards';
import { CardContainer } from '@components/atoms/simplified-proposal/card-container';
import { CardItems } from '@components/molecules/simplified-proposal/simplified-proposal-cards';
import { getDataFromChart } from './helper';

export interface ISimplifiedProposalSectionTwo {
  simulationExtendedResult?: IProductOneSimulationExtendedResult | IProductTwoSimulationExtendedResult;
}

const SimplifiedProposalSectionTwo = ({ simulationExtendedResult }: ISimplifiedProposalSectionTwo): JSX.Element => {
  const chartData = getDataFromChart(simulationExtendedResult);
  const { title, iconTitle, imageSvg, cardContent } = ProposalCards()[0];
  return (
    <div className="grid grid-cols-1 grid-flow-col-dense lg:grid-flow-row lg:grid-cols-6 px-5 md:px-0 gap-7 xl:gap-7 h-fit">
      <div className="col-span-1 md:col-span-4 mt-16 sm:mt-0">
        <SimplifiedProposalEconomyChartSection chartData={chartData} />{' '}
      </div>
      <div className="hidden lg:grid col-span-1 md:col-span-2">
        <CardContainer isPremisesCard={false} columnsSpan={2}>
          <CardItems title={title} iconTitle={iconTitle} imageSvg={imageSvg} cardContent={cardContent} />
        </CardContainer>
      </div>
    </div>
  );
};
export default SimplifiedProposalSectionTwo;
