import React from 'react';

import ProductOneProvider from '@contexts/product-one';
import ProductTwoProvider from '@contexts/product-two';
import ContractedDemandProvider from '@contexts/contracted-demand';
import WhiteLabelProvider from '@contexts/white-label';

interface SimulatorsRouteProps {
  children: JSX.Element;
}

const SimulatorsRoute: React.FC<SimulatorsRouteProps> = ({ children }) => {
  return (
    <WhiteLabelProvider>
      <ProductOneProvider>
        <ProductTwoProvider>
          <ContractedDemandProvider>{children}</ContractedDemandProvider>
        </ProductTwoProvider>
      </ProductOneProvider>
    </WhiteLabelProvider>
  );
};

export default SimulatorsRoute;
