import { twMerge } from 'tailwind-merge';

export type IGadgetWithIconContent = {
  imgSrc: string;
  titleText: string;
  text: string;
  isOnPrintMode?: string;
};

type IBoxContent = {
  boxContent: IGadgetWithIconContent;
  isOnPrintMode?: boolean;
};

type IBoxesContent = {
  boxesContent: Array<IGadgetWithIconContent>;
  isOnPrintMode?: boolean;
};

const GadgetWithIconBox = ({ boxContent, isOnPrintMode }: IBoxContent) => {
  const dynamicClass = isOnPrintMode
    ? 'h-[22rem] gap-4'
    : 'h-full sm:min-h-[12.56rem] md:min-h-[14.5rem] xl:min-h-[21.875rem]';
  const fixedClass = 'col-span-1 bg-neutral-10 rounded-lg p-6 flex flex-col justify-between shadow-3';

  return (
    <div className={twMerge(fixedClass, dynamicClass)}>
      <img className="w-8 h-8 object-cover" src={boxContent.imgSrc} />
      <div className="flex flex-col justify-end gap-2">
        <p className="text-heading-medium [23.4rem]:text-heading-large font-bold">{boxContent.titleText}</p>
        <p className="text-heading-xsmall font-normal">{boxContent.text}</p>
      </div>
    </div>
  );
};

export const GadgetWithIcon = ({ boxesContent, isOnPrintMode }: IBoxesContent) => {
  return (
    <div className="p-8 grid gap-6 w-full grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 h-fit">
      {boxesContent.map((boxContent) => (
        <GadgetWithIconBox
          key={`product-two-gadget-with-icon-${boxContent.titleText}`}
          boxContent={boxContent}
          isOnPrintMode={isOnPrintMode}
        />
      ))}
    </div>
  );
};
