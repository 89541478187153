import React from 'react';

import clarkeLogo from '@assets/images/clarke-green-horizontal.png';
import SlideHeader from '@components/atoms/simulation/slide-header';

import { ICompetitiveAnalysisData, parseCompetitiveAnalysisData } from './parser';

export interface ICompetitiveAnalysisTableProps {
  competitiveAnalysis: ICompetitiveAnalysisData[] | undefined;
  isNewProposal?: boolean;
}

const CompetitiveAnalysisTable = ({ competitiveAnalysis }: ICompetitiveAnalysisTableProps): JSX.Element => {
  const comparisonDataWithOrganizedCompetitors =
    competitiveAnalysis && parseCompetitiveAnalysisData(competitiveAnalysis);

  const thirdCompetitorExists =
    comparisonDataWithOrganizedCompetitors && comparisonDataWithOrganizedCompetitors[0].thirdCompetitor;

  const widhtRow = thirdCompetitorExists ? 'w-1/4' : 'w-1/3';

  const titleNameOfCompetitors =
    comparisonDataWithOrganizedCompetitors && Object.keys(comparisonDataWithOrganizedCompetitors[0]);

  return (
    <table className="text-paragraph-medium font-normal mb-10 md:text-paragraph-large md:font-bold">
      {titleNameOfCompetitors && (
        <>
          <thead>
            {Object.keys(titleNameOfCompetitors).map((key) => {
              let content: string | JSX.Element = '';

              if (key === '1') {
                content = <img src={clarkeLogo} alt="logo-clarke-energia" className="w-3/4 pl-5 lg:w-2/4" />;
              } else if (key === '2') {
                content = <div className="pl-5">Concorrente A</div>;
              } else if (key === '3') {
                if (thirdCompetitorExists !== null) {
                  content = <div className="pl-5">Concorrente B</div>;
                }
              } else if (key === '0') {
                content = 'Condições';
              }

              return (
                <td
                  key={key}
                  className={`${widhtRow} h-fit py-4 items-start text-paragraph-small border-b border-gray-300 md:text-paragraph-large font-black${
                    key === '0' ? ' bg-neutral-10 pl-5' : ''
                  } ${thirdCompetitorExists}`}
                >
                  {content}
                </td>
              );
            })}
          </thead>
          <tbody>
            {comparisonDataWithOrganizedCompetitors?.map((itemData, index) => (
              <tr key={index} className="text-paragraph-small lg:text-paragraph-medium font-normal">
                <td className="border-b border-gray-300 md:w-1/4 pl-5 pr-6 bg-neutral-10 ">
                  <div className="w-48 lg:w-full whitespace-nowrap">{itemData.item}</div>
                </td>
                <td className={`border-b border-gray-300 ${widhtRow} pl-5`}>{itemData.firstCompetitor}</td>
                <td className={`border-b border-gray-300 ${widhtRow} pl-5`}>{itemData.secondCompetitor}</td>
                {itemData.thirdCompetitor !== null && (
                  <td className="border-b border-gray-300 md:w-1/4 pl-5">{itemData.thirdCompetitor}</td>
                )}
              </tr>
            ))}
          </tbody>
        </>
      )}
    </table>
  );
};

const CompetitiveAnalysis: React.FC<ICompetitiveAnalysisTableProps> = ({ competitiveAnalysis, isNewProposal }) => {
  return (
    <div className="w-full">
      <SlideHeader title="Comparação com concorrentes" hiddenHeaderLine={isNewProposal} />
      <div className="overflow-x-auto w-full h-full flex flex-col px-8 md:px-16 lg:px-32 md:items-center ">
        <CompetitiveAnalysisTable competitiveAnalysis={competitiveAnalysis} />
      </div>
    </div>
  );
};

export default CompetitiveAnalysis;
