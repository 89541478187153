import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { isValidEmail, transformObjectKeysToCamelCase } from '@utils/common';

import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { GenericFormField } from '@components/atoms/form/fields/generic-form-field';
import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractSectionFieldsContainer from '@components/atoms/form/contract/contract-section-fields-container';
import { ComboBoxFormField } from '@components/atoms/form/fields/combobox-form-field';
import useLeads from '@hooks/use-leads';
import { IProposalLead } from '@contexts/types';
import { IManagementContractPayload } from '@contexts/management-contract/types';
import { NumericFormField } from '@components/atoms/form/fields/numeric-form-field';

const ContractFormContractBondSection = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const leadIdFromSearchParms = searchParams.get('leadId');

  const {
    setValue,
    formState: { defaultValues },
  } = useFormContext<IManagementContractPayload>();

  const [isFetchingLead, setIsFetchingLead] = useState(false);
  const [leads, setLeads] = useState<Array<IProposalLead>>([]);
  const { getLeads, getLeadById } = useLeads();

  const parseLeads = (leads?: IProposalLead[]): Array<IProposalLead> | undefined => {
    if (leads && leads.length > 0) {
      return transformObjectKeysToCamelCase(leads) as Array<IProposalLead>;
    }
  };

  const transformLeadsToInputOptions = (leads: IProposalLead[]) => {
    const transformedLeadListIntoInputOptions = leads.map((lead: IProposalLead) => ({
      value: lead.id,
      label: lead.name,
    }));

    return transformedLeadListIntoInputOptions;
  };

  const transformedLeadListIntoInputOptions = leads && transformLeadsToInputOptions(leads);

  const aglutinateLeads = (currentParsedLeads: Array<IProposalLead>, parsedLeads: Array<IProposalLead>) => {
    return [...new Set([...currentParsedLeads, ...parsedLeads])];
  };

  const fetchLeads = (page?: number, name?: string) => {
    setIsFetchingLead(true);
    getLeads(page, name, undefined, undefined, undefined)
      .then((response) => {
        if (response.data.success) {
          const parsedLeads = parseLeads(response.data.data.data);
          parsedLeads && setLeads((currentParsedLeads) => aglutinateLeads(currentParsedLeads, parsedLeads));
        }
      })
      .finally(() => setIsFetchingLead(false));
  };

  const handleGetLeadData = (id?: string) => {
    setIsFetchingLead(true);
    getLeadById(id)
      .then((response) => {
        if (response.data.success) {
          const parsedLeads = parseLeads([response.data.data]);
          if (parsedLeads) {
            setValue('inputs.customer.name', parsedLeads[0].name);
            setValue('lead.id', parsedLeads[0].id);
            setValue('lead.name', parsedLeads[0].name);
          }
        }
      })
      .finally(() => setIsFetchingLead(false));
  };

  useEffect(() => {
    if (leadIdFromSearchParms) {
      handleGetLeadData(leadIdFromSearchParms);
    } else {
      fetchLeads();
    }
  }, [leadIdFromSearchParms]);

  return (
    <ContractSectionContainer>
      <>
        <ContractTitleAndSubtitleSubsection
          title="Cliente e Vínculo do Contrato"
          subtitle="Quem é o cliente? Qual é a unidade que está sob esse contrato de energia?"
        />
        <ContractSectionFieldsContainer>
          <>
            <>
              {leadIdFromSearchParms ? (
                <GenericFormField<IManagementContractPayload>
                  id="inputs.customer.name"
                  field="inputs.customer.name"
                  label="Grupo Comercial"
                  disabled={true}
                />
              ) : (
                <ComboBoxFormField<IManagementContractPayload>
                  id="inputs.customer.name"
                  field="inputs.customer.name"
                  label="Grupo Comercial"
                  inputOptions={transformedLeadListIntoInputOptions}
                  options={{ required: { value: true, message: 'O nome do grupo comercial é obrigatório' } }}
                  onInputChange={(value) => {
                    value && fetchLeads(undefined, value);
                  }}
                  isLoading={isFetchingLead}
                />
              )}
            </>

            <NumericFormField<IManagementContractPayload>
              id="inputs.customer.docNumber"
              field="inputs.customer.docNumber"
              label="CNPJ"
              formatProps={{ format: '##.###.###/####-##', isNumericString: true }}
            />
            <NumericFormField<IManagementContractPayload>
              id="inputs.customer.headOfficeZipCode"
              field="inputs.customer.headOfficeZipCode"
              label="CEP da Matriz"
              formatProps={{ format: '#####-###', isNumericString: true }}
            />
            <GenericFormField<IManagementContractPayload>
              id="inputs.customer.headOfficeAddress"
              field="inputs.customer.headOfficeAddress"
              label="Endereço da Matriz"
            />
            <GenericFormField<IManagementContractPayload>
              id="inputs.customer.financeDepartmentEmail"
              field="inputs.customer.financeDepartmentEmail"
              label="E-mail do Departamento Financeiro"
              options={{
                validate: (value) => isValidEmail(value as string) || 'Insira um E-mail válido',
              }}
            />
          </>
        </ContractSectionFieldsContainer>
      </>
    </ContractSectionContainer>
  );
};

export default ContractFormContractBondSection;
