import { twMerge } from 'tailwind-merge';
import { HeroIcon, IconProps } from '@clarke-energia/foton';
import useWhiteLabel from '@hooks/use-white-label';

interface ICardHeader {
  title: string;
  icon: IconProps['icon'];
  imageSvg?: string;
  isPremisesCard?: boolean;
  className?: string;
}

function CardHeaderProposal({ title, icon, imageSvg, className, isPremisesCard }: ICardHeader) {
  const isSimplifiedProposal = window.location.pathname.includes('proposta-simplificada');
  const { isWhiteLabelProposal } = useWhiteLabel();
  const titleClassFixed = isPremisesCard && 'xl:text-paragraph-medium';
  const titleClassDynamic = 'text-paragraph-medium font-bold ml-6';
  const containerClassFixed = 'flex items-center mb-4 h ';
  const iconClassFixed = 'h-8 w-8 xl:h-14 xl:w-14 p-2 xl:p-5';
  const dynamicBgColorIcons = isWhiteLabelProposal
    ? 'bg-white-label-brighter text-white-label-main'
    : ' bg-primary-10 text-primary-70';
  const iconClassDynamic = isSimplifiedProposal ? 'rounded-full' : ' rounded-[0.625rem]';

  return (
    <div className={twMerge(containerClassFixed, className)}>
      <div>
        {imageSvg && imageSvg !== '' ? (
          <img className={twMerge(dynamicBgColorIcons, iconClassFixed)} src={imageSvg} alt="icon-img" />
        ) : (
          icon && <HeroIcon icon={icon} className={twMerge(dynamicBgColorIcons, iconClassFixed, iconClassDynamic)} />
        )}
      </div>
      <div className={twMerge(titleClassFixed, titleClassDynamic, 'text-heading-xsmall')}>{title}</div>
    </div>
  );
}

export default CardHeaderProposal;
