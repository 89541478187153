import React from 'react';
import { FeedbackNotification } from '@clarke-energia/foton';
import { useForm, FormProvider } from 'react-hook-form';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { getCalculatorName, isObjectEmpty } from '@utils/common';
import { getSimulatorInitialValues } from '@components/molecules/form/simulator/default-values-getters';

import {
  IContractedDemandSimulation,
  IContractedDemandSimulationUnitPayload,
  IContractedDemandSimulatorInputs,
} from '@contexts/contracted-demand/types';
import { parseContractedDemandSimulatorResult } from '@contexts/contracted-demand/parser';
import { parseUnitsConsumptionHistory } from '@contexts/calculator/units-payload-handlers';

import useCustomClipboard from '@hooks/use-custom-clipboard';
import useContractedDemand from '@hooks/use-contracted-demand';

import ContractedDemandSimulatorFormCore from '@components/organisms/contracted-demand/simulator/contracted-demand-simulator-form-core';
import SimulatorLayoutBase from '@components/organisms/simulator-layout-base';
import { CalculatorFormFooter } from '@components/molecules/calculator-form-footer';
import { useAuth } from '@src/auth-wrapper';

interface IContractedDemandSimulatorForm {
  setSimulatorResult: React.Dispatch<React.SetStateAction<IContractedDemandSimulation | undefined>>;
  setSimulatorInputs: React.Dispatch<React.SetStateAction<IContractedDemandSimulatorInputs | undefined>>;
}

const ContractedDemandSimulatorForm = ({
  setSimulatorResult,
  setSimulatorInputs,
}: IContractedDemandSimulatorForm): JSX.Element => {
  const { CopyFormValues, PasteFormValues, customClipboardFeedbackNotificationContent } = useCustomClipboard();
  const { createContractedDemandSimulation } = useContractedDemand();
  const { user } = useAuth();

  const formRef = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const formController = useForm<IContractedDemandSimulatorInputs>({
    defaultValues: getSimulatorInitialValues('CONTRACTED_DEMAND') as IContractedDemandSimulatorInputs,
    mode: 'all',
  });
  const {
    watch,
    reset,
    formState: { errors },
  } = formController;
  const formValues = watch();

  const onSubmit = (values: IContractedDemandSimulatorInputs) => {
    setLoading(true);

    const parsedUnits = parseUnitsConsumptionHistory(values.units) as Array<IContractedDemandSimulationUnitPayload>;
    const finalValues: IContractedDemandSimulatorInputs = {
      ...values,
      units: parsedUnits,
    };

    setSimulatorInputs(finalValues);
    CopyFormValues(values, false, false);

    createContractedDemandSimulation(finalValues)
      .then((response) => {
        const {
          data: { data },
        } = response;

        const parsedData = parseContractedDemandSimulatorResult(data);
        setSimulatorResult(parsedData);

        eventTracker.trackEvent(eventLabels.FORM_SUBMITTED, {
          form: getCalculatorName(location.href),
          user_groups: user?.groups ? user?.groups.join(', ') : '',
          user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
        });
      })
      .catch((error) => {
        alert('Ocorreu um erro. Verifique o formulário e tente novamente.');

        throw error;
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (location.href.includes('refazer-calculo')) {
      PasteFormValues(reset, 'CONTRACTED_DEMAND', false);
    } else {
      eventTracker.trackEvent(eventLabels.PAGE_VIEW_SIMULATOR, {
        product: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
      eventTracker.trackEvent(eventLabels.FORM_STARTED, {
        form: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
    }
  }, []);

  return (
    <SimulatorLayoutBase<IContractedDemandSimulatorInputs>
      title="Simulador"
      simulatorType="CONTRACTED_DEMAND"
      simulatorFlow="SIMULATOR_FORM"
      setFormValues={reset}
      formValues={formValues}
      hidePasteValuesButtons
    >
      <div className="col-span-full flex flex-col w-full lg:w-3/4 px-6">
        <FormProvider {...formController}>
          {customClipboardFeedbackNotificationContent && (
            <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
              <FeedbackNotification {...customClipboardFeedbackNotificationContent} />
            </div>
          )}
          <ContractedDemandSimulatorFormCore {...{ formRef, onSubmit, formController }} />
          <CalculatorFormFooter error={!isObjectEmpty(errors)} {...{ formRef, loading, onSubmit }} />
        </FormProvider>
      </div>
    </SimulatorLayoutBase>
  );
};

export default ContractedDemandSimulatorForm;
