import { twMerge } from 'tailwind-merge';
import MacBookImage from '@assets/newProposal/macbook.png';
import useWhiteLabel from '@hooks/use-white-label';
import SectionProposalHeroContainer from '@components/atoms/new-proposal/hero-container/hero';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';

function ManagementProposal() {
  const { isWhiteLabelProposal } = useWhiteLabel();
  return (
    <SlideContainer>
      <SectionProposalHeroContainer
        image={MacBookImage}
        className="sm:flex-row min-h-fit h-screen xl:min-h-100"
        imgStyles={'mt-60 xl:mt-0 xl:-ml-[10%] xl:mt-5 xl:h-fit xl:max-w-3xl 2xl:max-w-4xl'}
      >
        <div
          className={twMerge(
            'w-full xl:w-2/3 flex items-end justify-center pb-10 xl:pb-24',
            isWhiteLabelProposal ? 'text-white-label-main' : 'text-primary-70 ',
          )}
        >
          <h1
            className={twMerge(
              'absolute md:left-20 w-8/12 font-extrabold font-poppins xl:max-w-sm',
              'text-heading-xlarge xl:text-display-large 2xl:text-display-xlarge',
            )}
          >
            Proposta de Gestão
          </h1>
        </div>
      </SectionProposalHeroContainer>
    </SlideContainer>
  );
}

export default ManagementProposal;
