import React from 'react';
import coverImage from '@assets/images/hands-on-lamp.png';
import clarkeLogo from '@assets/images/clarke-black-horizontal.png';
interface ICover {
  companyName?: string;
}

const Cover = ({ companyName }: ICover): JSX.Element => (
  <div className="w-full h-full flex flex-col-reverse justify-between bg-primary-60 sm:grid sm:grid-cols-8">
    <div className="w-full h-full sm:col-span-4 flex justify-between py-6 px-10 md:pr-6">
      <div className="h-full flex flex-col gap-8 justify-between">
        <div className="w-full h-fit">
          <h2 className="text-4xl font-normal leading-tight lg:text-display-medium">
            A Clarke vai ajudar a <strong>{companyName}</strong> a ser livre e economizar energia!
          </h2>
        </div>
        <img src={clarkeLogo} alt="clarke-logo" className="w-[10rem] lg:w-[16.25rem]" />
      </div>
    </div>
    <div className="w-full h-full sm:col-span-4 flex justify-between py-6 px-10 md:pr-6">
      <img src={coverImage} className="w-full h-full object-cover object-left rounded-2xl" />
    </div>
  </div>
);

export default Cover;
