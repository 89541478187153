import React from 'react';

const PremisesTableColumns = [
  {
    label: '',
    render: (value: React.FC): React.FC => value,
  },
];

export default PremisesTableColumns;
