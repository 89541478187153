import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import { ComboBoxInput } from './combobox-input';

export interface ComboBoxFormFieldProps<T extends FieldValues> {
  id: string;
  field: FieldPath<T>;
  label: string;
  placeholder?: string;
  maxMenuHeightInPx?: number;
  inputOptions: { label: string; value: string | number }[];
  options?: RegisterOptions<T>;
  transform?: {
    input?: (value: number | string) => number | string;
    output?: (value: number | string) => number | string;
  };
  onInputChange?: (newValue?: string) => void;
  isLoading?: boolean;
  onMenuScrollToBottom?: () => void;
}

export function ComboBoxFormField<T extends FieldValues>({
  field,
  id,
  inputOptions,
  placeholder,
  options,
  label,
  maxMenuHeightInPx,
  onInputChange,
  onMenuScrollToBottom,
  isLoading,
}: ComboBoxFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();
  const fieldErrors = get(errors, field)?.message as string | undefined;
  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value, ref } }) => {
        return (
          <ComboBoxInput
            ref={ref}
            maxMenuHeightInPx={maxMenuHeightInPx as number}
            name={name}
            id={id}
            label={label}
            inputOptions={inputOptions}
            value={value}
            error={fieldErrors}
            required={options?.required as boolean}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            maxMenuHeight={maxMenuHeightInPx}
            onInputChange={onInputChange}
            isLoading={isLoading}
            isDisabled={options?.disabled}
            onMenuScrollToBottom={onMenuScrollToBottom}
          />
        );
      }}
    />
  );
}
