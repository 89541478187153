import React from 'react';
import SemanticMarquee from './semantic-marquee';

interface ISimulationDocumentGenerationResult {
  errorMessage?: string;
  pdfLink?: string;
}

const SimulationDocumentGenerationResult: React.FC<ISimulationDocumentGenerationResult> = ({
  errorMessage,
  pdfLink,
}: ISimulationDocumentGenerationResult) => (
  <>
    {errorMessage && <SemanticMarquee kind="ERROR">Houve um erro ao gerar documento. Tente novamente.</SemanticMarquee>}
    {pdfLink && !errorMessage && (
      <SemanticMarquee kind="SUCCESS">
        <a href={pdfLink}>
          Documento gerado com sucesso! <strong>Clique aqui</strong> para Baixar
        </a>
      </SemanticMarquee>
    )}
  </>
);

export default SimulationDocumentGenerationResult;
