import { useEffect, useState } from 'react';
import { CLARKE_MANAGEMENT_PATH } from '@routers/constants';
import { useAuth } from '@src/auth-wrapper';

import ProposalPreviewDescriptionAndUsers from '@components/atoms/general/lead-proposals-hub/proposal-preview-description-and-users';
import CommercialDealerComissionTrading from '@components/atoms/general/lead-proposals-hub/commercial-dealer-comission-trading';
import LeadProposalsHubBodyWrapper from '@components/molecules/simulations-list/lead-proposals-hub-body-wrapper';

import useProductOne from '@hooks/use-product-one';
import useManagementContract from '@hooks/use-management-contract';

import { calculateProposalManagementFeeMonthly } from '@contexts/calculator/utils';
import { IProductOneProposal } from '@contexts/product-one/types';
import { IProposalPreviewBodyBase } from '@contexts/types';

import { ProductOneProposalKeyMetricsPanel } from './helper';

interface IProductOneProposalPreviewBody extends IProposalPreviewBodyBase {
  leadProposal: IProductOneProposal;
}

const ProductOneProposalPreviewBody = ({
  leadProposal,
  leadId,
  selectedProposalId,
  setSelectedProposalId,
  onClickDeleteProposal,
  feedbackNotificationsContent,
  isMainContent = false,
}: IProductOneProposalPreviewBody): JSX.Element => {
  const { user } = useAuth();
  const { updateSellerOfProductOneProposal, updateProductOneProposalCommercialDealerComission } = useProductOne();
  const { getManagementContracts } = useManagementContract();

  const [showComissionNegotiation, setShowComissionNegotiation] = useState<boolean>(false);
  const [isContractSigned, setIsContractsSigned] = useState(false);

  const isProposalComplete = leadProposal?.calculatorMetadata !== undefined;
  const commercialDealerComission = leadProposal?.calculatorMetadata.firstResult.commercialDealerComission;

  useEffect(() => {
    if (user) {
      const conditionsToEnableComissionNegotiation = user.isCommercialDealer && isMainContent && !isContractSigned;
      setShowComissionNegotiation(conditionsToEnableComissionNegotiation);
    }
  }, [user, isMainContent, isContractSigned]);

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const contractData = await getManagementContracts('PRODUCT_ONE', 'SIGNED', leadProposal.id);
        if (contractData.data.success) {
          if (contractData.data.data.total > 0) {
            setIsContractsSigned(true);
          }
        }
      } catch (erro) {
        setIsContractsSigned(false);
      }
    };

    fetchContracts();
  }, [leadProposal.id, getManagementContracts]);

  return (
    <LeadProposalsHubBodyWrapper
      isProposalComplete={isProposalComplete}
      simulationPreviewFooterProps={{
        simulatorType: 'CLARKE_MANAGEMENT',
        simulatorPath: CLARKE_MANAGEMENT_PATH,
        simulationId: leadProposal?.id,
        leadName: leadProposal?.id,
        inputsValuesToCopy: leadProposal?.inputs,
        leadId: leadId,
        selectedProposalId: selectedProposalId,
        setSelectedProposalId: setSelectedProposalId,
        onClickDeleteProposal: onClickDeleteProposal,
        feedbackNotificationsContent: feedbackNotificationsContent,
      }}
    >
      <>
        <ProposalPreviewDescriptionAndUsers
          proposalId={leadProposal.id}
          description={leadProposal.inputs.description}
          author={leadProposal.author}
          seller={leadProposal.seller}
          leadId={leadId}
          updateProposalSeller={updateSellerOfProductOneProposal}
        />
        <ProductOneProposalKeyMetricsPanel
          totalSaving={leadProposal.calculatorMetadata.firstResult.totalSaving}
          totalSavingPercentage={leadProposal.calculatorMetadata.firstResult.totalSavingPercentage}
          totalAverageConsumption={leadProposal.calculatorMetadata.firstResult.totalAverageConsumption}
          targetYear={leadProposal.calculatorMetadata.firstResult.targetYearGroup}
          energyFeeMonthly={leadProposal.calculatorMetadata.firstResult.energyFeeMonthlyByYear}
          carbonDioxideEmissionInTonsAvoided={
            leadProposal.calculatorMetadata.firstResult.totalCarbonDioxideEmissionInTonsAvoided
          }
          grossMerchandiseVolume={leadProposal.calculatorMetadata.firstResult.totalGrossMerchandiseVolume}
          lifetimeValue={leadProposal.calculatorMetadata.firstResult.totalLifetimeValue}
          managementFeeMonthly={calculateProposalManagementFeeMonthly(
            leadProposal.calculatorMetadata.firstResult.unitsReports,
          )}
          takeRate={leadProposal.calculatorMetadata.firstResult.totalTakeRate}
          isMainContent={isMainContent}
        />
        {showComissionNegotiation && (
          <CommercialDealerComissionTrading
            proposalId={leadProposal.id}
            commercialDealerComission={commercialDealerComission}
            updateProposalCommercialDealerComission={updateProductOneProposalCommercialDealerComission}
          />
        )}
      </>
    </LeadProposalsHubBodyWrapper>
  );
};
export default ProductOneProposalPreviewBody;
