import { useEffect, useState } from 'react';

import { ILeadProposalsHubBase } from '@contexts/types';
import { IProductTwoProposal } from '@contexts/product-two/types';

import useProductTwo from '@hooks/use-product-two';
import useLeadProposals from '@hooks/use-lead-proposals';

import { checkValidityPeriod } from '@utils/datetime';
import { LeadProposalsHubContainer } from '@components/atoms/general/lead-proposals-hub/container';
import LeadProposalsHubHeader from '@components/molecules/general/lead-proposals-hub/header';
import ProductTwoLeadProposalsHubHistory from '@components/molecules/product-two/lead-proposals-hub/history';
import ProductTwoProposalPreviewBody from '@components/molecules/product-two/lead-proposals-hub/body';

const ProductTwoLeadProposalsHub = ({
  setIsDrawerOpen,
}: Omit<ILeadProposalsHubBase, 'selectedProposalId' | 'setSelectedProposalId'>): JSX.Element => {
  const [selectedProposalId, setSelectedProposalId] = useState<string>();

  const { getProductTwoProposals, deleteProductTwoProposalsHandler } = useProductTwo();
  const { feedbackNotificationsContent, loading, leadProposals, leadId, alertMessage, onClickDeleteProposal } =
    useLeadProposals<IProductTwoProposal>(getProductTwoProposals, deleteProductTwoProposalsHandler);

  const mostRecentProposal = leadProposals[0];
  const isProposalValidity = checkValidityPeriod(mostRecentProposal?.inputs.proposalValidity);

  useEffect(() => {
    mostRecentProposal && setSelectedProposalId(mostRecentProposal.id);
  }, [mostRecentProposal]);
  return (
    <LeadProposalsHubContainer setIsDrawerOpen={setIsDrawerOpen} loading={loading} alertMessage={alertMessage}>
      <>
        <LeadProposalsHubHeader
          titleContent={mostRecentProposal?.lead.name}
          createdAt={mostRecentProposal?.createdAt}
          leadId={leadId}
          proposalId={selectedProposalId}
          isProposalValid={isProposalValidity}
        />
        <ProductTwoProposalPreviewBody
          leadProposal={mostRecentProposal}
          leadId={leadId}
          selectedProposalId={selectedProposalId}
          setSelectedProposalId={setSelectedProposalId}
          onClickDeleteProposal={onClickDeleteProposal}
          feedbackNotificationsContent={feedbackNotificationsContent}
          isMainContent
        />
        {leadProposals.length > 1 && (
          <ProductTwoLeadProposalsHubHistory
            leadId={leadId}
            selectedProposalId={selectedProposalId}
            setSelectedProposalId={setSelectedProposalId}
            proposalsList={leadProposals.slice(1)}
            onClickDeleteProposal={onClickDeleteProposal}
            feedbackNotificationsContent={feedbackNotificationsContent}
          />
        )}
      </>
    </LeadProposalsHubContainer>
  );
};

export default ProductTwoLeadProposalsHub;
