import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { SelectFormField } from '@components/atoms/form/fields/select-form-field';
import { IWhiteLabelSupplierPayload, WhiteLabelSupplierTypeEnum } from '@contexts/white-label/type';

export function WhiteLabelSupplierFormTypesSection() {
  return (
    <ContractSectionContainer>
      <div className="grid grid-cols-3 col-span-full min-w-full">
        <ContractTitleAndSubtitleSubsection
          title="Tipo de Fornecedora"
          subtitle="Informações sobre o tipo de fornecedora."
        />
        <div className="col-span-2 grid grid-cols-2 h-7">
          <SelectFormField<IWhiteLabelSupplierPayload>
            field="type"
            label="Tipos de fornecedora"
            id="supplier.whitelabel.type"
            inputOptions={Object.entries(WhiteLabelSupplierTypeEnum).map((guaranteeTypeEntry) => ({
              value: guaranteeTypeEntry[0],
              optionLabel: guaranteeTypeEntry[1],
            }))}
            options={{
              required: { value: true, message: 'Selecione o tipo de fornecedora' },
              validate: (value) => {
                return value !== '0' || 'Selecione o tipo de fornecedora';
              },
            }}
          />
        </div>
      </div>
    </ContractSectionContainer>
  );
}
