import { getGroupInfoTableData } from '@pages/detailed-analysis/helper';

import { DataTable } from '@components/atoms/detailedAnalysisGC/dataTable';

import { DropdownSection } from './dropdonw';

import { ISimulationResultBase } from '@contexts/types';
import { Paper } from '@mui/material';

export function GenerateTableGroupInfo({ proposalData }: { proposalData: ISimulationResultBase }) {
  const groupInfoTableData = getGroupInfoTableData(proposalData);
  return (
    <DropdownSection key={'group-info'} title="" iconName="OfficeBuildingIcon" iconColor="text-primary-70">
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <DataTable data={groupInfoTableData} />
      </Paper>
    </DropdownSection>
  );
}

export default GenerateTableGroupInfo;
