import * as uuid from 'uuid';
import { twMerge } from 'tailwind-merge';
import { HeroIconSolidOrOutline } from '@components/atoms/form/hero-icon';
import { parseValue } from './helper';

interface TableRowProps {
  row: (string | number)[];
  tagStyle: string;
  rowIndex: number;
}

export function TableRow({ row, tagStyle, rowIndex }: TableRowProps) {
  return (
    <tr
      id={`price-list-composition-data-tbody-${row[0]}-${rowIndex}`}
      key={`price-list-composition-data-tbody-${uuid.v4()}`}
      className="hover:bg-gray-50"
    >
      {row?.map((cell, index) => (
        <td
          id={`price-list-composition-data-td-body-${row[0]}${cell}`}
          key={`price-list-composition-data-td-body-${uuid.v4()}`}
          className="py-2 px-4 border border-gray-300 text-left"
        >
          <div className={index === 1 ? tagStyle : ''}>
            <span className="flex items-center gap-5">
              {index === 1 && tagStyle.includes('text-danger-60') && (
                <HeroIconSolidOrOutline icon="TrendingDownIcon" className={twMerge(tagStyle, 'size-5')} />
              )}
              {index === 1 && tagStyle.includes('text-primary-60') && (
                <HeroIconSolidOrOutline icon="TrendingUpIcon" className={twMerge(tagStyle, 'size-5')} />
              )}
              {parseValue(cell)}
            </span>
          </div>
        </td>
      ))}
    </tr>
  );
}
