import { ISemanticMarquee } from '@components/atoms/general/semantic-marquee';

const loadingMessage = 'Aguarde. As simulações estão sendo geradas.';
const successMessage = 'Todas as simulações foram geradas com sucesso.';
const errorMessage = 'Um erro ocorreu durante a geração de uma ou mais simulações.';

export const getFeedbackMessageAndKind = (
  success?: boolean,
  loading?: boolean,
  formErrorsMessage?: string,
): {
  feedbackMessage: string | undefined;
  feedbackKind: ISemanticMarquee['kind'] | undefined;
} => {
  if (loading) return { feedbackMessage: loadingMessage, feedbackKind: 'NEUTRAL' };
  else if (success === true) return { feedbackMessage: successMessage, feedbackKind: 'SUCCESS' };
  else if (success === false) return { feedbackMessage: errorMessage, feedbackKind: 'ERROR' };
  else if (formErrorsMessage) return { feedbackMessage: formErrorsMessage as string, feedbackKind: 'ERROR' };
  else return { feedbackMessage: undefined, feedbackKind: undefined };
};
