import { twMerge } from 'tailwind-merge';
import NoteDesktopImage from '@assets/newProposal/05. Notebook 2_Mockup.png';
import MacDeskTopImage from '@assets/newProposal/macbook.png';
import useWhiteLabel from '@hooks/use-white-label';
import SectionProposalHeroContainer from '@components/atoms/new-proposal/hero-container/hero';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import './styles.css';

function FeasibilityAnalysis() {
  const { isWhiteLabelProposal } = useWhiteLabel();
  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const managementStyles = 'w-full xl:w-fit p-10 mt-100 xl:mt-40 2xl:mt-0 xl:-ml-5';
  const retailStyles = 'w-full mt-100 md:mt-40 xl:mt-0 2xl:max-w-screen-lg p-10 xl:-ml-5';
  return (
    <SlideContainer className={twMerge(isWhiteLabelProposal && 'xl:pt-5')}>
      <SectionProposalHeroContainer
        className="min-h-fit h-screen xl:min-h-100"
        image={isManagementPath ? NoteDesktopImage : MacDeskTopImage}
        imgStyles={isManagementPath ? managementStyles : retailStyles}
      >
        <div className="div-analysis-container-styles  max-w-screen-lg">
          {isWhiteLabelProposal ? (
            <h1 className="absolute sm:left-10 text-heading-xlarge xl:text-display-large 2xl:text-display-xlarge font-bold max-w-60 xl:max-w-[40%] 2xl:max-w-[30%] 2xl:ml-10  text-white-label-main">
              Análise de Viabilidade
            </h1>
          ) : (
            <h1 className="text-analysis-title-styles xl:w-fit 2xl:w-10/12">
              {isManagementPath ? 'Análise de Viabilidade' : 'Projeção de Economia'}
            </h1>
          )}
        </div>
      </SectionProposalHeroContainer>
    </SlideContainer>
  );
}

export default FeasibilityAnalysis;
