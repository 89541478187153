import { useAuth } from '@src/auth-wrapper';
import { Navigate, Outlet } from 'react-router-dom';

type UserRoles = 'isSuperAdmin' | 'isAdmin' | 'isCommercialDealer' | 'isWhiteLabel';

interface ProtectedRoutesProps {
  permissionKeys: UserRoles[];
  redirectTo: string;
}

export function ProtectedRoutes({ permissionKeys, redirectTo }: ProtectedRoutesProps) {
  const { user } = useAuth();
  const hasPermission = permissionKeys.some((permissionKey) => user && user[permissionKey]);
  return hasPermission ? <Outlet /> : <Navigate to={redirectTo} />;
}
