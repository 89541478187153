import { isNumber } from 'lodash';
import { formatNumber } from '@utils/numeral';

export enum DescriptionColunm {
  value = 'Valor',
  consumptionOffPeak = 'Consumo fora ponta (kWh)',
  consumptionPeak = 'Consumo ponta (kWh)',
  label = 'Item',
}

export function parseValue(value: string | number | null | undefined) {
  if (typeof value === 'string') {
    return value;
  }
  if (isNumber(value)) {
    return formatNumber(value, '0,0.00');
  }
  return '-';
}
