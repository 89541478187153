import Skeleton from 'react-loading-skeleton';

const TableSkeleton = (): JSX.Element => {
  return (
    <div className="flex gap-4 w-full">
      {Array(2)
        .fill({})
        .map((_, columnIndex) => (
          <div
            className={`flex flex-col gap-5 ${columnIndex === 0 ? 'w-2/3' : 'w-1/3'}`}
            key={`table-skeleton-column-${columnIndex}`}
          >
            <Skeleton containerClassName="w-full" width="100%" height={20} />
            <div className="flex flex-col gap-3">
              {Array(10)
                .fill({})
                .map((_, rowIndex) => (
                  <Skeleton
                    containerClassName="w-full"
                    key={`table-skeleton-column-${columnIndex}-row-${rowIndex}`}
                    width="100%"
                    height={20}
                  />
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default TableSkeleton;
