import { useContextSelector } from 'use-context-selector';

import { SalesContext } from '@contexts/sales';
import { ISalesContext } from '@contexts/sales/types';

const useSales = (): ISalesContext => {
  const convertHtmlToPdfHandler = useContextSelector(SalesContext, (sales) => sales.convertHtmlToPdfHandler);

  return {
    convertHtmlToPdfHandler,
  };
};

export default useSales;
