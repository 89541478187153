import { Accordion } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';
import { formatDate } from '@utils/datetime';

interface ILeadProposalsHubHistory {
  children: JSX.Element;
}

export const LeadProposalsHubHistory = ({ children }: ILeadProposalsHubHistory) => {
  return (
    <div className="flex flex-col gap-5 p-2">
      <h4 className="text-paragraph-medium font-bold mt-11">Histórico</h4>
      {children}
    </div>
  );
};

interface ILeadProposalsHubHistoryAccordion {
  children: JSX.Element;
  id: string;
  sellerName?: string;
  createdAt?: string;
}

export const LeadProposalsHubHistoryAccordion = ({
  children,
  id,
  sellerName,
  createdAt,
}: ILeadProposalsHubHistoryAccordion) => {
  const { user } = useAuth();

  return (
    <Accordion
      key={id}
      descriptionElement={
        !user?.isCommercialDealer && (
          <div className="flex items-center gap-4">
            <div className="text-neutral-50 text-paragraph-small">{sellerName}</div>
          </div>
        )
      }
      label={`Proposta ${formatDate(createdAt, 'DD/MM/YYYY - HH:mm')}`}
    >
      <div className="border rounded-small border-neutral-60 p-5">{children}</div>
    </Accordion>
  );
};
