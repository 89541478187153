import { twMerge } from 'tailwind-merge';

export type IGadgetNumberedContent = {
  titleText: string;
  text: string;
  isOnPrintMode?: string;
};

type IBoxContent = {
  boxContent: IGadgetNumberedContent;
  index: number;
  isOnPrintMode?: boolean;
};

type IBoxesContent = {
  boxesContent: Array<IGadgetNumberedContent>;
  isOnPrintMode?: boolean;
};

const GadgetNumberedBox = ({ boxContent, index, isOnPrintMode }: IBoxContent) => {
  const boxContainerColor = index === 0 && 'border-[1px] border-primary-60';
  const boxContainerHeight = isOnPrintMode
    ? 'h-[16rem]'
    : 'h-full sm:min-h-[12.56rem] lg:min-h-[14.5rem] xl:min-h-[15.9rem]';
  const boxContainerClassDynamic = [boxContainerColor, boxContainerHeight].join(' ');
  const boxContainerClassFixed = 'col-span-1 bg-neutral-10 rounded-lg p-6 flex flex-col justify-between shadow-3';

  const indexDescriptionClassDynamic = `${index === 0 && 'text-primary-60'}`;
  const indexDescriptionClass = `text-display-xlarge ${indexDescriptionClassDynamic}`;

  return (
    <div className={twMerge(boxContainerClassFixed, boxContainerClassDynamic)}>
      <p className={indexDescriptionClass}>{index + 1}</p>
      <div className="flex flex-col justify-end gap-2">
        <p className="font-normal text-neutral-50 text-paragraph-small">{boxContent.titleText}</p>
        <p className="text-heading-xsmall">{boxContent.text}</p>
      </div>
    </div>
  );
};

export const GadgetNumbered = ({ boxesContent, isOnPrintMode }: IBoxesContent) => {
  return (
    <div className="grid-cols-1 lg:grid-cols-3 xl:grid-cols-5 p-8 grid gap-6 w-full h-fit">
      {boxesContent.map((boxContent, index) => (
        <GadgetNumberedBox
          key={`product-two-gadget-numbered-${boxContent.text}-${index}`}
          boxContent={boxContent}
          index={index}
          isOnPrintMode={isOnPrintMode}
        />
      ))}
    </div>
  );
};
