import React from 'react';
import { Layout, LayoutProps } from '@clarke-energia/foton';

import { HOME_PATH } from '@routers/constants';

import { ContractGeneratorTypeEnum } from '@contexts/types';

interface IContractLayoutBase<T> extends LayoutProps {
  children: React.ReactNode;
  contractType: keyof typeof ContractGeneratorTypeEnum;
  contractFlow?: 'CONTRACT_GENERATOR_FORM';
}

const ContractLayoutBase = <T,>({
  children,
  contractType,
  contractFlow,
  ...layoutProps
}: IContractLayoutBase<T>) => {

  const baseLinks = [
    {
      label: 'Início',
      url: HOME_PATH,
    },
    {
      label: ContractGeneratorTypeEnum[contractType],
      url: '#',
    },
  ];

  const contractFormLinks =
    contractFlow === 'CONTRACT_GENERATOR_FORM'
      ? [
          {
            label: 'Criar Contrato',
            url: '#',
          },
        ]
      : [];

  const navigationHistoryLinks: LayoutProps['navigationHistoryLinks'] = [...baseLinks, ...contractFormLinks];


  return (
    <Layout
      {...layoutProps}
      navigationHistoryLinks={navigationHistoryLinks}
      darkerBackground
      className="h-full overflow-y-hidden pb-16"
    >
      <div className="col-span-full h-full p-2 md:p-5 overflow-y-auto">{children}</div>
    </Layout>
  );
};

export default ContractLayoutBase;