import React from 'react';
import { FeedbackNotification } from '@clarke-energia/foton';
import { useForm, FormProvider } from 'react-hook-form';

import { useAuth } from '@src/auth-wrapper';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { getCalculatorName, isObjectEmpty, parseStringToBoolean } from '@utils/common';
import {
  compareAndGetProposalValidity,
  getSimulatorInitialValues,
} from '@components/molecules/form/simulator/default-values-getters';

import {
  enforceUnitsManagementPriceSuggestion,
  parseUnitsConsumptionHistory,
} from '@contexts/calculator/units-payload-handlers';
import { IProposalUser, ISimulationUnitPayloadBase } from '@contexts/types';
import { IProductTwoSimulatorInputs } from '@contexts/product-two/types';

import useCustomClipboard from '@hooks/use-custom-clipboard';
import useProductTwo from '@hooks/use-product-two';

import ProductTwoSimulatorFormCore from '@components/organisms/product-two/simulator/product-two-simulator-form-core';
import SimulatorLayoutBase from '@components/organisms/simulator-layout-base';
import { CalculatorFormFooter } from '@components/molecules/calculator-form-footer';

interface IProductTwoSimulatorForm {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductTwoSimulatorForm = ({ setOpenModal, setSuccessModal }: IProductTwoSimulatorForm) => {
  const { CopyFormValues, PasteFormValues, customClipboardFeedbackNotificationContent } = useCustomClipboard();
  const { createProductTwoSimulation } = useProductTwo();

  const formRef = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const formController = useForm<IProductTwoSimulatorInputs>({
    defaultValues: getSimulatorInitialValues('CLARKE_RETAIL') as IProductTwoSimulatorInputs,
    mode: 'all',
  });
  const {
    watch,
    reset,
    formState: { errors },
  } = formController;
  const formValues = watch();

  const { user } = useAuth();
  const authorInfo =
    user &&
    ({
      id: user.id,
      name: user.name,
      pictureUrl: user.pictureUrl,
    } as IProposalUser);

  const onSubmit = async (values: IProductTwoSimulatorInputs) => {
    setLoading(true);
    const manualAclPrice = values.manualAclPrice;
    if (manualAclPrice && Object.keys(manualAclPrice).length === 0) {
      values.manualAclPrice = undefined;
    }

    const parsedUnits = parseUnitsConsumptionHistory(values.units) as ISimulationUnitPayloadBase[];
    const unitsWithEnforcedManagementPriceSuggestion = enforceUnitsManagementPriceSuggestion(
      parsedUnits,
    ) as ISimulationUnitPayloadBase[];

    const sellerValue = values.seller?.id === undefined ? authorInfo : values.seller;

    const finalValues: IProductTwoSimulatorInputs = {
      ...values,
      units: user?.isCommercialDealer ? unitsWithEnforcedManagementPriceSuggestion : parsedUnits,
      proposalValidity: compareAndGetProposalValidity(values.proposalValidity),
      manualAclPricesHasChargesCoverage:
        values.manualAclPricesHasChargesCoverage &&
        parseStringToBoolean(values.manualAclPricesHasChargesCoverage as unknown as string),
      author: authorInfo,
      seller: sellerValue,
    };

    CopyFormValues(finalValues, false, false);

    return createProductTwoSimulation(finalValues)
      .then(() => {
        setOpenModal(true);
        setSuccessModal(true);
        eventTracker.trackEvent(eventLabels.FORM_SUBMITTED, {
          form: getCalculatorName(location.href),
          user_groups: user?.groups ? user?.groups.join(', ') : '',
          user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
        });
      })
      .catch((error) => {
        setSuccessModal(false);
        eventTracker.trackEvent(eventLabels.ERROR_MESSAGE_WHEN_CALCULATION_FAILS, {
          product: getCalculatorName(location.href),
          user_groups: user?.groups ? user?.groups.join(', ') : '',
          user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
        });

        alert('Ocorreu um erro. Verifique o formulário e tente novamente.');

        throw error;
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (location.href.includes('refazer-calculo')) {
      PasteFormValues(reset, 'CLARKE_RETAIL', false);
    } else {
      eventTracker.trackEvent(eventLabels.PAGE_VIEW_SIMULATOR, {
        product: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
      eventTracker.trackEvent(eventLabels.FORM_STARTED, {
        form: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
    }
  }, []);

  return (
    <SimulatorLayoutBase<IProductTwoSimulatorInputs>
      title="Simulador"
      simulatorType="CLARKE_RETAIL"
      simulatorFlow="SIMULATOR_FORM"
      setFormValues={reset}
      formValues={formValues}
    >
      <div className="col-span-full flex flex-col w-full lg:w-3/4 p-7">
        <FormProvider {...formController}>
          {customClipboardFeedbackNotificationContent && (
            <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
              <FeedbackNotification {...customClipboardFeedbackNotificationContent} />
            </div>
          )}
          <ProductTwoSimulatorFormCore {...{ formRef, onSubmit, formController }} simulatorType="CLARKE_RETAIL" />
          <CalculatorFormFooter error={!isObjectEmpty(errors)} {...{ formRef, loading, onSubmit }} />
        </FormProvider>
      </div>
    </SimulatorLayoutBase>
  );
};

export default ProductTwoSimulatorForm;
