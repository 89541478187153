import { SuppliersProjectionsHistoryTableButtonsColumn } from './buttons-column';
import { SuppliersProjectionsHistoryTableHead } from './head';
import { SuppliersProjectionsHistoryTableResponsibleColumn } from './responsible-column';
import { SuppliersProjectionsHistoryTableTagColumn } from './tag-column';

export const SuppliersProjectionsHistoryTableAtoms = {
  Head: SuppliersProjectionsHistoryTableHead,
  ButtonsColumn: SuppliersProjectionsHistoryTableButtonsColumn,
  ResponsibleColumn: SuppliersProjectionsHistoryTableResponsibleColumn,
  TagColumn: SuppliersProjectionsHistoryTableTagColumn,
};
