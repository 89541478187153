import numeral from 'numeral';
import { IEconomyChart } from '.';

const buildChartWithLabelsData = ({
  savingValues,
  targetValues,
  currentValues,
  alternativeBarsColor,
  labels,
}: IEconomyChart) => ({
  labels: labels,
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        grace: 80,
        beginAtZero: true,
        position: 'bottom',
        grid: {
          display: false
        },
        font: {
          size: '10px', 
        },

      },
      'livre-y-axis': {
        display: true,
        grace: 80,
        beginAtZero: true,
        position: 'left',
        grid: {
          display: false
        },
        ticks: {
          min: 0, 
          callback: function (value: any) {
            return value>=1000000 ? numeral(value).format('$ 0.0a') : numeral(value).format('$ 0,0') ; // Customize the tick labels
          },}
      },
      'economia-y-axis': {
        display: false,
        grace: 80,
        beginAtZero: true,
        type: 'linear',
        position: 'right',
        ticks: {
          callback(value: any, _index: any, _ticks: any) {
            return `${value} %`;
          },
        },
      },
    },
    plugins: {
      datalabels: {
        padding: 12,
        borderRadius: 30,
        backgroundColor: 'white',
        color: 'black',
        borderWidth: 1,
        borderColor: 'black',
        formatter(value: any) {
          return `${value}%`;
        },
      },
      legend: {
        display: false,
      },
    },
  },
  datasets: [
    {
      yAxisID: 'economia-y-axis',
      type: 'line',
      label: 'Economia (%)',
      borderColor: '#000',
      borderWidth: 2,
      fill: false,
      data: savingValues,
    },
    {
      type: 'bar',
      label: 'Mercado de Origem',
      backgroundColor: alternativeBarsColor ? '#A3F5A7' : '#404040',
      data: currentValues,
      borderColor: 'white',
      borderWidth: 2,
      datalabels: {
        labels: {
          title: null,
        },
      },
    },
    {
      yAxisID: 'livre-y-axis',
      type: 'bar',
      label: 'Mercado Alvo',
      backgroundColor: alternativeBarsColor ? '#008C4C' : '#00DF7C',
      data: targetValues,
      datalabels: {
        labels: {
          title: null,
        },
      },
    },
  ],
});

export default buildChartWithLabelsData;
