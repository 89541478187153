import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { twMerge } from 'tailwind-merge';

import { addWorkdays, startDate } from '@utils/datetime';

import staticDataStepsTimeline from './static';
import StepTimeline, { IStep } from './steps';

dayjs.extend(customParseFormat);

interface ITableTimelineProps {
  className?: string;
}
const TableTimeline: React.FC<ITableTimelineProps> = ({ className }) => {
  const stepsWithDates = staticDataStepsTimeline.map((step: IStep) => {
    const endDate = addWorkdays(startDate(), step.stepDays).format('DD/MM/YYYY');
    return { ...step, date: endDate };
  });

  return (
    <table className={twMerge('table-auto', className)}>
      <tbody>
        {stepsWithDates.map((step, index) => {
          const isLastIndex = index === stepsWithDates.length - 1;
          return <StepTimeline key={step.description} step={step} index={index} isLastIndex={isLastIndex} />;
        })}
      </tbody>
    </table>
  );
};

export default TableTimeline;
