import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';

import config from '@config';

import ContractLayoutBase from '@components/organisms/general/management-contract/management-contract-layout-base';
import ContractContent from '@components/organisms/general/management-contract/management-contract-content';
import {
  IManagementContractPayload,
  IManagementContractProduct,
  IManagementContractSignatory,
} from '@contexts/management-contract/types';
import useManagementContract from '@hooks/use-management-contract';

interface IManagementContractForm {
  product: IManagementContractProduct;
}

const ManagementContractForm = ({ product }: IManagementContractForm): JSX.Element => {
  const clarkeSignatory: IManagementContractSignatory = {
    fullName: config.CLARKE_SIGNATURE_NAME,
    email: config.CLARKE_SIGNATURE_EMAIL,
    identityNumber: config.CLARKE_SIGNATURE_IDENTITY,
    cpfNumber: config.CLARKE_SIGNATURE_CPF,
  };

  const [hasPreviousDraft, setHasPreviousDraft] = React.useState<boolean>(false);

  const { handleSetFormDefaultValues } = useManagementContract();

  const formController = useForm<IManagementContractPayload>({
    defaultValues: {
      attachments: [],
      additionalServices: {
        changeOfOwnership: undefined,
        demandAdjustment: undefined,
        tariffModalityChange: undefined,
      },
      inputs: {
        signatories: {
          customerSignatories: [{}],
          clarkeSignatory: clarkeSignatory,
        },
        units: [{}],
      },
    },
    mode: 'all',
  });
  const { reset, setValue } = formController;

  const { user } = useAuth0();
  const idProperty = 'https://clarke.com.br/uuid';

  const [searchParams] = useSearchParams();
  const proposalId = searchParams.get('proposalId');
  const setProposalIdAndUserAtForm = () => {
    setValue('proposalId', proposalId as string);
    setValue('product', product);

    if (user) {
      setValue('user.id', user[idProperty]);
      setValue('user.name', user.name);
      setValue('user.email', user.email);
      setValue('user.pictureUrl', user.picture);
    }
  };

  React.useEffect(() => {
    user && proposalId && handleSetFormDefaultValues(user[idProperty], proposalId, setHasPreviousDraft, reset);
    setProposalIdAndUserAtForm();
  }, []);

  return (
    <ContractLayoutBase title="Criar Contrato" contractType={product} contractFlow="CONTRACT_GENERATOR_FORM">
      <ContractContent
        formController={formController}
        contractProduct={product}
        hasPreviousDraft={hasPreviousDraft}
        setHasPreviousDraft={setHasPreviousDraft}
      />
    </ContractLayoutBase>
  );
};

export default ManagementContractForm;
