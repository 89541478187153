import React from 'react';
import HomeLink from '@components/atoms/general/home-link';

import { NavigationLinkProps } from '@clarke-energia/foton';

interface IHomeLinkSection {
  title: string;
  links: Array<NavigationLinkProps>;
  isCommercialDealer?: boolean;
}

const HomeLinkSection = ({ title, links, isCommercialDealer }: IHomeLinkSection) => (
  <div className="links-section">
    <h2 className="links-section-title">{title}</h2>
    <div className="links-section-items">
      {links.map((item) => {
        return (
          <HomeLink key={item.label} item={item} externalLink={item.external} isCommercialDealer={isCommercialDealer} />
        );
      })}
    </div>
  </div>
);

export default HomeLinkSection;
