import React from 'react';

export interface IAlertMessage {
  kind: ISemanticMarquee['kind'];
  content: string;
}

export interface ISemanticMarquee {
  kind: 'WARNING' | 'ERROR' | 'NEUTRAL' | 'SUCCESS';
  children: React.ReactNode;
}

const SemanticMarquee: React.FC<ISemanticMarquee> = ({ kind, children }: ISemanticMarquee) => {
  const colorKeyIndexer: Record<ISemanticMarquee['kind'], string> = {
    WARNING: 'warning',
    ERROR: 'danger',
    NEUTRAL: 'blue',
    SUCCESS: 'primary',
  };

  const colorClassName = `bg-${colorKeyIndexer[kind]}-10 text-${colorKeyIndexer[kind]}-80 border-${colorKeyIndexer[kind]}-60`;
  return <div className={`w-full text-paragraph-medium border-1 rounded-medium p-5 ${colorClassName}`}>{children}</div>;
};

export default SemanticMarquee;
