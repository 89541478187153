import React from 'react';

const NotFound404: React.FC = () => {
  return (
    <div className="flex overflow-y-auto flex-col justify-center items-center h-screen align-middle md:flex-row md:px-36">
      <div className="p-3 m-3 text-9xl font-extrabold text-right text-primary-60-green sm:text-180">404</div>
      <div className="flex flex-col justify-start items-start pl-10 mx-20 text-left align-middle border-l-2 border-clarke-green">
        <div className="font-semibold text-heading-xlarge">Página não encontrada...</div>
        <div className="mt-2 w-full text-base font-light text-neutral-60">
          Parece que não conseguimos encontrar a página que você estava procurando. Retorne à pagina anterior ou entre
          em contato conosco para solucionarmos esse problema.
        </div>
      </div>
    </div>
  );
};

export default NotFound404;
