import TabletImg from '@assets/newProposal/tablet.png';
import useWhiteLabel from '@hooks/use-white-label';
import SectionProposalHeroContainer from '@components/atoms/new-proposal/hero-container/hero';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import './styles.css';

function AboutClarkeHeroSection() {
  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const { isWhiteLabelProposal } = useWhiteLabel();
  return (
    <SlideContainer>
      <SectionProposalHeroContainer
        image={TabletImg}
        className="sm:flex-row min-h-fit h-screen xl:min-h-100"
        imgStyles="about-section-styles mt-40 xl:mt-0"
      >
        <div className="about-text-container">
          {isWhiteLabelProposal ? (
            <h1 className="absolute left-32 xl:left-60 max-w-40 xl:max-w-[30%] text-heading-xlarge -ml-20  xl:text-display-large 2xl:text-display-xlarge font-bold text-white-label-main">
              Sobre a Migração
            </h1>
          ) : (
            <>
              {isManagementPath ? (
                <h1 className="text-container-tittle">Sobre a Clarke</h1>
              ) : (
                <span className="text-container-tittle w-full max-w-lg">
                  Sobre o <p className="whitespace-nowrap">Clarke Varejo</p>
                </span>
              )}
            </>
          )}
        </div>
      </SectionProposalHeroContainer>
    </SlideContainer>
  );
}

export default AboutClarkeHeroSection;
