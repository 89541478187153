import React from 'react';

import { IProductOneProposal } from '@contexts/product-one/types';
import useProductOne from '@hooks/use-product-one';

import LeadsHistory from '@components/organisms/general/leads-history';
import ProductOneLeadProposalsHub from '@components/organisms/product-one/lead-proposals-hub';

const ProductOneLeadsHistory = (): JSX.Element => {
  const { deleteProductOneAllProposalsByLeadIdHandler } = useProductOne();

  const [selectedLeadId, setSelectedLeadId] = React.useState<string>();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  return (
    <LeadsHistory<IProductOneProposal>
      simulatorType="CLARKE_MANAGEMENT"
      deleteAllProposalsByLeadIdHandler={deleteProductOneAllProposalsByLeadIdHandler}
      selectedLeadId={selectedLeadId}
      setSelectedLeadId={setSelectedLeadId}
      setIsDrawerOpen={setIsDrawerOpen}
    >
      <>
        {isDrawerOpen && selectedLeadId && (
          <ProductOneLeadProposalsHub setIsDrawerOpen={setIsDrawerOpen} leadId={selectedLeadId} />
        )}
      </>
    </LeadsHistory>
  );
};

export default ProductOneLeadsHistory;
