import React from 'react';
import { Button } from '@clarke-energia/foton';

import { GENERAL_CALCULATOR_PATH } from '@routers/constants';
import { SimulatorTypeEnum } from '@contexts/types';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import useCustomClipboard from '@hooks/use-custom-clipboard';

import { getCalculatorName } from '@utils/common';

import CreateProposalDocumentButton from '@components/atoms/general/create-proposal-document-button';
import SimulationDocumentGenerationResult from '@components/atoms/general/simulation-document-generation-result';
import { useAuth } from '@src/auth-wrapper';

interface ISimulatorResultFooter {
  simulatorType: keyof typeof SimulatorTypeEnum;
  simulatorPath: string;
  simulationId?: string;
  loading?: boolean;
  leadName?: string;
  calculationInputs?: Record<string, any>;
  generateSimulation?: () => void;
  setModalGenerateNewSimulationOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SimulatorResultFooter = ({
  simulatorType,
  simulatorPath,
  simulationId,
  loading,
  leadName,
  calculationInputs,
  generateSimulation,
  setModalGenerateNewSimulationOpen,
}: ISimulatorResultFooter): JSX.Element => {
  const { CopyFormValues } = useCustomClipboard();
  const { user } = useAuth();

  const simulationUrl = simulationId && `${simulatorPath}/${simulationId}`;

  const [pdfLink, setPdfLink] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const handleRemakeSimulationButtonClick = (calculationInputs: Record<string, any>, simulatorPath: string) => {
    CopyFormValues(calculationInputs);
    const redoSimulationUrl = `${simulatorPath}/${GENERAL_CALCULATOR_PATH}?refazer-calculo`;
    location.href = redoSimulationUrl;
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-start w-full md:w-2/3 gap-4">
        {simulationUrl ? (
          <div className="flex flex-col gap-2">
            <Button
              kind="primary"
              type="button"
              label="Ver Proposta"
              onClick={() => window.open(simulationUrl, '_blank', 'noopener,noreferrer')}
            />
            {leadName && (
              <CreateProposalDocumentButton
                kind="secondary"
                simulatorType={simulatorType}
                {...{ simulationUrl, leadName, setPdfLink, setErrorMessage }}
              />
            )}
          </div>
        ) : (
          generateSimulation && (
            <Button
              kind="primary"
              type="button"
              loading={loading}
              label="Gerar Proposta"
              onClick={() => {
                generateSimulation && generateSimulation();
                eventTracker.trackEvent(eventLabels.CALCULATION_RESULT_PAGE_CLICK_ON_GENERATE_SIMULATION, {
                  product: getCalculatorName(location.href),
                  user_groups: user?.groups ? user?.groups.join(', ') : '',
                  user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
                });
              }}
            />
          )
        )}
        {setModalGenerateNewSimulationOpen !== undefined && (
          <Button
            kind="primary"
            label="Reaproveitar Cálculo"
            onClick={() => {
              setModalGenerateNewSimulationOpen(true);
              eventTracker.trackEvent(eventLabels.CALCULATION_RESULT_PAGE_CLICK_ON_REUSE_CALCULUS, {
                product: getCalculatorName(location.href),
                user_groups: user?.groups ? user?.groups.join(', ') : '',
                user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
              });
            }}
            type="button"
          />
        )}
        {calculationInputs && (
          <Button
            kind="secondary"
            label="Refazer Cálculo"
            onClick={() => {
              handleRemakeSimulationButtonClick(calculationInputs, simulatorPath);
              eventTracker.trackEvent(eventLabels.CALCULATION_RESULT_PAGE_CLICK_ON_REDO_CALCULUS, {
                product: getCalculatorName(location.href),
                user_groups: user?.groups ? user?.groups.join(', ') : '',
                user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
              });
            }}
          />
        )}
        <Button
          kind="secondary"
          label="Ir para o Histórico"
          onClick={() => (location.href = `${simulatorPath}/lista`)}
        />
      </div>
      <div className="w-1/2 mb-12 mt-5">
        <SimulationDocumentGenerationResult pdfLink={pdfLink} errorMessage={errorMessage} />
      </div>
    </div>
  );
};
