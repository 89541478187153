import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Modal } from '@clarke-energia/foton';

import { GenericFormField } from '@components/atoms/form/fields/generic-form-field';

import { ILeadPayLoad } from '@contexts/lead/types';

interface IModalRegisterNewLead {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCreateLead: (name: string) => Promise<void>;
}

const ModalRegisterNewLead = ({
  isModalOpen,
  setIsModalOpen,
  handleCreateLead,
}: IModalRegisterNewLead): JSX.Element => {
  const formController = useForm<ILeadPayLoad>();
  const { handleSubmit } = formController;

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleCreateLeadOnClick: SubmitHandler<ILeadPayLoad> = async ({ name }) => {
    setLoading(true);
    handleCreateLead(name).finally(() => setLoading(false));
  };

  const validateCommercialGroupName = (value?: string) => {
    if (!value) return false;

    const forbiddenChars = /[/*!#?$%&'"]/;
    return !forbiddenChars.test(value);
  };

  return (
    <Modal
      className="md:w-96 md:h-[22.25rem]"
      open={isModalOpen}
      setOpen={() => setIsModalOpen(false)}
      title="Novo Lead"
      description="Não encontrou o grupo comercial que procura? Cadastre-o aqui."
      modalButtons={{
        primary: {
          label: 'Cadastrar',
          type: 'submit',
          style: { backgroundColor: 'primary', width: '100%' },
          onClick: handleSubmit(handleCreateLeadOnClick),
          loading,
          disabled: loading,
        },
        secondary: { label: 'Cancelar', style: { width: '100%' }, onClick: () => setIsModalOpen(false) },
      }}
    >
      <FormProvider {...formController}>
        <form>
          <div>
            <GenericFormField<ILeadPayLoad>
              id="name"
              field="name"
              label="Grupo Comercial"
              placeholder="Nome do lead ou cliente"
              options={{
                required: { value: true, message: 'O nome do grupo comercial é obrigatório' },
                validate: (value) =>
                  validateCommercialGroupName(value) || "Nome não pode conter os caracteres: / * ! ? # $ % & ' ",
              }}
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ModalRegisterNewLead;
