import React from 'react';
import ResponsiveHeaderLine from '@components/atoms/simulation/responsive-header-line';

interface IGreenSectionSlide {
  title: string;
}

const GreenSectionSlide = ({ title }: IGreenSectionSlide): JSX.Element => (
  <div className="w-full h-full bg-primary-60 pt-10 px-10 pb-13 lg:pb-[18.75rem] xl:pb-[30rem]">
    <div className="sm:w-1/2">
      <ResponsiveHeaderLine color="white" />
      <h2 className="text-heading-xlarge font-bold text-white mt-5 sm:text-display-small">{title}</h2>
    </div>
  </div>
);

export default GreenSectionSlide;
