import React from 'react';

interface TableItem {
  title: string;
  description: string;
}

const TableHeaderRow = ({ title, description }: TableItem) => (
  <tr className="border-t-2 border-neutral-30 bg-neutral-10">
    <td className="font-bold sm:text-heading-small xl:text-heading-medium w-1/3 pr-3 pt-4 pb-5">{title}</td>
    <td className="text-paragraph-large font-normal xl:text-heading-xsmall w-2/3 pl-3 pt-4 pb-5">{description}</td>
  </tr>
);

const TableBodyRow = ({ title, description }: TableItem) => (
  <tr className="border-y-1 border-neutral-30">
    <td className="sm:text-paragraph-large font-bold w-1/3 pr-3 pt-4 pb-5">{title}</td>
    <td className="text-paragraph-large font-normal w-2/3 pl-3 pt-4 pb-5">{description}</td>
  </tr>
);

interface ManagementBillingTableCoreProps {
  migrationTableData: Array<TableItem>;
}

const ManagementBillingTableCore: React.FC<ManagementBillingTableCoreProps> = ({ migrationTableData }) => {
  return (
    <table>
      <thead>
        <TableHeaderRow {...migrationTableData[0]} />
      </thead>

      <tbody>
        {migrationTableData.slice(1).map((TableItem) => (
          <TableBodyRow key={`migration-table-data-row-${TableItem.title}`} {...TableItem} />
        ))}
      </tbody>
    </table>
  );
};

export interface ManagementBillingTableProps {
  preMigrationTableData: TableItem[];
  postMigrationTableData: TableItem[];
  enableTotalMigrationCost?: boolean;
}

const ManagementBillingTable: React.FC<ManagementBillingTableProps> = ({
  preMigrationTableData,
  postMigrationTableData,
}) => {
  return (
    <div>
      {preMigrationTableData && <ManagementBillingTableCore migrationTableData={preMigrationTableData} />}
      {postMigrationTableData && <ManagementBillingTableCore migrationTableData={postMigrationTableData} />}
    </div>
  );
};

export default ManagementBillingTable;
