interface IContractTitleAndSubtitleSubsection {
  title: string;
  subtitle: string;
}

const ContractTitleAndSubtitleSubsection = ({ title, subtitle }: IContractTitleAndSubtitleSubsection) => {
  return (
    <div className="xl:w-[25rem]">
      <h1 className="text-heading-xsmall font-bold">{title}</h1>
      <h2 className="text-paragraph-medium font-normal text-neutral-60 text-wrap xl:w-4/5 ">{subtitle}</h2>
    </div>
  );
};

export default ContractTitleAndSubtitleSubsection;
