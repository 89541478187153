import { twMerge } from 'tailwind-merge';
import numeral from 'numeral';
import { Button } from '@clarke-energia/foton';
import useWhiteLabel from '@hooks/use-white-label';
import { IProposalCommercialStage } from '@contexts/types';
import { DescriptionParagraph } from '@components/atoms/simplified-proposal/description-paragraph';

export interface ISimplifiedProposalHeaderContent {
  totalSaving: number;
  totalSavingByMonth: number;
  savingPercentage: number;
  commercialStage?: IProposalCommercialStage;
}

export const SimplifiedProposalHeaderContent = ({
  totalSaving,
  totalSavingByMonth,
  savingPercentage,
  commercialStage,
}: ISimplifiedProposalHeaderContent) => {
  const { isWhiteLabelProposal } = useWhiteLabel();

  return (
    <div className="absolute top-32 md:static col-span-1 w-full px-5 md:px-0 2xl:pl-40">
      <div className="relative flex flex-col gap-5 p-6 bg-white rounded-large md:gap-7 md:pt-9 md:pb-8 md:pl-7 md:pr-7 w-full">
        <div>
          <h2
            className={twMerge(
              'font-black text-heading-xlarge xl:text-display-small truncate',
              isWhiteLabelProposal ? 'text-white-label-main' : ' text-primary-80',
            )}
          >
            {numeral(totalSaving).format('$ 0,0.00')}
          </h2>
          <DescriptionParagraph text={'Valor total'} />
        </div>
        <div className="gap-5 grid grid-cols-1 xl:grid-cols-2 2xl:w-10/12">
          {totalSavingByMonth && (
            <div>
              <h4 className="text-heading-medium font-bold xl:cols-span-1 truncate">
                {numeral(totalSavingByMonth).format('$ 0,0.00')}
              </h4>
              <DescriptionParagraph text={'Economia mensal'} />
            </div>
          )}
          <div>
            <h4 className="text-heading-medium font-bold xl:cols-span-1">{numeral(savingPercentage).format('0%')}</h4>
            <DescriptionParagraph text={'Economia comparado ao mercado cativo'} />
          </div>
        </div>
        <Button
          className="w-full self-center mt-4"
          size="medium"
          icon={commercialStage == 'approved' ? undefined : 'CheckIcon'}
          iconPosition={commercialStage == 'approved' ? undefined : 'left'}
          kind={commercialStage == 'approved' ? 'secondary' : 'primary'}
          label={commercialStage == 'approved' ? 'Proposta aceita' : 'Aceitar proposta'}
          disabled={commercialStage == 'approved' ? true : false}
          hidden
        />
      </div>
    </div>
  );
};
