import React from 'react';

import { getWindowDimensions } from '@utils/graphics';

import DesktopSidebar from './desktop-sidebar';

const Sidebar = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <DesktopSidebar isMenuCollapsedByDefault={windowDimensions.width <= 768} />;
};

export default Sidebar;
