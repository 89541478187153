export const requiredFieldSymbol = <label className="text-red-600">*</label>;

type fieldPriorityType = 'PRIMARY' | 'SECONDARY';
interface IFieldVerificator<T> {
  fieldName: keyof T | string;
  fieldPriority: fieldPriorityType;
  fields?: Record<keyof T | string, boolean>;
}
export function fieldVerificator<T>({ fieldName, fieldPriority, fields }: IFieldVerificator<T>): boolean | undefined {
  if (fields) {
    if (Object.keys(fields).find((field) => field === fieldName)) {
      return fields[fieldName] as boolean;
    } else {
      if (fieldPriority === 'PRIMARY') {
        return true;
      } else if (fieldPriority === 'SECONDARY') {
        return false;
      }
    }
  } else {
    return fieldPriority === 'PRIMARY' ? true : false;
  }
}
