import { twMerge } from 'tailwind-merge';
import { IconProps } from '@clarke-energia/foton';
import useWhiteLabel from '@hooks/use-white-label';
import CardSimpleProposal from '@components/atoms/new-proposal/icon-card/simple-proposal';
import CardHeaderProposal from '@components/atoms/new-proposal/icon-card/header';

interface IEconomyCardComponent {
  title: string;
  icon: IconProps['icon'];
  imageSvg?: string;
  totalValue: string;
  percentValue: string;
  monthlyEconomy: string;
  migrationDate: string;
  migrationCost: string;
  managementCost: string;
  source: string;
}

function EconomyCardComponent({
  title,
  icon,
  imageSvg,
  totalValue,
  percentValue,
  monthlyEconomy,
  migrationDate,
  migrationCost,
  managementCost,
  source,
}: IEconomyCardComponent) {
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();

  return (
    <div
      id="economy-card-component"
      className="w-full h-full sm:grid sm:col-span-2 bg-neutral-10 rounded-3xl pl-6 p-5 gap-0 min-w-full"
    >
      <CardHeaderProposal title={title} icon={icon} imageSvg={imageSvg} />
      <span
        className={twMerge(
          isWhiteLabelProposal ? 'text-white-label-main' : 'text-primary-70',
          'flex items-center gap-4',
        )}
      >
        <h1 className="text-paragraph-large sm:text-heading-xlarge font-bold ">{totalValue}</h1>
        <p>({percentValue})</p>
      </span>
      <div className="flex flex-col gap-4">
        <span className="flex">
          <CardSimpleProposal
            label={'Média de Economia mensal líquida'}
            defaultValue={monthlyEconomy}
            defaultValueClassName=" xl:text-paragraph-medium"
          />
        </span>
        <hr className="xl:w-9/12 h-0.5 bg-neutral-30 bg-opacity-30" />
        <div className="flex flex-col justify-center">
          <strong
            style={{
              color: isWhiteLabelProposal ? whiteLabelSupplier?.colors?.main : '#00DF7C',
            }}
            className="text-paragraph-small"
          >
            Premissas
          </strong>
          <div className="grid grid-cols-2 col-span-2 sm:gap-4 w-full sm:w-10/12">
            <CardSimpleProposal label={'Data de migração'} defaultValue={migrationDate} />
            <CardSimpleProposal label={'Custo de Migração'} defaultValue={migrationCost} />
            <CardSimpleProposal
              className="management-cost-card"
              label={'Custo de Gestão'}
              defaultValue={managementCost}
            />
            <CardSimpleProposal label={'Fonte'} defaultValue={source} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EconomyCardComponent;
