import { twMerge } from 'tailwind-merge';

function CardSimpleProposal({
  label,
  defaultValue,
  className,
  defaultValueClassName,
  labelStyle,
}: {
  label: string;
  defaultValue: string;
  className?: string;
  defaultValueClassName?: string;
  labelStyle?: string;
}) {
  return (
    <div className={twMerge('flex flex-col text-paragraph-small min-w-fit', className)}>
      <p
        className={twMerge('text-caption-small 2xl:text-paragraph-small text-[#222222] whitespace-nowrap', labelStyle)}
      >
        {label}
      </p>
      <p
        className={twMerge(
          'font-bold text-paragraph-small sm:text-paragraph-small 2xl:text-paragraph-medium w-full h-full',
          defaultValueClassName,
        )}
      >
        {defaultValue}
      </p>
    </div>
  );
}

export default CardSimpleProposal;
