import { useEffect, useState } from 'react';
import useProductOne from '@hooks/use-product-one';

import IRecIcon from '@assets/newProposal/logo i-rec.png';

import { formatNumber } from '@utils/numeral';

import LegendContainer from '@components/atoms/new-proposal/legend-container';
import { NewIconCard } from '@components/atoms/new-proposal/icon-card';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import { iRecCardData as initialIRecCardData, IconCardProposalProps } from '@components/molecules/new-proposal/static';

import './styles.css';

function IRecSection() {
  const { proposal } = useProductOne();
  const iRecCertificationCost = proposal?.calculatorMetadata?.firstResult?.iRecCertificationCost as number;
  const totalMonths = proposal?.calculatorMetadata?.firstResult?.unitsReports[0]?.totalMonths as number;
  const iRecCertificationCostperMonth = iRecCertificationCost / totalMonths;
  const useIRec = proposal?.inputs?.useIRecCertification;
  const [iRecCardData, setIRecCardData] = useState<IconCardProposalProps[]>(initialIRecCardData);

  useEffect(() => {
    const updatedCardData = initialIRecCardData.map((item, index) => {
      if (index === 2) {
        return {
          ...item,
          title: `${formatNumber(iRecCertificationCostperMonth, '$ 0,0.00')} / mês`,
        };
      }
      return item;
    });

    setIRecCardData(updatedCardData);
  }, [iRecCertificationCost]);
  return (
    <SlideContainer isVisible={useIRec ?? false}>
      <section className="i-rec-section-style min-h-screen h-fit">
        <LegendContainer legend="Proposta de Gestão" />
        <div className="i-rec-main-container">
          <div className="i-rec-container-classes">
            <span className="i-rec-text-style">
              O {proposal?.lead?.name} será certificado com
              <p className="i-rec-subtext-style">I-REC - certificado internacional de consumo de energia limpa</p>
            </span>
            <img src={IRecIcon} alt="IRec Icon" className="w-60 sm:w-96" />
          </div>
          <div className="i-rec-card-container-style">
            {iRecCardData.map((item) => (
              <NewIconCard
                key={`proposal-i-rec-section-card-${item.title}`}
                {...item}
                isCardDescription={true}
                sizeCard="min-w-80 max-h-[5.875rem] sm:w-1/2 xl:w-3/5 2xl:min-w-60 2xl:max-w-xl sm:h-60 h-28 items-start sm:items-center  sm:flex-row"
              />
            ))}
          </div>
        </div>
      </section>
    </SlideContainer>
  );
}

export default IRecSection;
