import { useContextSelector } from 'use-context-selector';
import { CustomClipboardContext } from '@contexts/custom-clipboard';
import { ICustomClipboardContext } from '@contexts/custom-clipboard/types';

const useCustomClipboard = (): ICustomClipboardContext => {
  const CopyFormValues = useContextSelector(
    CustomClipboardContext,
    (customClipboard) => customClipboard.CopyFormValues,
  );
  const PasteFormValues = useContextSelector(
    CustomClipboardContext,
    (customClipboard) => customClipboard.PasteFormValues,
  );
  const customClipboardFeedbackNotificationContent = useContextSelector(
    CustomClipboardContext,
    (customClipboard) => customClipboard.customClipboardFeedbackNotificationContent,
  );

  return {
    CopyFormValues,
    PasteFormValues,
    customClipboardFeedbackNotificationContent,
  };
};

export default useCustomClipboard;
