import { twMerge } from 'tailwind-merge';

type IResponsiveHeaderLine = {
  color: 'green' | 'white';
};

const ResponsiveHeaderLine: React.FC<IResponsiveHeaderLine> = ({ color }: IResponsiveHeaderLine) => {
  const dynamicClass = color == 'green' ? 'bg-primary-60' : 'bg-white';

  return <div className={twMerge('w-20 h-4 md:w-32 md:h-5', dynamicClass)} />;
};

export default ResponsiveHeaderLine;
