import React from 'react';
import { useFormContext } from 'react-hook-form';

import { IManagementContractPayload } from '@contexts/management-contract/types';

import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { FileField } from '@components/atoms/form/file/input';

const ContractDocumentsSection = () => {
  const { setValue, watch } = useFormContext<IManagementContractPayload>();

  const attachments = watch('attachments');

  const onChangeFiles = (files: Array<File>) => {
    setValue('attachments', files);
  };

  React.useEffect(() => {
    if (attachments && attachments.length >= 1) {
      alert(
        'Após fazer upload de arquivo não é permitido salvar rascunho, apenas gerar contrato. Para salvar rascunho novamente exclua os arquivos anexados.',
      );
    }
  }, [attachments]);

  return (
    <ContractSectionContainer>
      <>
        <ContractTitleAndSubtitleSubsection title="Documentos" subtitle="Vincule documentos a esse contrato." />
        <FileField<IManagementContractPayload>
          field="attachments"
          id="attachments"
          label={'Anexar documentos'}
          description={'Envie documentos referente ao contrato de gestão desse grupo comercial. PDF de até 5MB'}
          maximumFileSizeInMB={5}
          allowedFileTypes={['.pdf']}
          onChangeFiles={(files) => onChangeFiles(files as Array<File>)}
          multipleFiles
        />
      </>
    </ContractSectionContainer>
  );
};

export default ContractDocumentsSection;
