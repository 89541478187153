import dayjs from 'dayjs';

interface TableHeader {
  label: string;
  columnsRange: number;
}

const isProposalExpired = (dateString?: string): boolean => {
  if (!dateString) return false;

  const date = dayjs(dateString, 'DD/MM/YYYY', true); 
  if (!date.isValid()) return false; 

  const currentDate = dayjs();
  return date.isBefore(currentDate, 'day'); 
};

const PricesTableHeaders = (proposalValidity?: string): TableHeader[] => {
  const isExpired = isProposalExpired(proposalValidity);

  return [
    {
      label: `Preços de fornecimento (R$/MWh) ${isExpired ? '[Expirados]' : ''}`,
      columnsRange: 1,
    },
  ];
};

export default PricesTableHeaders;