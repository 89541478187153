import { ISimulationPayloadBase, SimulatorTypeEnum } from '@contexts/types';
import { isOnSimulatorType } from '@utils/common';

const standardizeTotalMigrationCostSuggestion = (calculatedValue: number, units: ISimulationPayloadBase['units']) => {
  const unitsNumber = Array(units).length;
  const maximumValuePerUnit = 6000;
  const doesSuggestionExceedsMaximumPerUnit = calculatedValue / unitsNumber > maximumValuePerUnit;
  const maximumSuggestionValue = unitsNumber * maximumValuePerUnit;

  return doesSuggestionExceedsMaximumPerUnit ? maximumSuggestionValue : calculatedValue;
};

export const calculateTotalMigrationCostSuggestion = (units: ISimulationPayloadBase['units']) => {
  const sumOfManagementPriceOfUnits = (units as ISimulationPayloadBase['units'])?.reduce(function (accumulator, unit) {
    const unitManagementPrice = unit.managementPrice ?? 0;
    return accumulator + unitManagementPrice;
  }, 0);

  const selectedSimulatorType = isOnSimulatorType(SimulatorTypeEnum.CLARKE_RETAIL);
  const multiplicativeFactor = selectedSimulatorType ? 4 : 5;

  const calculatedTotalMigrationCostSuggestion = (sumOfManagementPriceOfUnits ?? 0) * multiplicativeFactor;
  return standardizeTotalMigrationCostSuggestion(calculatedTotalMigrationCostSuggestion, units);
};
