import { useContextSelector } from 'use-context-selector';

import { ProductOneContext } from '@contexts/product-one';
import { IProductOneContext } from '@contexts/product-one/types';

const useProductOne = (): IProductOneContext => {
  const proposal = useContextSelector(ProductOneContext, (sales) => sales.proposal);
  const createProductOneSimulation = useContextSelector(ProductOneContext, (sales) => sales.createProductOneSimulation);
  const createProductOneProposal = useContextSelector(ProductOneContext, (sales) => sales.createProductOneProposal);
  const getProductOneProposals = useContextSelector(ProductOneContext, (sales) => sales.getProductOneProposals);
  const getProductOneProposalById = useContextSelector(ProductOneContext, (sales) => sales.getProductOneProposalById);
  const deleteProductOneProposalsHandler = useContextSelector(
    ProductOneContext,
    (sales) => sales.deleteProductOneProposalsHandler,
  );
  const deleteProductOneAllProposalsByLeadIdHandler = useContextSelector(
    ProductOneContext,
    (sales) => sales.deleteProductOneAllProposalsByLeadIdHandler,
  );
  const updateSellerOfProductOneProposal = useContextSelector(
    ProductOneContext,
    (sales) => sales.updateSellerOfProductOneProposal,
  );
  const updateProductOneProposalCommercialDealerComission = useContextSelector(
    ProductOneContext,
    (sales) => sales.updateProductOneProposalCommercialDealerComission,
  );

  return {
    proposal,
    createProductOneSimulation,
    createProductOneProposal,
    getProductOneProposals,
    getProductOneProposalById,
    deleteProductOneProposalsHandler,
    deleteProductOneAllProposalsByLeadIdHandler,
    updateSellerOfProductOneProposal,
    updateProductOneProposalCommercialDealerComission,
  };
};

export default useProductOne;
