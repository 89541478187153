import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

import MainRouter from '@routers/main-router';
import config from '@config';

import AuthWrapper from './auth-wrapper';
import './numeral';

function App() {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };

  const isTesting = Boolean(window.Cypress);
  const cacheLocation = isTesting ? 'localstorage' : 'memory';
  const scope = isTesting ? undefined : 'read:current_user read:users read:roles read:role_members read:profile';

  return (
    <Auth0Provider
      domain={config.DOMAIN}
      clientId={config.CLIENT_ID}
      redirectUri={window.location.origin}
      useRefreshTokens
      audience={`https://${config.DOMAIN}/api/v2/`}
      cacheLocation={cacheLocation}
      scope={scope}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthWrapper>
        <MainRouter />
      </AuthWrapper>
    </Auth0Provider>
  );
}

export default App;
