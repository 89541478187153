import AnnualDetailCard from '@components/atoms/new-proposal/icon-card/annual-detail';
import CardHeaderProposal from '@components/atoms/new-proposal/icon-card/header';
import CardHeader from '@components/atoms/simplified-proposal/card-header';
import { CardContainer } from '@components/atoms/simplified-proposal/card-container';

import { AnnualBreakdownColumns, IAnnualBreakdownColumns } from './helper';

const SimplifiedProposalAnnualBreakdown = ({ annualBreakdownDataList }: IAnnualBreakdownColumns) => {
  const gridColumns = annualBreakdownDataList.length;
  const lastCard = annualBreakdownDataList.length - 1;
  const isMobile = window.innerWidth < 768;

  return (
    <>
      {isMobile ? (
        <CardContainer columnsSpan={3}>
          <>
            <CardHeader title={'Detalhamento Anual'} icon={'CalendarIcon'} imageSvg={''} />
            <AnnualBreakdownColumns annualBreakdownDataList={annualBreakdownDataList} />
          </>
        </CardContainer>
      ) : (
        <CardContainer columnsSpan={6}>
          <>
            <CardHeaderProposal
              className="mb-6 text-heading-xsmall"
              title="Detalhamento anual"
              icon="CalendarIcon"
              imageSvg=""
            />
            <div
              className={`
            flex gap-5 overflow-visible overflow-x-scroll xl:overflow-x-hidden     
             xl:grid xl:grid-cols-${gridColumns} col-span-${gridColumns}
             h-40 lg:h-fit
             `}
            >
              {annualBreakdownDataList.map((item, index) => {
                const { priceList, totalTargetSpentByYear, totalSavingByYear, totalCurrentSpentByYear } = item;
                return (
                  <div
                    key={`${item.year}-${item.totalCurrentSpentByYear}`}
                    className={`${index !== lastCard && 'border-r-2'}`}
                  >
                    <AnnualDetailCard
                      year={item.year}
                      supply={priceList}
                      freeMarket={totalTargetSpentByYear}
                      economy={totalSavingByYear}
                      captiveMarket={totalCurrentSpentByYear}
                      className="grid grid-cols-1 gap-5 items-start justify-around px-2 min-w-[20rem] xl:min-w-fit min-h-full"
                      spanStyle="font-bold xl:text-heading-small"
                    />
                  </div>
                );
              })}
            </div>
          </>
        </CardContainer>
      )}
    </>
  );
};
export default SimplifiedProposalAnnualBreakdown;
