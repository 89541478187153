import React from 'react';

interface IContractedDemandSimulatorResultItem {
  label: string;
  value?: string | number;
}

const ContractedDemandSimulatorResultItem = ({ label, value }: IContractedDemandSimulatorResultItem) => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 sm:mt-0 sm:col-span-2">
        <p className="font-bold text-md text-green-700">{value}</p>
      </dd>
    </div>
  );
};

export default ContractedDemandSimulatorResultItem;
