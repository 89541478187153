export interface IWhiteLabelSupplierColors {
  main?: string;
  darker?: string;
  brighter?: string;
}

export enum PricesTypesEnum {
  migration = 'Preço padrão de migração',
  management = 'Preço padrão de gestão',
  discount = 'Desconto padrão',
}

export interface IWhiteLabelSupplierPrices {
  migration: number;
  management: number;
  discount: number;
}
export interface IWhiteLabelSupplierDifferences {
  title: string;
  icon: string;
  description: string;
}
export interface IWhiteLabelSupplierUser {
  id: string;
  name: string;
  email: string;
  pictureUrl: string;
}
export enum WhiteLabelSupplierTypeEnum {
  'MANAGER' = 'Gestora',
  'TRADER' = 'Comercializadora',
  'HYBRID' = 'Hibrido',
}
export enum WhiteLabelSupplierStatusEnum {
  'INCOMPLETE_REGISTRATION' = 'INCOMPLETE_REGISTRATION',
  'COMPLETE_REGISTRATION' = 'COMPLETE_REGISTRATION',
}

export interface IWhiteLabelSupplierPresentation {
  logoUrl?: string;
  name: string;
  phone: string;
  email: string;
}
export interface IWhiteLabelSupplier extends Omit<IWhiteLabelSupplierPayload, 'fileList'> {
  id: string;
  status: WhiteLabelSupplierStatusEnum;
}

export interface IWhiteLabelSupplierPayload {
  presentation: IWhiteLabelSupplierPresentation;
  colors?: IWhiteLabelSupplierColors;
  differences?: Array<IWhiteLabelSupplierDifferences>;
  prices?: IWhiteLabelSupplierPrices;
  users: Array<IWhiteLabelSupplierUser>;
  type: keyof typeof WhiteLabelSupplierTypeEnum;
  fileList?: File;
  createdAt?: string;
  deletedAt?: string;
}
