const config = {
  SALES_API_HOST: import.meta.env.VITE_SALES_API_HOST,
  CALCULATOR_API_HOST: import.meta.env.VITE_CALCULATOR_API_HOST,
  CALCULATOR_API_KEY: import.meta.env.VITE_CALCULATOR_API_KEY,
  USER_API_HOST: import.meta.env.VITE_USER_API_HOST,
  GATEWAY_API_HOST: import.meta.env.VITE_GATEWAY_API_HOST,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN || undefined,
  AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
  AMPLITUDE_INITIALIZED: false,
  IS_IN_PRODUCTION: import.meta.env.VITE_IS_IN_PRODUCTION,
  DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  CLARKE_SIGNATURE_CPF: import.meta.env.VITE_CLARKE_SIGNATURE_CPF,
  CLARKE_SIGNATURE_EMAIL: import.meta.env.VITE_CLARKE_SIGNATURE_EMAIL,
  CLARKE_SIGNATURE_NAME: import.meta.env.VITE_CLARKE_SIGNATURE_NAME,
  CLARKE_SIGNATURE_IDENTITY: import.meta.env.VITE_CLARKE_SIGNATURE_IDENTITY,
  PROPOSALS_HISTORIC_PRICES_CHART_IMAGE_URL: import.meta.env.VITE_PROPOSALS_HISTORIC_PRICES_CHART_IMAGE_URL,
  INTEREST_FACTOR_FOR_INSTALLMENTS_COMMISSION: import.meta.env.VITE_INTEREST_FACTOR_FOR_INSTALLMENTS_COMMISSION,
};

export default config;
