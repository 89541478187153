import LegendContainer from '@components/atoms/new-proposal/legend-container';
import { NewIconCard } from '@components/atoms/new-proposal/icon-card/index';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import { migrationCardData } from '@components/molecules/new-proposal/static';

import './styles.css';

function MigrationsSection() {
  return (
    <SlideContainer>
      <section className={'migrations-section-styles'}>
        <LegendContainer legend="A dor da migração para o Mercado Livre de Energia" colorSecondary={true} />
        <div className={'legend-container-styles-div'}>
          <span className={'text-styles-migrations-span'}>
            A migração para o mercado livre de energia <b className="text-orange-50">pode parecer complexa</b>,
            principalmente, por causa dos seguintes motivos:
          </span>
          <div className={'grid-styles-migrations'}>
            {migrationCardData.map((item, index) => (
              <NewIconCard
                key={`proposal-migrations-section-card-${item.title}`}
                {...item}
                index={index}
                isSpecialColor={true}
                sizeCard="sm:h-60 xl:h-72 min-w-fit xl:w-11/12 min-h-24 p-7"
              />
            ))}
          </div>
        </div>
      </section>
    </SlideContainer>
  );
}

export default MigrationsSection;
